// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: common/echo.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "common";

export enum Service {
  SERVICE_UNSPECIFIED = 0,
  SERVICE_BRIG = 1,
  SERVICE_CONFIGD = 2,
  SERVICE_SEXTANT = 3,
  SERVICE_BILGE = 4,
  SERVICE_KRANG = 5,
  SERVICE_RUDDER = 6,
  SERVICE_FRED = 7,
  UNRECOGNIZED = -1,
}

export function serviceFromJSON(object: any): Service {
  switch (object) {
    case 0:
    case "SERVICE_UNSPECIFIED":
      return Service.SERVICE_UNSPECIFIED;
    case 1:
    case "SERVICE_BRIG":
      return Service.SERVICE_BRIG;
    case 2:
    case "SERVICE_CONFIGD":
      return Service.SERVICE_CONFIGD;
    case 3:
    case "SERVICE_SEXTANT":
      return Service.SERVICE_SEXTANT;
    case 4:
    case "SERVICE_BILGE":
      return Service.SERVICE_BILGE;
    case 5:
    case "SERVICE_KRANG":
      return Service.SERVICE_KRANG;
    case 6:
    case "SERVICE_RUDDER":
      return Service.SERVICE_RUDDER;
    case 7:
    case "SERVICE_FRED":
      return Service.SERVICE_FRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Service.UNRECOGNIZED;
  }
}

export function serviceToJSON(object: Service): string {
  switch (object) {
    case Service.SERVICE_UNSPECIFIED:
      return "SERVICE_UNSPECIFIED";
    case Service.SERVICE_BRIG:
      return "SERVICE_BRIG";
    case Service.SERVICE_CONFIGD:
      return "SERVICE_CONFIGD";
    case Service.SERVICE_SEXTANT:
      return "SERVICE_SEXTANT";
    case Service.SERVICE_BILGE:
      return "SERVICE_BILGE";
    case Service.SERVICE_KRANG:
      return "SERVICE_KRANG";
    case Service.SERVICE_RUDDER:
      return "SERVICE_RUDDER";
    case Service.SERVICE_FRED:
      return "SERVICE_FRED";
    case Service.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** EchoRequest encapsulates properties of a simple gRPC echo request. */
export interface EchoRequest {
  /** Echo message. */
  message: string;
  /** Input timestamp. */
  requestTs:
    | Date
    | undefined;
  /**
   * num_responses is number of EchoResponses the client expects the server
   * to send.  It is optional, the default value of 0 indicates the server
   * should keep sending indefinitely.
   */
  numResponses: number;
  /**
   * num_messages is number of messages the client expects the server
   * to send in messages in each EchoResponse.  It is optional, the default
   * value of 0 indicates send messsage in EchoResponse only (no messages).
   */
  numMessages: number;
  /**
   * wait_secs is the number of seconds the server should wait before sending
   * subsequent EchoResponse messages.  If not specified (0), the current value
   * is used (the initial (default) value is 1 second).
   */
  waitSecs: number;
  /**
   * stop indicates the server should close its end of the stream immediately
   * (in the case of streamecho).
   */
  stop: boolean;
}

/** EchoResponse encapsulates properties of a simple gRPC echo response. */
export interface EchoResponse {
  /** Echo message. */
  message: string;
  /** Input timestamp.n */
  requestTs:
    | Date
    | undefined;
  /** Echo timestamp. */
  responseTs:
    | Date
    | undefined;
  /** Name of service responding. */
  svc: string;
  /** Identifies the switch. */
  switchId: string;
  /** Identifies the organization of the session to which tenant belongs. */
  orgId: string;
  /** Identifies the switch. */
  fabricId: string;
  /**
   * Identifies the tenant of the session to which the user belongs.  If tenant_id equals org_id,
   * then the session is in the context of the default-tenant of this organization.
   */
  tenantId: string;
  /** Email of the authenticated user. */
  email: string;
  /** A repeat of message based on num_messages in EchoRequest. */
  messages: string[];
}

function createBaseEchoRequest(): EchoRequest {
  return { message: "", requestTs: undefined, numResponses: 0, numMessages: 0, waitSecs: 0, stop: false };
}

export const EchoRequest = {
  encode(message: EchoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.requestTs !== undefined) {
      Timestamp.encode(toTimestamp(message.requestTs), writer.uint32(18).fork()).ldelim();
    }
    if (message.numResponses !== 0) {
      writer.uint32(24).uint32(message.numResponses);
    }
    if (message.numMessages !== 0) {
      writer.uint32(32).uint32(message.numMessages);
    }
    if (message.waitSecs !== 0) {
      writer.uint32(40).uint32(message.waitSecs);
    }
    if (message.stop !== false) {
      writer.uint32(48).bool(message.stop);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EchoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEchoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numResponses = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.numMessages = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.waitSecs = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.stop = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EchoRequest {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      requestTs: isSet(object.requestTs) ? fromJsonTimestamp(object.requestTs) : undefined,
      numResponses: isSet(object.numResponses) ? globalThis.Number(object.numResponses) : 0,
      numMessages: isSet(object.numMessages) ? globalThis.Number(object.numMessages) : 0,
      waitSecs: isSet(object.waitSecs) ? globalThis.Number(object.waitSecs) : 0,
      stop: isSet(object.stop) ? globalThis.Boolean(object.stop) : false,
    };
  },

  toJSON(message: EchoRequest): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.requestTs !== undefined) {
      obj.requestTs = message.requestTs.toISOString();
    }
    if (message.numResponses !== 0) {
      obj.numResponses = Math.round(message.numResponses);
    }
    if (message.numMessages !== 0) {
      obj.numMessages = Math.round(message.numMessages);
    }
    if (message.waitSecs !== 0) {
      obj.waitSecs = Math.round(message.waitSecs);
    }
    if (message.stop !== false) {
      obj.stop = message.stop;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EchoRequest>, I>>(base?: I): EchoRequest {
    return EchoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EchoRequest>, I>>(object: I): EchoRequest {
    const message = createBaseEchoRequest();
    message.message = object.message ?? "";
    message.requestTs = object.requestTs ?? undefined;
    message.numResponses = object.numResponses ?? 0;
    message.numMessages = object.numMessages ?? 0;
    message.waitSecs = object.waitSecs ?? 0;
    message.stop = object.stop ?? false;
    return message;
  },
};

function createBaseEchoResponse(): EchoResponse {
  return {
    message: "",
    requestTs: undefined,
    responseTs: undefined,
    svc: "",
    switchId: "",
    orgId: "",
    fabricId: "",
    tenantId: "",
    email: "",
    messages: [],
  };
}

export const EchoResponse = {
  encode(message: EchoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.requestTs !== undefined) {
      Timestamp.encode(toTimestamp(message.requestTs), writer.uint32(18).fork()).ldelim();
    }
    if (message.responseTs !== undefined) {
      Timestamp.encode(toTimestamp(message.responseTs), writer.uint32(26).fork()).ldelim();
    }
    if (message.svc !== "") {
      writer.uint32(34).string(message.svc);
    }
    if (message.switchId !== "") {
      writer.uint32(66).string(message.switchId);
    }
    if (message.orgId !== "") {
      writer.uint32(74).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(82).string(message.fabricId);
    }
    if (message.tenantId !== "") {
      writer.uint32(90).string(message.tenantId);
    }
    if (message.email !== "") {
      writer.uint32(98).string(message.email);
    }
    for (const v of message.messages) {
      writer.uint32(722).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EchoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEchoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.responseTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.svc = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.email = reader.string();
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.messages.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EchoResponse {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      requestTs: isSet(object.requestTs) ? fromJsonTimestamp(object.requestTs) : undefined,
      responseTs: isSet(object.responseTs) ? fromJsonTimestamp(object.responseTs) : undefined,
      svc: isSet(object.svc) ? globalThis.String(object.svc) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: EchoResponse): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.requestTs !== undefined) {
      obj.requestTs = message.requestTs.toISOString();
    }
    if (message.responseTs !== undefined) {
      obj.responseTs = message.responseTs.toISOString();
    }
    if (message.svc !== "") {
      obj.svc = message.svc;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.messages?.length) {
      obj.messages = message.messages;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EchoResponse>, I>>(base?: I): EchoResponse {
    return EchoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EchoResponse>, I>>(object: I): EchoResponse {
    const message = createBaseEchoResponse();
    message.message = object.message ?? "";
    message.requestTs = object.requestTs ?? undefined;
    message.responseTs = object.responseTs ?? undefined;
    message.svc = object.svc ?? "";
    message.switchId = object.switchId ?? "";
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.email = object.email ?? "";
    message.messages = object.messages?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
