// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/presets.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Breakout, NodeRole, nodeRoleFromJSON, nodeRoleToJSON } from "./fabric";
import { NetworkPort } from "./models";

export const protobufPackage = "models";

/** DeviceCapability encapsulates a device's capabilities. */
export interface DeviceCapability {
  /** ModelName defines a device model name or PID. */
  modelName: string;
  /** Name defines the user-defined name of device model. */
  name: string;
  /** Manufacturer defines the name of the device manufacturer. */
  manufacturer: string;
  /** MgmtPorts defines the number of physical management ports. */
  mgmtPorts: number;
  /** Ports defines the number of physical data ports. */
  dataPorts: number;
  /** Roles defines supported node roles for the device. */
  roles: NodeRole[];
  /** HostOnSpine indicates if hosts can be attached to the switch when it is a spine switch. */
  hostOnSpine: boolean;
  /** Breakouts defines supported breakout modes. */
  breakouts: Breakout[];
  /** Ports defines default network ports of the device model. */
  ports: NetworkPort[];
}

function createBaseDeviceCapability(): DeviceCapability {
  return {
    modelName: "",
    name: "",
    manufacturer: "",
    mgmtPorts: 0,
    dataPorts: 0,
    roles: [],
    hostOnSpine: false,
    breakouts: [],
    ports: [],
  };
}

export const DeviceCapability = {
  encode(message: DeviceCapability, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modelName !== "") {
      writer.uint32(18).string(message.modelName);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.manufacturer !== "") {
      writer.uint32(34).string(message.manufacturer);
    }
    if (message.mgmtPorts !== 0) {
      writer.uint32(40).uint32(message.mgmtPorts);
    }
    if (message.dataPorts !== 0) {
      writer.uint32(48).uint32(message.dataPorts);
    }
    writer.uint32(58).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.hostOnSpine !== false) {
      writer.uint32(64).bool(message.hostOnSpine);
    }
    for (const v of message.breakouts) {
      Breakout.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.ports) {
      NetworkPort.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceCapability {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceCapability();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mgmtPorts = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dataPorts = reader.uint32();
          continue;
        case 7:
          if (tag === 56) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hostOnSpine = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.breakouts.push(Breakout.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.ports.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceCapability {
    return {
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      mgmtPorts: isSet(object.mgmtPorts) ? globalThis.Number(object.mgmtPorts) : 0,
      dataPorts: isSet(object.dataPorts) ? globalThis.Number(object.dataPorts) : 0,
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      hostOnSpine: isSet(object.hostOnSpine) ? globalThis.Boolean(object.hostOnSpine) : false,
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => Breakout.fromJSON(e))
        : [],
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => NetworkPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: DeviceCapability): unknown {
    const obj: any = {};
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.mgmtPorts !== 0) {
      obj.mgmtPorts = Math.round(message.mgmtPorts);
    }
    if (message.dataPorts !== 0) {
      obj.dataPorts = Math.round(message.dataPorts);
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.hostOnSpine !== false) {
      obj.hostOnSpine = message.hostOnSpine;
    }
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts.map((e) => Breakout.toJSON(e));
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => NetworkPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceCapability>, I>>(base?: I): DeviceCapability {
    return DeviceCapability.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceCapability>, I>>(object: I): DeviceCapability {
    const message = createBaseDeviceCapability();
    message.modelName = object.modelName ?? "";
    message.name = object.name ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.mgmtPorts = object.mgmtPorts ?? 0;
    message.dataPorts = object.dataPorts ?? 0;
    message.roles = object.roles?.map((e) => e) || [];
    message.hostOnSpine = object.hostOnSpine ?? false;
    message.breakouts = object.breakouts?.map((e) => Breakout.fromPartial(e)) || [];
    message.ports = object.ports?.map((e) => NetworkPort.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
