import { Button, ButtonProps } from "@magnetic/button";
import React, { useContext } from "react";
import {
  DataProviderContext,
  CandidateMode
} from "../core/provider/dataProvider";

export const EditButton = React.forwardRef((props: ButtonProps, ref) => {
  const { mode } = useContext(DataProviderContext);
  if (mode === CandidateMode.Edit) {
    return (
      <Button ref={ref} {...props}>
        {props.children}
      </Button>
    );
  }
  return null;
});
