import {
  useInternalRawWebsocket,
  useInternalUnary as useUnary
} from "../core/dataflow/internalHooks";
import { mergeStreams } from "../core/mergeStreams";
import { BrigClientImpl } from "../gen/schema/brig/bservice";
import { EchoRequest } from "../gen/schema/common/echo";
import { ConfigDClientImpl } from "../gen/schema/configd/service";
import { FredClientImpl, GrpcWebImpl } from "../gen/schema/fred/service";
import { PathId } from "../gen/schema/schema_path/path_id";
import { SextantClientImpl } from "../gen/schema/sextant/service";

// this should probably be instanciated on the global object
const rpc = new GrpcWebImpl("", {
  debug: false // change later
});
const fred = new FredClientImpl(rpc);
const configD = new ConfigDClientImpl(rpc);
const sextant = new SextantClientImpl(rpc);
const brig = new BrigClientImpl(rpc);

// replace items in the record by path id.
export const mergeOnPathIdReducer = <T extends Partial<{ id: PathId }>>(
  curr: T[][] | undefined,
  next: T[][]
) => {
  const keyFn = (d: T) => {
    return d?.id?.pathString || "";
  };

  let mergedData: T[] = [];
  curr?.forEach((d) => {
    mergedData = mergeStreams(mergedData, d, keyFn);
  });

  next?.forEach((d) => {
    mergedData = mergeStreams(mergedData, d, keyFn);
  });

  return [mergedData];
};

export enum AssertType {
  SWITCH,
  PORT,
  SWITCHPORT
}

export enum AssertScope {
  FABRIC,
  DEVICE
}

export const Api = {
  useRawWebsocket: (url: string, requestJson: string) => {
    return useInternalRawWebsocket(url, requestJson); // don't use this hook
  },
  Echo: {
    useFred: (req: Partial<EchoRequest>) => {
      return useUnary(fred.Echo, req);
    },
    useConfigD: (req: Partial<EchoRequest>) => {
      return useUnary(configD.Echo, req);
    },
    useSextant: (req: Partial<EchoRequest>) => {
      return useUnary(sextant.Echo, req);
    },
    useBrig: (req: Partial<EchoRequest>) => {
      return useUnary(brig.Echo, req);
    }
  }
};
