/**
 * There is a lot of bitwise operations
 * here so eslint disable no-bitwise is being
 * used.
 *
 * Note that MD5 is not considered
 * secure for cryptographic purposes, but
 * it can be used for checksums and
 * non-critical applications.
 */
/* eslint-disable no-bitwise */
export class MD5 {
  private static readonly hexChars = "0123456789abcdef";

  /**
   * generates the MD5 hash from a JSON
   * object
   */
  static hashJson(input: unknown): string {
    return MD5.hash(JSON.stringify(input));
  }

  /**
   * generates the MD5 hash from a string
   */
  static hash(input: string): string {
    const data = MD5.utf8Encode(input);
    const m = MD5.createBlocks(data);
    const [a, b, c, d] = MD5.computeHash(m);
    return MD5.toHex(a) + MD5.toHex(b) + MD5.toHex(c) + MD5.toHex(d);
  }

  /**
   * Encodes the input string to a Uint8Array
   * using UTF-8 encoding
   */
  private static utf8Encode(input: string): Uint8Array {
    return new TextEncoder().encode(input);
  }

  /**
   * Converts the byte data to 32-bit blocks
   * and adds padding as required by the MD5
   * algorithm.
   */
  private static createBlocks(data: Uint8Array): Uint32Array {
    const length = data.length;
    const nBlocks = (((length + 8) >>> 6) << 4) + 16;
    const blocks = new Uint32Array(nBlocks);

    for (let i = 0; i < length; i++) {
      /* @ts-ignore */
      blocks[i >> 2] |= data[i] << ((i % 4) * 8);
    }

    blocks[length >> 2] |= 0x80 << ((length % 4) * 8);
    blocks[nBlocks - 2] = length * 8;

    return blocks;
  }

  /**
   * Computes the MD5 hash on the blocks using
   * the MD5 transformation
   */
  private static computeHash(
    blocks: Uint32Array
  ): [number, number, number, number] {
    let [a, b, c, d] = [0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476];

    /**
     * The s array contains the predefined
     * shift amounts for each of the 64 operations
     * in the MD5 algorithm.
     *
     * These values determine how many bits
     * to rotate the intermediate hash values
     * during each operation.
     *
     * The MD5 algorithm uses bitwise
     * operations to mix the input data
     * and produce the final hash.
     */
    const s: number[] = [
      7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 5, 9, 14, 20,
      5, 9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 4, 11, 16, 23, 4, 11, 16, 23, 4,
      11, 16, 23, 4, 11, 16, 23, 6, 10, 15, 21, 6, 10, 15, 21, 6, 10, 15, 21, 6,
      10, 15, 21
    ];

    /**
     * K array: Contains the precomputed constants
     * for each of the 64 steps of the MD5 algorithm.
     * These constants are derived from the sine
     * function to add an element of randomness
     * to the hash computation.
     *
     * Each constant K[j]: Is added to the
     * intermediate hash values during the
     * MD5 transformation process to help
     * ensure a unique and evenly distributed
     * hash output for different inputs.
     *
     */
    const K: number[] = [
      0xd76aa478, 0xe8c7b756, 0x242070db, 0xc1bdceee, 0xf57c0faf, 0x4787c62a,
      0xa8304613, 0xfd469501, 0x698098d8, 0x8b44f7af, 0xffff5bb1, 0x895cd7be,
      0x6b901122, 0xfd987193, 0xa679438e, 0x49b40821, 0xf61e2562, 0xc040b340,
      0x265e5a51, 0xe9b6c7aa, 0xd62f105d, 0x02441453, 0xd8a1e681, 0xe7d3fbc8,
      0x21e1cde6, 0xc33707d6, 0xf4d50d87, 0x455a14ed, 0xa9e3e905, 0xfcefa3f8,
      0x676f02d9, 0x8d2a4c8a, 0xfffa3942, 0x8771f681, 0x6d9d6122, 0xfde5380c,
      0xa4beea44, 0x4bdecfa9, 0xf6bb4b60, 0xbebfbc70, 0x289b7ec6, 0xeaa127fa,
      0xd4ef3085, 0x04881d05, 0xd9d4d039, 0xe6db99e5, 0x1fa27cf8, 0xc4ac5665,
      0xf4292244, 0x432aff97, 0xab9423a7, 0xfc93a039, 0x655b59c3, 0x8f0ccc92,
      0xffeff47d, 0x85845dd1, 0x6fa87e4f, 0xfe2ce6e0, 0xa3014314, 0x4e0811a1,
      0xf7537e82, 0xbd3af235, 0x2ad7d2bb, 0xeb86d391
    ];

    for (let i = 0; i < blocks.length; i += 16) {
      const [aa, bb, cc, dd] = [a, b, c, d];

      for (let j = 0; j < 64; j++) {
        let f, g;

        if (j < 16) {
          f = (b & c) | (~b & d);
          g = j;
        } else if (j < 32) {
          f = (d & b) | (~d & c);
          g = (5 * j + 1) % 16;
        } else if (j < 48) {
          f = b ^ c ^ d;
          g = (3 * j + 5) % 16;
        } else {
          f = c ^ (b | ~d);
          g = (7 * j) % 16;
        }

        const temp = d;
        d = c;
        c = b;
        /* @ts-ignore */
        b = b + MD5.leftRotate((a + f + K[j] + blocks[i + g]) | 0, s[j]);
        a = temp;
      }

      a = (a + aa) | 0;
      b = (b + bb) | 0;
      c = (c + cc) | 0;
      d = (d + dd) | 0;
    }

    return [a, b, c, d];
  }

  /**
   * Performs a left bitwise rotation.
   */
  private static leftRotate(x: number, c: number): number {
    return (x << c) | (x >>> (32 - c));
  }

  /**
   * Converts a 32-bit number to a hexadecimal
   * string
   */
  private static toHex(num: number): string {
    let hex = "";
    for (let i = 0; i < 4; i++) {
      hex +=
        MD5.hexChars.charAt((num >> (i * 8 + 4)) & 0x0f) +
        MD5.hexChars.charAt((num >> (i * 8)) & 0x0f);
    }
    return hex;
  }
}
/* eslint-enable no-bitwise */
