import { Text } from "@magnetic/text";
import "./results.scss";
import VFlex from "./vFlex";
export const Results = ({ size = 0 }: { readonly size?: number }) => (
  <VFlex gap="xs" className="cnc-results-count">
    <Text color="light">
      {size} result{size !== 1 && `s`}
    </Text>
  </VFlex>
);
