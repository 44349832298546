type Callback<T> = (params: T) => void;

class Observer<T> {
  scope: unknown;
  callback: Callback<T>;
  constructor(scope: unknown, callback: Callback<T>) {
    this.scope = scope;
    this.callback = callback;
  }

  run(params: T): void {
    this.callback.call(this.scope, params);
  }
}

class Observable<T> {
  handlers: Map<string, Observer<T>[]> = new Map();

  addEvents(events: string[]): void {
    events.forEach((event: string) => {
      this.handlers.set(event, []);
    });
  }

  /**
   *
   * @param eventName
   * @param callback
   * @param scope
   */
  on(eventName: string, callback: Callback<T>, scope: object): void {
    if (!this.handlers.has(eventName)) {
      this.addEvents([eventName]);
    }
    const observers: Observer<T>[] = this.handlers.get(eventName) || [];
    observers.push(new Observer(scope, callback));
    this.handlers.set(eventName, observers);
  }

  /**
   *
   * @param event
   * @param callback
   * @param scope
   */
  un(event: string, callback: Callback<T>, scope: object): void {
    if (this.handlers.has(event)) {
      const observers: Observer<T>[] = this.handlers.get(event) || [];
      const filtered: Observer<T>[] = observers.filter(
        (observer: Observer<T>): boolean => {
          return !(observer.scope === scope && observer.callback === callback);
        }
      );
      this.handlers.set(event, filtered);
    }
  }

  /**
   *
   * @param eventName
   * @param params
   */
  fireEvent(eventName: string, params: T): void {
    if (this.handlers.has(eventName)) {
      const observers: Observer<T>[] = this.handlers.get(eventName) || [];
      observers.forEach((observer: Observer<T>): void => {
        observer.run(params);
      });
    }
  }
}

export { Observer, Observable };
/**
 * schema path: Abstract system global ID
 * - has a schema type
 *
 * Sextant: big state store
 */
