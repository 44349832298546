import React from "react";
import { Empty } from "@magnetic/icons";
import { Flex } from "@magnetic/flex";
import { Text } from "@magnetic/text";
import { Container } from "@magnetic/container";
import "./emptyContainer.scss";

type EmptyProps = {
  readonly className?: string;
  readonly text?: React.ReactNode;
};

export const EmptyContainer = (props: EmptyProps) => {
  const { className = "", text = "No matches found" } = props;
  return (
    <Container className={`empty-container ${className}`}>
      <Flex
        align="center"
        className="mds-table-empty-state"
        direction="vertical"
        gap={4}
      >
        <Empty.Info className="empty-icon" />
        <Text color="light" size="p4">
          {text}
        </Text>
      </Flex>
    </Container>
  );
};
