import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

// write state objects out to url, making it the source of truth
export const useUrlState = (
  namespace = "q",
  defaultValue?: string
): [string, (newVal: string) => void] => {
  // set initial state
  const defaultValueObject = useMemo(() => {
    /**
     * using any since there is probably
     * no way to tell all the possible
     * params for a Url
     */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const o: any = {};
    o[namespace] = defaultValue ?? "";
    return o;
  }, [namespace, defaultValue]);

  const [searchParams, setSearchParams] = useSearchParams(defaultValueObject);

  //wrap the setter to remove empty query strings like q=""
  const setter = useCallback(
    (val: string) => {
      setSearchParams(
        (prev) => {
          prev.set(namespace, val);
          if (!val) {
            // remove empty query strings from url
            prev.delete(namespace);
          }
          return prev;
        },
        { replace: false } // this pushed to history instead of replacing it so that we can use browser back button!
      );
    },
    [namespace, setSearchParams]
  );
  const currentVal = searchParams.get(namespace) || "";
  return [currentVal, setter];
};
