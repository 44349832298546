// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/rest_counters.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { BasicCounters, DacCounters, PortCounters, VlanCounters } from "../schema/schema";

export const protobufPackage = "sextant";

export interface Counter {
  basic: BasicCounters | undefined;
  port: PortCounters | undefined;
  vlans: VlanCounters | undefined;
  dac: DacCounters | undefined;
}

export interface CountersResponse {
  counters: Counter[];
}

export interface GetDeviceCountersRequest {
  /** The device id or name. */
  deviceId: string;
  /** The fabric id or name. */
  fabricId: string;
}

export interface GetDevicePortCountersRequest {
  /** The chassis id or name. */
  deviceId: string;
  /** The port id, index or name. */
  port: string;
  /** The fabric id or name. */
  fabricId: string;
}

function createBaseCounter(): Counter {
  return { basic: undefined, port: undefined, vlans: undefined, dac: undefined };
}

export const Counter = {
  encode(message: Counter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.basic !== undefined) {
      BasicCounters.encode(message.basic, writer.uint32(10).fork()).ldelim();
    }
    if (message.port !== undefined) {
      PortCounters.encode(message.port, writer.uint32(18).fork()).ldelim();
    }
    if (message.vlans !== undefined) {
      VlanCounters.encode(message.vlans, writer.uint32(26).fork()).ldelim();
    }
    if (message.dac !== undefined) {
      DacCounters.encode(message.dac, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Counter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.basic = BasicCounters.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = PortCounters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vlans = VlanCounters.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dac = DacCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Counter {
    return {
      basic: isSet(object.basic) ? BasicCounters.fromJSON(object.basic) : undefined,
      port: isSet(object.port) ? PortCounters.fromJSON(object.port) : undefined,
      vlans: isSet(object.vlans) ? VlanCounters.fromJSON(object.vlans) : undefined,
      dac: isSet(object.dac) ? DacCounters.fromJSON(object.dac) : undefined,
    };
  },

  toJSON(message: Counter): unknown {
    const obj: any = {};
    if (message.basic !== undefined) {
      obj.basic = BasicCounters.toJSON(message.basic);
    }
    if (message.port !== undefined) {
      obj.port = PortCounters.toJSON(message.port);
    }
    if (message.vlans !== undefined) {
      obj.vlans = VlanCounters.toJSON(message.vlans);
    }
    if (message.dac !== undefined) {
      obj.dac = DacCounters.toJSON(message.dac);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Counter>, I>>(base?: I): Counter {
    return Counter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Counter>, I>>(object: I): Counter {
    const message = createBaseCounter();
    message.basic = (object.basic !== undefined && object.basic !== null)
      ? BasicCounters.fromPartial(object.basic)
      : undefined;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortCounters.fromPartial(object.port)
      : undefined;
    message.vlans = (object.vlans !== undefined && object.vlans !== null)
      ? VlanCounters.fromPartial(object.vlans)
      : undefined;
    message.dac = (object.dac !== undefined && object.dac !== null) ? DacCounters.fromPartial(object.dac) : undefined;
    return message;
  },
};

function createBaseCountersResponse(): CountersResponse {
  return { counters: [] };
}

export const CountersResponse = {
  encode(message: CountersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.counters) {
      Counter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.counters.push(Counter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountersResponse {
    return {
      counters: globalThis.Array.isArray(object?.counters) ? object.counters.map((e: any) => Counter.fromJSON(e)) : [],
    };
  },

  toJSON(message: CountersResponse): unknown {
    const obj: any = {};
    if (message.counters?.length) {
      obj.counters = message.counters.map((e) => Counter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CountersResponse>, I>>(base?: I): CountersResponse {
    return CountersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountersResponse>, I>>(object: I): CountersResponse {
    const message = createBaseCountersResponse();
    message.counters = object.counters?.map((e) => Counter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeviceCountersRequest(): GetDeviceCountersRequest {
  return { deviceId: "", fabricId: "" };
}

export const GetDeviceCountersRequest = {
  encode(message: GetDeviceCountersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceCountersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceCountersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceCountersRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetDeviceCountersRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceCountersRequest>, I>>(base?: I): GetDeviceCountersRequest {
    return GetDeviceCountersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceCountersRequest>, I>>(object: I): GetDeviceCountersRequest {
    const message = createBaseGetDeviceCountersRequest();
    message.deviceId = object.deviceId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetDevicePortCountersRequest(): GetDevicePortCountersRequest {
  return { deviceId: "", port: "", fabricId: "" };
}

export const GetDevicePortCountersRequest = {
  encode(message: GetDevicePortCountersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDevicePortCountersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDevicePortCountersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDevicePortCountersRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetDevicePortCountersRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDevicePortCountersRequest>, I>>(base?: I): GetDevicePortCountersRequest {
    return GetDevicePortCountersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDevicePortCountersRequest>, I>>(object: I): GetDevicePortCountersRequest {
    const message = createBaseGetDevicePortCountersRequest();
    message.deviceId = object.deviceId ?? "";
    message.port = object.port ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
