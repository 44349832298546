import { CandidateMode } from "../utils/api";
import { PageHeader } from "./pageHeader";
import VFlex from "./vFlex";
import { EmptyContainer } from "./emptyContainer";
import React, { useContext } from "react";
import { DataProviderContext } from "../core/provider/dataProvider";

interface DetailPageFallbackProps {
  readonly text?: string;
  readonly breadcrumbs: [string, string][];
}
export const DetailPageFallback = ({
  breadcrumbs,
  text = "Item not found"
}: DetailPageFallbackProps) => {
  const { mode } = useContext(DataProviderContext);
  const txt = mode === CandidateMode.Running ? "No running config found" : text;

  return (
    <VFlex className="detail-page-fallback">
      <PageHeader breadcrumbs={breadcrumbs} name={txt} title={txt} />
      <EmptyContainer text={txt} />
    </VFlex>
  );
};
