import React, { useCallback, useContext, useState } from "react";
import { Link } from "@magnetic/link";
import { Heading } from "@magnetic/heading";
import { Button } from "@magnetic/button";
import { Input } from "@magnetic/input";
import { Select } from "@magnetic/select";
import { Checkbox } from "@magnetic/checkbox";
import { Text } from "@magnetic/text";
import { Notification } from "@magnetic/notification";
import { CiscoLogo } from "../common/ciscoLogo";
import countries from "../assets/countries.json";
import purchaseMethods from "../assets/purchaseMethods";
import orgTypeOptions from "../assets/orgTypes";
import { ContactSales } from "./contactSales";

import "./welcomeScreen.scss";
import { LoginContext } from "./loginContext";
import VFlex from "../components/vFlex";
import HFlex from "../components/hFlex";
import { NewOrgRequest, NewOrgResponse } from "../gen/schema/brig/bapi";
import { PurchaseMethod, Theme } from "../gen/schema/models/auth";
import { Apis } from "../utils/api";
import { ApiError } from "./apiError";
import { Constants } from "../utils/constants";
import { OrgType } from "../gen/schema/models/tenancy";

const PHONE = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;

const { Brig } = Apis;

const ERRORS = {
  PHONE: "Please enter a valid phone number",
  COUNTRY: "Please select your country",
  TOC: '"You must accept the terms and contidions to continue"'
};

export const WelcomeScreen = () => {
  const { session, logout, switchTenant } = useContext(LoginContext);
  const [error, setError] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [orgType, setOrgType] = useState<string>(OrgType.ENTERPRISE.toString());
  const [purchaseMethod, setPurchaseMethod] = useState<string>(
    PurchaseMethod.PARTNER.toString()
  );
  const [timeZone] = useState<string>("Local");
  const [toc, setToc] = useState<boolean>(false);
  const [theme] = useState<number>(Theme.LIGHT);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const data = new FormData(e.currentTarget);
      const getFormValue = (name: string): string => {
        return (data.get(name) as string) ?? "";
      };

      const phone = getFormValue("phone");
      /* Phone is a required field, you cannot submit without a value */
      if (phone) {
        if (phone.match(PHONE) === null) {
          setError(ERRORS.PHONE);
          return;
        }
      }
      if (!country) {
        setError(ERRORS.COUNTRY);
        return;
      }
      if (!toc) {
        setError(ERRORS.TOC);
        return;
      }

      const req: NewOrgRequest = NewOrgRequest.create({
        signUp: {
          companyName: getFormValue("orgName"),
          email: session?.email,
          jobTitle: getFormValue("job"),
          phoneNumber: phone,
          country: country,
          orgType: parseInt(orgType),
          purchaseMethod: parseInt(purchaseMethod),
          eulaVersion: new Date().toDateString()
        },
        userPreferences: {
          email: session?.email,
          timezone: timeZone,
          timeFormat: "",
          theme: theme,
          tracked: false
        }
      });
      Brig.newOrg(req).then(
        (resp: NewOrgResponse) => {
          if (resp) {
            switchTenant(resp.id);
          }
        },
        (err: ApiError | Error) => {
          if (err instanceof Error) {
            setError(err.message);
          } else if (err instanceof ApiError) {
            setError(err.getMessage());
          }
        }
      );
    },
    [
      toc,
      theme,
      timeZone,
      country,
      orgType,
      purchaseMethod,
      session?.email,
      switchTenant
    ]
  );

  // TODO: Add Time zone and theme back once we support it
  // const handleThemeChange = useCallback(
  //   (e: React.FormEvent<HTMLInputElement>) => {
  //     setTheme(parseInt(e.currentTarget.value));
  //   },
  //   []
  // );
  //
  // const handleTimeZoneChange = useCallback(
  //   (e: React.FormEvent<HTMLInputElement>) => {
  //     setTimeZone(e.currentTarget.value);
  //   },
  //   []
  // );

  const handleTermsChange = useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setToc((prev) => !prev);
    },
    []
  );

  const handleOrgChange = useCallback((value?: string) => {
    if (value) {
      setOrgType(value);
    }
  }, []);

  const handlePurchaseMethodChange = useCallback((value?: string) => {
    if (value) {
      setPurchaseMethod(value);
    }
  }, []);

  if (session?.allowSignUp === true) {
    return (
      <VFlex className="welcome" align="center" gap="lg">
        <CiscoLogo />
        <Heading size="h1">Welcome to {Constants.APP_NAME}</Heading>
        <p>
          Please provide the following information to begin your journey with
          {Constants.APP_NAME}.
        </p>
        <form onSubmit={onSubmit}>
          <Input
            label="Email"
            name="email"
            type="email"
            value={session?.email}
            required={true}
            fixedWidth={true}
            autoComplete="off"
            disabled={true}
          />
          <Input
            label="Job Title"
            name="job"
            type="text"
            required={true}
            fixedWidth={true}
          />
          <Input
            label="Phone Number"
            invalid={error === ERRORS.PHONE}
            name="phone"
            type="tel"
            size="md"
            required={true}
            fixedWidth={true}
          />
          <label
            htmlFor="network"
            className="mds-rebuild-input-frame mds-rebuild-input-label-size-md"
          >
            <span>
              Country
              <span className="mds-rebuild-input-label-required">*</span>
            </span>

            <Select
              error={error === ERRORS.COUNTRY}
              fixedWidth={true}
              onChange={setCountry}
              value={country}
              options={countries}
              placeholder="Select a country"
            />
          </label>
          <label
            htmlFor="network"
            className="mds-rebuild-input-frame mds-rebuild-input-label-size-md"
          >
            <span>
              Type of network
              <span className="mds-rebuild-input-label-required">*</span>
            </span>
            <Select
              fixedWidth={true}
              onChange={handleOrgChange}
              value={orgType.toString()}
              options={orgTypeOptions}
              placeholder="Select a network"
            />
          </label>
          <label
            htmlFor="network"
            className="mds-rebuild-input-frame mds-rebuild-input-label-size-md"
          >
            <span>
              Purchase method
              <span className="mds-rebuild-input-label-required">*</span>
            </span>
            <Select
              fixedWidth={true}
              onChange={handlePurchaseMethodChange}
              value={purchaseMethod}
              options={purchaseMethods}
              placeholder="Select a purchase method"
            />
          </label>

          <div className="hr" />
          <Text size="p1" weight="semi-bold" align="left">
            Account settings
          </Text>
          <Input
            label="Organization name"
            name="orgName"
            type="text"
            size="md"
            required={true}
            fixedWidth={true}
            autoComplete="off"
          />
          {/**
          <label
            htmlFor="status"
            className="mds-rebuild-input-frame mds-rebuild-input-label-size-md"
          >
            <span>
              Time zone
              <span className="mds-rebuild-input-label-required">*</span>
            </span>
            <Radio
              name="timezone"
              direction="horizontal"
              value={timeZone}
              onChange={handleTimeZoneChange}
              options={[
                {
                  key: "local",
                  label: `Local (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`,
                  value: "Local"
                },
                {
                  key: "utc",
                  label: "UTC",
                  value: "UTC"
                }
              ]}
              size="sm"
            />
          </label>
          <label
            htmlFor="status"
            className="mds-rebuild-input-frame mds-rebuild-input-label-size-md"
          >
            <span>
              Theme
              <span className="mds-rebuild-input-label-required">*</span>
            </span>
            <Radio
              name="theme"
              direction="horizontal"
              value={theme}
              onChange={handleThemeChange}
              options={[
                {
                  key: Theme.LIGHT,
                  label: "Light",
                  value: Theme.LIGHT.valueOf()
                },
                {
                  key: Theme.DARK,
                  label: "Dark",
                  value: Theme.DARK.valueOf()
                },
                {
                  key: Theme.HIGH_CONTRAST,
                  label: "High Contrast",
                  value: Theme.HIGH_CONTRAST.valueOf()
                }
              ]}
              size="sm"
            />
          </label>
           */}
          <Checkbox onClick={handleTermsChange} checked={toc}>
            I agree to the of the
            <Link
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html"
            >
              terms and conditions
            </Link>
            Cisco Cloud offer
          </Checkbox>
          {error ? (
            <Notification status="negative">{error}</Notification>
          ) : null}
          <Button type="submit" size="md" name="getstarted" className="save">
            Get started
          </Button>
          <HFlex align="stretch" justify="center">
            <Button name="logout" kind="tertiary" onClick={logout}>
              Cancel
            </Button>
          </HFlex>
        </form>
      </VFlex>
    );
  }
  return <ContactSales />;
};
