import React, { type ReactElement } from "react";

export const CiscoLogo = (): ReactElement => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 38 24"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2747 15.1997H10.6275V21.7514H12.2747V15.1997Z"
      fill="#F7F7F7"
    />
    <path
      d="M25.5894 17.0761C25.1646 16.8452 24.6892 16.7239 24.206 16.7233C23.9771 16.7233 23.7504 16.7685 23.5389 16.8564C23.3274 16.9442 23.1352 17.073 22.9733 17.2353C22.8115 17.3977 22.6831 17.5904 22.5955 17.8025C22.5079 18.0146 22.4628 18.2419 22.4628 18.4715C22.4628 18.7011 22.5079 18.9284 22.5955 19.1405C22.6831 19.3526 22.8115 19.5453 22.9733 19.7076C23.1352 19.87 23.3274 19.9988 23.5389 20.0866C23.7504 20.1745 23.9771 20.2197 24.206 20.2197C24.6885 20.2218 25.164 20.1033 25.5894 19.8749V21.6311C25.1023 21.7818 24.5958 21.8601 24.086 21.8636C23.1889 21.8636 22.3286 21.5062 21.6943 20.8701C21.0599 20.2339 20.7036 19.3711 20.7036 18.4715C20.7036 17.5718 21.0599 16.709 21.6943 16.0729C22.3286 15.4367 23.1889 15.0793 24.086 15.0793C24.596 15.08 25.1029 15.1584 25.5894 15.3119V17.0761Z"
      fill="#F7F7F7"
    />
    <path
      d="M8.36355 17.0761C7.93941 16.8436 7.46357 16.7222 6.98018 16.7233C6.75125 16.7233 6.52457 16.7685 6.31308 16.8564C6.10158 16.9442 5.90941 17.073 5.74754 17.2353C5.58566 17.3977 5.45726 17.5904 5.36966 17.8025C5.28205 18.0146 5.23696 18.2419 5.23696 18.4715C5.23696 18.7011 5.28205 18.9284 5.36966 19.1405C5.45726 19.3526 5.58566 19.5453 5.74754 19.7076C5.90941 19.87 6.10158 19.9988 6.31308 20.0866C6.52457 20.1745 6.75125 20.2197 6.98018 20.2197C7.46288 20.2232 7.93867 20.1046 8.36355 19.8749V21.6311C7.87656 21.7822 7.37001 21.8606 6.86023 21.8636C5.96314 21.8636 5.1028 21.5062 4.46846 20.8701C3.83412 20.2339 3.47775 19.3711 3.47775 18.4715C3.47775 17.5718 3.83412 16.709 4.46846 16.0729C5.1028 15.4367 5.96314 15.0793 6.86023 15.0793C7.37026 15.0792 7.87727 15.1577 8.36355 15.3119V17.0761Z"
      fill="#F7F7F7"
    />
    <path
      d="M30.7451 16.7555C30.4044 16.7523 30.0704 16.8507 29.7856 17.0382C29.5008 17.2257 29.2779 17.4939 29.1453 17.8086C29.0128 18.1234 28.9764 18.4705 29.0409 18.806C29.1055 19.1415 29.2679 19.4502 29.5077 19.693C29.7475 19.9357 30.0538 20.1015 30.3877 20.1694C30.7217 20.2372 31.0682 20.204 31.3832 20.074C31.6983 19.944 31.9678 19.723 32.1574 19.4391C32.347 19.1553 32.4483 18.8213 32.4483 18.4796C32.4526 18.2534 32.4116 18.0286 32.3277 17.8185C32.2438 17.6084 32.1188 17.4173 31.96 17.2566C31.8012 17.0958 31.6118 16.9687 31.4031 16.8826C31.1945 16.7965 30.9707 16.7533 30.7451 16.7555ZM34.1915 18.4796C34.2042 19.1658 34.0129 19.8402 33.642 20.417C33.2711 20.9937 32.7374 21.4467 32.1089 21.7182C31.4804 21.9896 30.7855 22.0673 30.1128 21.9413C29.4401 21.8153 28.82 21.4912 28.3316 21.0105C27.8432 20.5298 27.5085 19.9141 27.3703 19.242C27.232 18.5698 27.2964 17.8716 27.5553 17.2363C27.8142 16.601 28.2558 16.0574 28.8238 15.6747C29.3919 15.292 30.0607 15.0876 30.7451 15.0875C31.1955 15.0756 31.6437 15.1548 32.0629 15.3202C32.4822 15.4857 32.864 15.7341 33.1856 16.0506C33.5071 16.3671 33.7619 16.7453 33.9347 17.1626C34.1076 17.5799 34.1949 18.0278 34.1915 18.4796Z"
      fill="#F7F7F7"
    />
    <path
      d="M18.5269 16.6909C18.1069 16.5744 17.6749 16.5072 17.2395 16.4905C16.5758 16.4905 16.2239 16.707 16.2239 17.0278C16.2239 17.3485 16.7037 17.565 16.9756 17.6533L17.4314 17.7976C17.8571 17.8895 18.2403 18.1204 18.521 18.4542C18.8018 18.788 18.9641 19.2058 18.9827 19.642C18.9827 21.286 17.5353 21.8393 16.2799 21.8393C15.6841 21.8334 15.09 21.7744 14.5047 21.6629V20.1793C15.0117 20.3287 15.5358 20.4122 16.064 20.4279C16.8636 20.4279 17.2635 20.1873 17.2635 19.8104C17.2635 19.4335 16.9356 19.2892 16.5278 19.1609L16.176 19.0486C15.2564 18.7599 14.4967 18.2467 14.4967 17.132C14.4967 15.9051 15.4083 15.0871 16.8956 15.0871C17.4349 15.0957 17.9714 15.1656 18.4949 15.2956L18.5269 16.6909Z"
      fill="#F7F7F7"
    />
    <path
      d="M1.59928 8.15874C1.59928 7.94605 1.51503 7.74208 1.36507 7.59169C1.21511 7.4413 1.01172 7.35681 0.79964 7.35681C0.587562 7.35681 0.384171 7.4413 0.234209 7.59169C0.0842476 7.74208 0 7.94605 0 8.15874L0 9.88288C0 10.0956 0.0842476 10.2995 0.234209 10.4499C0.384171 10.6003 0.587562 10.6848 0.79964 10.6848C1.01172 10.6848 1.21511 10.6003 1.36507 10.4499C1.51503 10.2995 1.59928 10.0956 1.59928 9.88288V8.15874Z"
      fill="#F7F7F7"
    />
    <path
      d="M6.12408 5.90532C6.12408 5.69264 6.03983 5.48866 5.88987 5.33827C5.73991 5.18788 5.53651 5.10339 5.32444 5.10339C5.11236 5.10339 4.90897 5.18788 4.759 5.33827C4.60904 5.48866 4.5248 5.69264 4.5248 5.90532V9.91495C4.5248 10.1276 4.60904 10.3316 4.759 10.482C4.90897 10.6324 5.11236 10.7169 5.32444 10.7169C5.53651 10.7169 5.73991 10.6324 5.88987 10.482C6.03983 10.3316 6.12408 10.1276 6.12408 9.91495V5.90532Z"
      fill="#F7F7F7"
    />
    <path
      d="M10.6198 2.80193C10.6198 2.58924 10.5356 2.38527 10.3856 2.23488C10.2357 2.08449 10.0323 2 9.82021 2C9.60813 2 9.40474 2.08449 9.25478 2.23488C9.10482 2.38527 9.02057 2.58924 9.02057 2.80193V11.5188C9.02057 11.7315 9.10482 11.9355 9.25478 12.0859C9.40474 12.2363 9.60813 12.3208 9.82021 12.3208C10.0323 12.3208 10.2357 12.2363 10.3856 12.0859C10.5356 11.9355 10.6198 11.7315 10.6198 11.5188V2.80193Z"
      fill="#F7F7F7"
    />
    <path
      d="M15.1125 5.90532C15.1125 5.69264 15.0283 5.48866 14.8783 5.33827C14.7284 5.18788 14.525 5.10339 14.3129 5.10339C14.1008 5.10339 13.8974 5.18788 13.7475 5.33827C13.5975 5.48866 13.5133 5.69264 13.5133 5.90532V9.91495C13.5133 10.1276 13.5975 10.3316 13.7475 10.482C13.8974 10.6324 14.1008 10.7169 14.3129 10.7169C14.525 10.7169 14.7284 10.6324 14.8783 10.482C15.0283 10.3316 15.1125 10.1276 15.1125 9.91495V5.90532Z"
      fill="#F7F7F7"
    />
    <path
      d="M19.6084 8.15874C19.6084 7.94605 19.5241 7.74208 19.3742 7.59169C19.2242 7.4413 19.0208 7.35681 18.8087 7.35681C18.5967 7.35681 18.3933 7.4413 18.2433 7.59169C18.0934 7.74208 18.0091 7.94605 18.0091 8.15874V9.88288C18.0091 10.0956 18.0934 10.2995 18.2433 10.4499C18.3933 10.6003 18.5967 10.6848 18.8087 10.6848C19.0208 10.6848 19.2242 10.6003 19.3742 10.4499C19.5241 10.2995 19.6084 10.0956 19.6084 9.88288V8.15874Z"
      fill="#F7F7F7"
    />
    <path
      d="M24.1012 5.90532C24.1012 5.69264 24.017 5.48866 23.867 5.33827C23.7171 5.18788 23.5137 5.10339 23.3016 5.10339C23.0895 5.10339 22.8861 5.18788 22.7362 5.33827C22.5862 5.48866 22.502 5.69264 22.502 5.90532V9.91495C22.502 10.1276 22.5862 10.3316 22.7362 10.482C22.8861 10.6324 23.0895 10.7169 23.3016 10.7169C23.5137 10.7169 23.7171 10.6324 23.867 10.482C24.017 10.3316 24.1012 10.1276 24.1012 9.91495V5.90532Z"
      fill="#F7F7F7"
    />
    <path
      d="M28.6027 2.80193C28.6027 2.58924 28.5185 2.38527 28.3685 2.23488C28.2186 2.08449 28.0152 2 27.8031 2C27.591 2 27.3876 2.08449 27.2377 2.23488C27.0877 2.38527 27.0035 2.58924 27.0035 2.80193V11.5188C27.0035 11.7315 27.0877 11.9355 27.2377 12.0859C27.3876 12.2363 27.591 12.3208 27.8031 12.3208C28.0152 12.3208 28.2186 12.2363 28.3685 12.0859C28.5185 11.9355 28.6027 11.7315 28.6027 11.5188V2.80193Z"
      fill="#F7F7F7"
    />
    <path
      d="M33.0973 5.90563C33.0973 5.68657 33.0105 5.47648 32.856 5.32158C32.7016 5.16667 32.4921 5.07965 32.2736 5.07965C32.0552 5.07965 31.8457 5.16667 31.6912 5.32158C31.5368 5.47648 31.45 5.68657 31.45 5.90563V9.91526C31.45 10.1343 31.5368 10.3444 31.6912 10.4993C31.8457 10.6542 32.0552 10.7412 32.2736 10.7412C32.4921 10.7412 32.7016 10.6542 32.856 10.4993C33.0105 10.3444 33.0973 10.1343 33.0973 9.91526V5.90563Z"
      fill="#F7F7F7"
    />
    <path
      d="M37.5827 8.15874C37.5827 7.94605 37.4985 7.74208 37.3485 7.59169C37.1986 7.4413 36.9952 7.35681 36.7831 7.35681C36.571 7.35681 36.3676 7.4413 36.2176 7.59169C36.0677 7.74208 35.9834 7.94605 35.9834 8.15874V9.88288C35.9834 10.0956 36.0677 10.2995 36.2176 10.4499C36.3676 10.6003 36.571 10.6848 36.7831 10.6848C36.9952 10.6848 37.1986 10.6003 37.3485 10.4499C37.4985 10.2995 37.5827 10.0956 37.5827 9.88288V8.15874Z"
      fill="#F7F7F7"
    />
  </svg>
);
