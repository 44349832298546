// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bapikeys.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "brig";

export enum ApiKeyType {
  API_KEY_TYPE_UNSPECIFIED = 0,
  RSA = 1,
  ED25519 = 2,
  UNRECOGNIZED = -1,
}

export function apiKeyTypeFromJSON(object: any): ApiKeyType {
  switch (object) {
    case 0:
    case "API_KEY_TYPE_UNSPECIFIED":
      return ApiKeyType.API_KEY_TYPE_UNSPECIFIED;
    case 1:
    case "RSA":
      return ApiKeyType.RSA;
    case 2:
    case "ED25519":
      return ApiKeyType.ED25519;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApiKeyType.UNRECOGNIZED;
  }
}

export function apiKeyTypeToJSON(object: ApiKeyType): string {
  switch (object) {
    case ApiKeyType.API_KEY_TYPE_UNSPECIFIED:
      return "API_KEY_TYPE_UNSPECIFIED";
    case ApiKeyType.RSA:
      return "RSA";
    case ApiKeyType.ED25519:
      return "ED25519";
    case ApiKeyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ApiKey {
  type: ApiKeyType;
  fingerprint: string;
  id: string;
  description: string;
}

/** A list of one or more enabled and active API Keys. */
export interface ApiKeysResponse {
  keys: ApiKey[];
}

export interface UploadApiKeyRequest {
  keyData: string;
}

export interface GetApiKeysRequest {
}

export interface DeleteApiKeyRequest {
  /** The id of the key to delete. */
  keyId: string;
}

function createBaseApiKey(): ApiKey {
  return { type: 0, fingerprint: "", id: "", description: "" };
}

export const ApiKey = {
  encode(message: ApiKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.fingerprint !== "") {
      writer.uint32(18).string(message.fingerprint);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiKey {
    return {
      type: isSet(object.type) ? apiKeyTypeFromJSON(object.type) : 0,
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: ApiKey): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = apiKeyTypeToJSON(message.type);
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiKey>, I>>(base?: I): ApiKey {
    return ApiKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiKey>, I>>(object: I): ApiKey {
    const message = createBaseApiKey();
    message.type = object.type ?? 0;
    message.fingerprint = object.fingerprint ?? "";
    message.id = object.id ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseApiKeysResponse(): ApiKeysResponse {
  return { keys: [] };
}

export const ApiKeysResponse = {
  encode(message: ApiKeysResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.keys) {
      ApiKey.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiKeysResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiKeysResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(ApiKey.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiKeysResponse {
    return { keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e: any) => ApiKey.fromJSON(e)) : [] };
  },

  toJSON(message: ApiKeysResponse): unknown {
    const obj: any = {};
    if (message.keys?.length) {
      obj.keys = message.keys.map((e) => ApiKey.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiKeysResponse>, I>>(base?: I): ApiKeysResponse {
    return ApiKeysResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiKeysResponse>, I>>(object: I): ApiKeysResponse {
    const message = createBaseApiKeysResponse();
    message.keys = object.keys?.map((e) => ApiKey.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUploadApiKeyRequest(): UploadApiKeyRequest {
  return { keyData: "" };
}

export const UploadApiKeyRequest = {
  encode(message: UploadApiKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyData !== "") {
      writer.uint32(10).string(message.keyData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadApiKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadApiKeyRequest {
    return { keyData: isSet(object.keyData) ? globalThis.String(object.keyData) : "" };
  },

  toJSON(message: UploadApiKeyRequest): unknown {
    const obj: any = {};
    if (message.keyData !== "") {
      obj.keyData = message.keyData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadApiKeyRequest>, I>>(base?: I): UploadApiKeyRequest {
    return UploadApiKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadApiKeyRequest>, I>>(object: I): UploadApiKeyRequest {
    const message = createBaseUploadApiKeyRequest();
    message.keyData = object.keyData ?? "";
    return message;
  },
};

function createBaseGetApiKeysRequest(): GetApiKeysRequest {
  return {};
}

export const GetApiKeysRequest = {
  encode(_: GetApiKeysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetApiKeysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApiKeysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetApiKeysRequest {
    return {};
  },

  toJSON(_: GetApiKeysRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetApiKeysRequest>, I>>(base?: I): GetApiKeysRequest {
    return GetApiKeysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetApiKeysRequest>, I>>(_: I): GetApiKeysRequest {
    const message = createBaseGetApiKeysRequest();
    return message;
  },
};

function createBaseDeleteApiKeyRequest(): DeleteApiKeyRequest {
  return { keyId: "" };
}

export const DeleteApiKeyRequest = {
  encode(message: DeleteApiKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyId !== "") {
      writer.uint32(10).string(message.keyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteApiKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteApiKeyRequest {
    return { keyId: isSet(object.keyId) ? globalThis.String(object.keyId) : "" };
  },

  toJSON(message: DeleteApiKeyRequest): unknown {
    const obj: any = {};
    if (message.keyId !== "") {
      obj.keyId = message.keyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteApiKeyRequest>, I>>(base?: I): DeleteApiKeyRequest {
    return DeleteApiKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteApiKeyRequest>, I>>(object: I): DeleteApiKeyRequest {
    const message = createBaseDeleteApiKeyRequest();
    message.keyId = object.keyId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
