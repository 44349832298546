// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/assert.proto

/* eslint-disable */

export const protobufPackage = "models";

/** AssertCategoryType specifies the various kinds of assertion categories. */
export enum AssertCategoryType {
  /** ASSERT_CATEGORY_UNSPECIFIED - Invalid Assert Category type. */
  ASSERT_CATEGORY_UNSPECIFIED = 0,
  /** ASSERT_CATEGORY_FABRIC - Used for Fabric related assertion types */
  ASSERT_CATEGORY_FABRIC = 1,
  /** ASSERT_CATEGORY_UPSTREAM_PEERING - Used for Upstream Peering related assertion types. */
  ASSERT_CATEGORY_UPSTREAM_PEERING = 2,
  /** ASSERT_CATEGORY_HOST_ATTACHMENT - Used for Host Attachment related assertion types. */
  ASSERT_CATEGORY_HOST_ATTACHMENT = 3,
  /** ASSERT_CATEGORY_SERVICES - Used for Service related assertion types. */
  ASSERT_CATEGORY_SERVICES = 4,
  /** ASSERT_CATEGORY_COMPLIANCE - Used for Compliance related assertion types. */
  ASSERT_CATEGORY_COMPLIANCE = 5,
  /** ASSERT_CATEGORY_SECURITY - Used for Security related assertion types. */
  ASSERT_CATEGORY_SECURITY = 6,
  UNRECOGNIZED = -1,
}

export function assertCategoryTypeFromJSON(object: any): AssertCategoryType {
  switch (object) {
    case 0:
    case "ASSERT_CATEGORY_UNSPECIFIED":
      return AssertCategoryType.ASSERT_CATEGORY_UNSPECIFIED;
    case 1:
    case "ASSERT_CATEGORY_FABRIC":
      return AssertCategoryType.ASSERT_CATEGORY_FABRIC;
    case 2:
    case "ASSERT_CATEGORY_UPSTREAM_PEERING":
      return AssertCategoryType.ASSERT_CATEGORY_UPSTREAM_PEERING;
    case 3:
    case "ASSERT_CATEGORY_HOST_ATTACHMENT":
      return AssertCategoryType.ASSERT_CATEGORY_HOST_ATTACHMENT;
    case 4:
    case "ASSERT_CATEGORY_SERVICES":
      return AssertCategoryType.ASSERT_CATEGORY_SERVICES;
    case 5:
    case "ASSERT_CATEGORY_COMPLIANCE":
      return AssertCategoryType.ASSERT_CATEGORY_COMPLIANCE;
    case 6:
    case "ASSERT_CATEGORY_SECURITY":
      return AssertCategoryType.ASSERT_CATEGORY_SECURITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertCategoryType.UNRECOGNIZED;
  }
}

export function assertCategoryTypeToJSON(object: AssertCategoryType): string {
  switch (object) {
    case AssertCategoryType.ASSERT_CATEGORY_UNSPECIFIED:
      return "ASSERT_CATEGORY_UNSPECIFIED";
    case AssertCategoryType.ASSERT_CATEGORY_FABRIC:
      return "ASSERT_CATEGORY_FABRIC";
    case AssertCategoryType.ASSERT_CATEGORY_UPSTREAM_PEERING:
      return "ASSERT_CATEGORY_UPSTREAM_PEERING";
    case AssertCategoryType.ASSERT_CATEGORY_HOST_ATTACHMENT:
      return "ASSERT_CATEGORY_HOST_ATTACHMENT";
    case AssertCategoryType.ASSERT_CATEGORY_SERVICES:
      return "ASSERT_CATEGORY_SERVICES";
    case AssertCategoryType.ASSERT_CATEGORY_COMPLIANCE:
      return "ASSERT_CATEGORY_COMPLIANCE";
    case AssertCategoryType.ASSERT_CATEGORY_SECURITY:
      return "ASSERT_CATEGORY_SECURITY";
    case AssertCategoryType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** AssertStateType specifies the various kinds of states of an assertions. */
export enum AssertStateType {
  /** ASSERT_STATE_UNSPECIFIED - Invalid Assert type. */
  ASSERT_STATE_UNSPECIFIED = 0,
  /** ASSERT_STATE_UNKNOWN - Assertion state is unknown, note this is not the same as UNSPECIFIED as it can be a valid state. */
  ASSERT_STATE_UNKNOWN = 1,
  /** ASSERT_STATE_TRUE - Assertion state is true. */
  ASSERT_STATE_TRUE = 2,
  /** ASSERT_STATE_FALSE - Assertion state is false. */
  ASSERT_STATE_FALSE = 3,
  UNRECOGNIZED = -1,
}

export function assertStateTypeFromJSON(object: any): AssertStateType {
  switch (object) {
    case 0:
    case "ASSERT_STATE_UNSPECIFIED":
      return AssertStateType.ASSERT_STATE_UNSPECIFIED;
    case 1:
    case "ASSERT_STATE_UNKNOWN":
      return AssertStateType.ASSERT_STATE_UNKNOWN;
    case 2:
    case "ASSERT_STATE_TRUE":
      return AssertStateType.ASSERT_STATE_TRUE;
    case 3:
    case "ASSERT_STATE_FALSE":
      return AssertStateType.ASSERT_STATE_FALSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertStateType.UNRECOGNIZED;
  }
}

export function assertStateTypeToJSON(object: AssertStateType): string {
  switch (object) {
    case AssertStateType.ASSERT_STATE_UNSPECIFIED:
      return "ASSERT_STATE_UNSPECIFIED";
    case AssertStateType.ASSERT_STATE_UNKNOWN:
      return "ASSERT_STATE_UNKNOWN";
    case AssertStateType.ASSERT_STATE_TRUE:
      return "ASSERT_STATE_TRUE";
    case AssertStateType.ASSERT_STATE_FALSE:
      return "ASSERT_STATE_FALSE";
    case AssertStateType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
