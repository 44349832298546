import { BaseColumnConfig, Config } from "./baseColumnConfig";
import { Constants } from "../../../utils/constants";

interface NumberColumnConfig extends Config {
  percision?: number;
}

export class NumberColumn extends BaseColumnConfig<number> {
  /**
   * used to limit the number of decimal
   * places to show
   */
  protected percision: number | undefined;
  constructor(cfg: NumberColumnConfig) {
    super(cfg);
    this.percision = cfg.percision;
  }
  compare(a: number = 0, b: number = 0) {
    return a - b;
  }
  render(data: number) {
    if (this.percision) {
      return <div>{data.toFixed(this.percision)}</div>;
    }
    return <div>{data}</div>;
  }
}

export class PercentColumn extends BaseColumnConfig<number | undefined> {
  compare(a: number = 0, b: number = 0) {
    return a - b;
  }
  getValue(data: number): string {
    if (data) {
      if (data < 1 && data > 0) {
        return `${data * 100}%`;
      }
      return `${data}%`;
    }
    return Constants.EMPTY_VALUE;
  }
  render(data: number) {
    return <div className="percent">{this.getValue(data)}</div>;
  }
}
