// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: common/grpc.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Any } from "../google/protobuf/any";

export const protobufPackage = "common";

/**
 * PaginationRequest defines common pagination properties that all GET gRPC requests
 * should have. PaginationRequest is to be embedded in all GET gRPC request messages.
 */
export interface PaginationRequest {
  /** Maximum number of objects to return. */
  limit: number;
  /** Start after the supplied cursor. */
  cursor: string;
  /** Sort by this field. */
  sortBy: string;
  /** Sort in the descending order. */
  sortDesc: boolean;
}

/**
 * PaginationResponse defines common pagination properties that all GET gRPC responses
 * should have. PaginationResponse is to be embedded in all GET gRPC response messages.
 */
export interface PaginationResponse {
  /** Total number of objects found by query. */
  total: number;
  /** Last used search cursor. Next pagination will start after this. */
  cursor: string;
  /** Indicates an end of query results. */
  final: boolean;
}

/**
 * Error encapsulates properties of a service gRPC error. Error objects are returned
 * as details in a GrpcError object. In other words, GrpcError.Details = []Error.
 */
export interface Error {
  /** The error message. */
  message: string;
  /** The error tag. */
  tag: string;
  /** The name of relevant field. */
  field: string;
  /** The value of relevant field. */
  value: string;
  /** Additional information about the error. */
  notes: string;
  /** The error code. */
  code: number;
  /** Indicates that the error is a critical error. */
  critical: boolean;
  /** A list of underlying causes. */
  causes: string[];
}

/**
 * The `Status` type defines a logical error model that is suitable for
 * different programming environments, including REST APIs and RPC APIs. It is
 * used by [gRPC](https://github.com/grpc). Each `Status` message contains
 * three pieces of data: error code, error message, and error details.
 *
 * You can find out more about this error model and how to work with it in the
 * [API Design Guide](https://cloud.google.com/apis/design/errors).
 * FIXME
 *    TORT-2196: Remove this once the UI moves from gRPC to REST. See Jira for some extra details.
 */
export interface Status {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code: number;
  /**
   * A developer-facing error message, which should be in English. Any
   * user-facing error message should be localized and sent in the
   * [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client.
   */
  message: string;
  /**
   * A list of messages that carry the error details.  There is a common set of
   * message types for APIs to use.
   */
  details: Any[];
}

function createBasePaginationRequest(): PaginationRequest {
  return { limit: 0, cursor: "", sortBy: "", sortDesc: false };
}

export const PaginationRequest = {
  encode(message: PaginationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).uint32(message.limit);
    }
    if (message.cursor !== "") {
      writer.uint32(18).string(message.cursor);
    }
    if (message.sortBy !== "") {
      writer.uint32(26).string(message.sortBy);
    }
    if (message.sortDesc !== false) {
      writer.uint32(32).bool(message.sortDesc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaginationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaginationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sortBy = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sortDesc = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaginationRequest {
    return {
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : "",
      sortBy: isSet(object.sortBy) ? globalThis.String(object.sortBy) : "",
      sortDesc: isSet(object.sortDesc) ? globalThis.Boolean(object.sortDesc) : false,
    };
  },

  toJSON(message: PaginationRequest): unknown {
    const obj: any = {};
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.cursor !== "") {
      obj.cursor = message.cursor;
    }
    if (message.sortBy !== "") {
      obj.sortBy = message.sortBy;
    }
    if (message.sortDesc !== false) {
      obj.sortDesc = message.sortDesc;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaginationRequest>, I>>(base?: I): PaginationRequest {
    return PaginationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaginationRequest>, I>>(object: I): PaginationRequest {
    const message = createBasePaginationRequest();
    message.limit = object.limit ?? 0;
    message.cursor = object.cursor ?? "";
    message.sortBy = object.sortBy ?? "";
    message.sortDesc = object.sortDesc ?? false;
    return message;
  },
};

function createBasePaginationResponse(): PaginationResponse {
  return { total: 0, cursor: "", final: false };
}

export const PaginationResponse = {
  encode(message: PaginationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== 0) {
      writer.uint32(8).uint32(message.total);
    }
    if (message.cursor !== "") {
      writer.uint32(18).string(message.cursor);
    }
    if (message.final !== false) {
      writer.uint32(24).bool(message.final);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaginationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaginationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.total = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.final = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaginationResponse {
    return {
      total: isSet(object.total) ? globalThis.Number(object.total) : 0,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : "",
      final: isSet(object.final) ? globalThis.Boolean(object.final) : false,
    };
  },

  toJSON(message: PaginationResponse): unknown {
    const obj: any = {};
    if (message.total !== 0) {
      obj.total = Math.round(message.total);
    }
    if (message.cursor !== "") {
      obj.cursor = message.cursor;
    }
    if (message.final !== false) {
      obj.final = message.final;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaginationResponse>, I>>(base?: I): PaginationResponse {
    return PaginationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaginationResponse>, I>>(object: I): PaginationResponse {
    const message = createBasePaginationResponse();
    message.total = object.total ?? 0;
    message.cursor = object.cursor ?? "";
    message.final = object.final ?? false;
    return message;
  },
};

function createBaseError(): Error {
  return { message: "", tag: "", field: "", value: "", notes: "", code: 0, critical: false, causes: [] };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.tag !== "") {
      writer.uint32(18).string(message.tag);
    }
    if (message.field !== "") {
      writer.uint32(26).string(message.field);
    }
    if (message.value !== "") {
      writer.uint32(34).string(message.value);
    }
    if (message.notes !== "") {
      writer.uint32(42).string(message.notes);
    }
    if (message.code !== 0) {
      writer.uint32(48).int32(message.code);
    }
    if (message.critical !== false) {
      writer.uint32(56).bool(message.critical);
    }
    for (const v of message.causes) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.field = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.code = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.critical = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.causes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Error {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      field: isSet(object.field) ? globalThis.String(object.field) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      notes: isSet(object.notes) ? globalThis.String(object.notes) : "",
      code: isSet(object.code) ? globalThis.Number(object.code) : 0,
      critical: isSet(object.critical) ? globalThis.Boolean(object.critical) : false,
      causes: globalThis.Array.isArray(object?.causes) ? object.causes.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.field !== "") {
      obj.field = message.field;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.notes !== "") {
      obj.notes = message.notes;
    }
    if (message.code !== 0) {
      obj.code = Math.round(message.code);
    }
    if (message.critical !== false) {
      obj.critical = message.critical;
    }
    if (message.causes?.length) {
      obj.causes = message.causes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Error>, I>>(base?: I): Error {
    return Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.message = object.message ?? "";
    message.tag = object.tag ?? "";
    message.field = object.field ?? "";
    message.value = object.value ?? "";
    message.notes = object.notes ?? "";
    message.code = object.code ?? 0;
    message.critical = object.critical ?? false;
    message.causes = object.causes?.map((e) => e) || [];
    return message;
  },
};

function createBaseStatus(): Status {
  return { code: 0, message: "", details: [] };
}

export const Status = {
  encode(message: Status, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.details) {
      Any.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details.push(Any.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status {
    return {
      code: isSet(object.code) ? globalThis.Number(object.code) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      details: globalThis.Array.isArray(object?.details) ? object.details.map((e: any) => Any.fromJSON(e)) : [],
    };
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = Math.round(message.code);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.details?.length) {
      obj.details = message.details.map((e) => Any.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status>, I>>(base?: I): Status {
    return Status.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status>, I>>(object: I): Status {
    const message = createBaseStatus();
    message.code = object.code ?? 0;
    message.message = object.message ?? "";
    message.details = object.details?.map((e) => Any.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
