import { PathId } from "../gen/schema/schema_path/path_id";

export const mergeStreams = <T extends Partial<{ id: PathId }>>(
  prev: T[],
  curr: T[],
  keyFn: (d: T) => string
): T[] => {
  const mergeMap = new Map();
  prev.forEach((d: T) => {
    const key = keyFn(d);
    if (key) {
      mergeMap.set(key, d);
      // console.log("add", key, mergeMap.size, d);
    }
  });
  curr.forEach((d: T) => {
    // TODO: handle reset
    const key = keyFn(d);
    if (key) {
      // tombstones... yes this should be typesafe...
      if (d?.id?.deleted) {
        mergeMap.delete(key);
        // console.log("delete", key, mergeMap.size, d);
      } else {
        mergeMap.set(key, d);
        // console.log("add", key, mergeMap.size, d);
      }
    }
  });
  return Array.from(mergeMap.values());
};
