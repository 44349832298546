import { GrpcWebImpl } from "../../gen/schema/brig/bservice";
import {
  CommitFabricCandidateRequest,
  CommitFabricCandidateResponse,
  GetNodesPortsRequest,
  GetNodesPortsResponse,
  GetNodesRequest,
  GetFabricCandidatesRequest,
  GetFabricCandidatesResponse,
  GetFabricConfigRequest,
  GetFabricConfigResponse,
  GetFabricInventoriesRequest,
  GetFabricInventoriesResponse,
  GetFabricsRequest,
  GetFabricsResponse,
  GetVnisRequest,
  GetVrfsRequest,
  ProvisionRequest,
  ProvisionResponse,
  RevertFabricCandidateRequest,
  ReviewFabricCandidateRequest,
  ReviewFabricCandidateResponse,
  GetVlansRequest
} from "../../gen/schema/configd/api";
import {
  AddManagementPortsRequest,
  GetManagementPortsRequest,
  ManagementPortsResponse,
  UpdateManagementPortRequest
} from "../../gen/schema/configd/node";
import { ConfigDClientImpl } from "../../gen/schema/configd/service";
import { ManagementPort } from "../../gen/schema/models/models";
import { Empty } from "../../gen/schema/common/empty";

const rpc = new GrpcWebImpl("", { debug: false });
const configD = new ConfigDClientImpl(rpc);

/**
 * Basic access to the Raw GRPC
 * ConfigD api(s). Maybe not
 * used except in cases where you
 * need more control over generating
 * the request with custom filters
 * or other configs
 */
export const ConfigD = {
  getFabricConfig: (
    req: Partial<GetFabricConfigRequest>
  ): Promise<GetFabricConfigResponse> => {
    return configD.GetFabricConfig(req);
  },
  getFabrics: (
    req: Partial<GetFabricsRequest>
  ): Promise<GetFabricsResponse> => {
    return configD.GetFabrics(req);
  },
  getFabricTransactions: (
    req: Partial<GetFabricCandidatesRequest>
  ): Promise<GetFabricCandidatesResponse> => {
    return configD.GetFabricCandidates(req);
  },
  getSwitch: (req: Partial<GetNodesRequest>) => {
    return configD.GetNodes(req);
  },
  getFabricInventory: (
    req: Partial<GetFabricInventoriesRequest>
  ): Promise<GetFabricInventoriesResponse> => {
    return configD.GetFabricInventories(req);
  },
  addManagementPort: (
    req: Partial<AddManagementPortsRequest>
  ): Promise<ManagementPortsResponse> => {
    return configD.AddManagementPorts(req);
  },
  updateManagementPort: (
    req: Partial<UpdateManagementPortRequest>
  ): Promise<ManagementPort> => {
    return configD.UpdateManagementPort(req);
  },
  getSwitchPorts: (
    req: Partial<GetNodesPortsRequest>
  ): Promise<GetNodesPortsResponse> => {
    return configD.GetNodesPorts(req);
  },
  getVnis: (req: Partial<GetVnisRequest>) => {
    return configD.GetVnis(req);
  },
  getVlans: (req: Partial<GetVlansRequest>) => {
    return configD.GetVlans(req);
  },
  getVRFs: (req: Partial<GetVrfsRequest>) => {
    return configD.GetVrfs(req);
  },
  getManagementPort: (req: Partial<GetManagementPortsRequest>) => {
    return configD.GetManagementPorts(req);
  },
  reviewFabricTransaction: (
    req: Partial<ReviewFabricCandidateRequest>
  ): Promise<ReviewFabricCandidateResponse> => {
    return configD.ReviewFabricCandidate(req);
  },
  // Provisioning APIs
  provision: (req: Partial<ProvisionRequest>): Promise<ProvisionResponse> => {
    return configD.Provision(req);
  },
  commitFabricCandidate: (
    req: Partial<CommitFabricCandidateRequest>
  ): Promise<CommitFabricCandidateResponse> => {
    return configD.CommitFabricCandidate(req);
  },
  revertFabricCandidate: (
    req: Partial<RevertFabricCandidateRequest>
  ): Promise<Empty> => {
    return configD.RevertFabricCandidate(req);
  }
};
