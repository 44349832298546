import React from "react";

type RenderedData = React.ReactElement | React.ReactNode | null;

interface DataTypeInterface {
  tag: string;
  toString: () => string;
  render: () => RenderedData;
}

abstract class BaseType<T> implements DataTypeInterface {
  abstract tag: string;
  protected readonly value: T | undefined;
  constructor(value: T) {
    this.value = value;
  }
  toString(): string {
    return String(this.value).toString();
  }
  render(): RenderedData {
    return <div className={this.tag}>{this.toString()}</div>;
  }
}

/**
 * Base usable abstract class for handling Data Types.
 */
export abstract class BaseDataType<T> extends BaseType<T> {
  abstract valueOf(): T | undefined;
  abstract compare(a: BaseType<T>): number;
}

/**
 * For more complex types that might take one type but
 * return some subset or calculated value. This is an
 * abstract class where subclasses need to implement
 * the logic that derives the returned value.
 */
export abstract class ComplexDataType<T, V> extends BaseType<T> {
  abstract valueOf(): V | undefined;
  abstract compare(a: BaseType<T>): number;
}
