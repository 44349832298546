import { BaseColumnConfig } from "./baseColumnConfig";

// DO NOT USE THIS
export class JsxColumn extends BaseColumnConfig<JSX.Element> {
  // can't sort
  compare(): number {
    return 0;
  }
  render(val: JSX.Element | undefined): JSX.Element {
    return <div>{val}</div>;
  }
}
