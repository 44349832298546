import { numberComparator } from "../../../utils/comparator";
import { BaseColumnConfig, Config } from "./baseColumnConfig";

export class TimeColumn extends BaseColumnConfig<Date> {
  constructor(cfg: Config) {
    super(cfg);
  }

  compare(a: Date | undefined, b: Date | undefined): number {
    return numberComparator(a?.getMilliseconds(), b?.getMilliseconds());
  }

  toString(val: Date | undefined): string {
    return val?.toLocaleTimeString() || "";
  }

  render(val: Date | undefined): JSX.Element {
    const content = this.toString(val);
    return <div>{content}</div>;
  }
}
