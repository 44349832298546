// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/tenant.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Annotation } from "./global";
import { Metadata } from "./types";

export const protobufPackage = "models";

/**
 * A tenant object.
 *
 * INTERNAL
 *
 * Tenant defines a tenant object in Tortuga. Tenants are children of organization.
 * Users are children of tenant.  Each organization comes with a default-tenant that
 * has the same identifier as its parent organization.  ADMIN users of the default
 * tenant can create additional tenants of that organization.  Users of the
 * default-tenant can see all fabrics (and all nodes of those fabrics) owned by
 * the organization.  Users of the non-default tenants can see only the overlay
 * network parts of the fabric.  Typically, a default-tenant ADMIN will set up the
 * fabric(s) and create overlay networks and assign ownership to non-default tenants.
 * When a new organization is created, the default-tenant is created along with it.
 * The user that creates a new tenant is the first ADMIN of that tenant.
 * FIXME: everything starting with asn below should be removed from configd.
 * Brig should handle Tenant and configd should handle only ProvisionTenants.
 */
export interface Tenant {
  /**
   * The unique identifier of the Tenant. Identifier is required to update an existing
   * Tenant. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the Tenant. Tenant name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Tenant. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /**
   * A set of annotations to store user-defined data. Tortuga allows up to four
   * annotations per object. If caller needs more than four, then caller may store
   * them as JSON in an annotation.
   */
  annotations: Annotation[];
  /** Indicates if Tenant is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the Tenant object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** Indicates if this Tenant object is the default-tenant of organization. */
  isDefault: boolean;
}

function createBaseTenant(): Tenant {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    isDefault: false,
  };
}

export const Tenant = {
  encode(message: Tenant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.isDefault !== false) {
      writer.uint32(80).bool(message.isDefault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tenant {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
    };
  },

  toJSON(message: Tenant): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tenant>, I>>(base?: I): Tenant {
    return Tenant.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tenant>, I>>(object: I): Tenant {
    const message = createBaseTenant();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.isDefault = object.isDefault ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
