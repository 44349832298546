import * as React from "react";
import { useCallback, useContext } from "react";
import { Button } from "@magnetic/button";
import {
  CandidateMode,
  DataProviderContext
} from "../../core/provider/dataProvider";
import { withShowToggle } from "../../components/hoc/withShowToggle";
import HFlex from "../../components/hFlex";
import "./candidateActions.scss";
import { Arrows } from "../../assets/icons/arrows";
import { BulletList } from "../../assets/icons/bulletList";
import { useNavigate } from "react-router-dom";
import { usePreviewChangesDrawer } from "./previewChanges";
import { getFabricRoute } from "../../utils/routesUtils";

interface ButtonProps {
  handleAction: () => void;
}

const SwitchToEdit = withShowToggle(({ handleAction }: ButtonProps) => {
  return (
    <Button kind="tertiary" onClick={handleAction}>
      <Arrows /> Switch to edit mode
    </Button>
  );
});

const ViewRunning = withShowToggle(({ handleAction }: ButtonProps) => {
  return (
    <Button kind="tertiary" onClick={handleAction}>
      <Arrows /> View running mode
    </Button>
  );
});

const PreviewChanges = withShowToggle(({ handleAction }: ButtonProps) => {
  return (
    <Button kind="secondary" onClick={handleAction}>
      <BulletList />
      Preview changes
    </Button>
  );
});

const ReviewConfiguration = withShowToggle(({ handleAction }: ButtonProps) => {
  return (
    <Button kind="primary" onClick={handleAction}>
      Review configuration
    </Button>
  );
});

export const CandidateActions = () => {
  const { previewChangesDrawer, openPreviewChangesDrawer } =
    usePreviewChangesDrawer();
  const { setMode, mode, fabric } = useContext(DataProviderContext);

  const redirect = useNavigate();

  const reviewConfiguration = useCallback(() => {
    if (fabric) {
      redirect(getFabricRoute(fabric.id, "candidate"));
    }
  }, [redirect, fabric]);

  return (
    <HFlex gap={4} className="action-section" align="center" justify="start">
      <SwitchToEdit
        show={mode === CandidateMode.Running}
        handleAction={() => setMode(CandidateMode.Edit)}
      />
      <ViewRunning
        show={mode === CandidateMode.Edit}
        handleAction={() => setMode(CandidateMode.Running)}
      />
      <PreviewChanges
        show={mode === CandidateMode.Edit}
        handleAction={openPreviewChangesDrawer}
      />
      <ReviewConfiguration
        show={mode === CandidateMode.Edit}
        handleAction={reviewConfiguration}
      />
      {previewChangesDrawer}
    </HFlex>
  );
};
