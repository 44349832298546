// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/rest_sensors.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Fan, Psu, TempSensor } from "../schema/schema";

export const protobufPackage = "sextant";

export interface Sensor {
  power: Psu | undefined;
  fan: Fan | undefined;
  temperature: TempSensor | undefined;
}

export interface SensorsResponse {
  sensors: Sensor[];
}

export interface GetDeviceSensorsRequest {
  /** The device id or name. */
  deviceId: string;
  /** The fabric id or name. */
  fabricId: string;
}

function createBaseSensor(): Sensor {
  return { power: undefined, fan: undefined, temperature: undefined };
}

export const Sensor = {
  encode(message: Sensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.power !== undefined) {
      Psu.encode(message.power, writer.uint32(10).fork()).ldelim();
    }
    if (message.fan !== undefined) {
      Fan.encode(message.fan, writer.uint32(18).fork()).ldelim();
    }
    if (message.temperature !== undefined) {
      TempSensor.encode(message.temperature, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.power = Psu.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fan = Fan.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.temperature = TempSensor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sensor {
    return {
      power: isSet(object.power) ? Psu.fromJSON(object.power) : undefined,
      fan: isSet(object.fan) ? Fan.fromJSON(object.fan) : undefined,
      temperature: isSet(object.temperature) ? TempSensor.fromJSON(object.temperature) : undefined,
    };
  },

  toJSON(message: Sensor): unknown {
    const obj: any = {};
    if (message.power !== undefined) {
      obj.power = Psu.toJSON(message.power);
    }
    if (message.fan !== undefined) {
      obj.fan = Fan.toJSON(message.fan);
    }
    if (message.temperature !== undefined) {
      obj.temperature = TempSensor.toJSON(message.temperature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Sensor>, I>>(base?: I): Sensor {
    return Sensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Sensor>, I>>(object: I): Sensor {
    const message = createBaseSensor();
    message.power = (object.power !== undefined && object.power !== null) ? Psu.fromPartial(object.power) : undefined;
    message.fan = (object.fan !== undefined && object.fan !== null) ? Fan.fromPartial(object.fan) : undefined;
    message.temperature = (object.temperature !== undefined && object.temperature !== null)
      ? TempSensor.fromPartial(object.temperature)
      : undefined;
    return message;
  },
};

function createBaseSensorsResponse(): SensorsResponse {
  return { sensors: [] };
}

export const SensorsResponse = {
  encode(message: SensorsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sensors) {
      Sensor.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SensorsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSensorsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sensors.push(Sensor.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SensorsResponse {
    return {
      sensors: globalThis.Array.isArray(object?.sensors) ? object.sensors.map((e: any) => Sensor.fromJSON(e)) : [],
    };
  },

  toJSON(message: SensorsResponse): unknown {
    const obj: any = {};
    if (message.sensors?.length) {
      obj.sensors = message.sensors.map((e) => Sensor.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SensorsResponse>, I>>(base?: I): SensorsResponse {
    return SensorsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SensorsResponse>, I>>(object: I): SensorsResponse {
    const message = createBaseSensorsResponse();
    message.sensors = object.sensors?.map((e) => Sensor.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeviceSensorsRequest(): GetDeviceSensorsRequest {
  return { deviceId: "", fabricId: "" };
}

export const GetDeviceSensorsRequest = {
  encode(message: GetDeviceSensorsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceSensorsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceSensorsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceSensorsRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetDeviceSensorsRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceSensorsRequest>, I>>(base?: I): GetDeviceSensorsRequest {
    return GetDeviceSensorsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceSensorsRequest>, I>>(object: I): GetDeviceSensorsRequest {
    const message = createBaseGetDeviceSensorsRequest();
    message.deviceId = object.deviceId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
