import { Button } from "@magnetic/button";
import { Link } from "react-router-dom";

interface WrappedLinkProps {
  readonly targetRoute: string;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly name: string;
}

const WrappedLink = ({
  targetRoute,
  children,
  className,
  name
}: WrappedLinkProps) => {
  return (
    <Link
      key={name}
      to={targetRoute}
      title={`${window.location.protocol}//${window.location.host}${targetRoute}`}
    >
      <Button kind="tertiary" className={className || ""}>
        {children}
      </Button>
    </Link>
  );
};

export { WrappedLink as Link };
