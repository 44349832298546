import { ApiError } from "../../apiError";

/**
 * The update callback is basically a
 * setState Dispatch function, so it
 * might take any form of data. it
 * is dependent on the client.
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type UpdateHandler = (data: any, loading: boolean) => void;

export type ErrorHandler = (error: ApiError | undefined) => void;

export class StreamClient {
  public id: string = crypto.randomUUID();
  updateFn: UpdateHandler;
  errorFn: ErrorHandler;
  constructor(onUpdate: UpdateHandler, onError: ErrorHandler) {
    this.updateFn = onUpdate;
    this.errorFn = onError;
  }
}
