import { RefObject, useLayoutEffect, useRef, useState } from "react";

function useElementResizeObserver<T extends HTMLElement>(): [
  RefObject<T>,
  number,
  number
] {
  const ref = useRef<T>(null);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const element = ref?.current;

    const observer = new ResizeObserver(() => {
      if (element) {
        setWidth(element.clientWidth);
        setHeight(element.clientHeight);
      }
    });
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, [ref]);

  return [ref, width, height];
}

export default useElementResizeObserver;
