export function numberComparator(
  a: number | undefined,
  b: number | undefined
): number {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

export function stringComparator(
  a: string | undefined,
  b: string | undefined
): number {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return a.localeCompare(b, undefined, { numeric: true });
}

export function booleanComparator(a: boolean, b: boolean): number {
  if (a === b) {
    return 0;
  } else if (a) {
    return -1;
  }
  return 1;
}
