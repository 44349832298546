import React from "react";

import { Flex, FlexProps } from "@magnetic/flex";

export default function VFlex(props: FlexProps) {
  return (
    <Flex gap={props.gap || "md"} direction="vertical" {...props}>
      {props.children}
    </Flex>
  );
}
