// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/onprem.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { WorkflowFeedback } from "../onprem/onprem";
import { ChassisInventory, MgmtConfig } from "../statsd/statsd";

export const protobufPackage = "configd";

/** SetDeviceInventoryRequest is used by Lira to upload LLDP data to ConfigD. */
export interface SetDeviceInventoryRequest {
  inventories: ChassisInventory[];
}

export interface SetDeviceInventoryResponse {
}

/** SetMgmtConfigRequest is to upload mgmt interface config from lira to ConfigD */
export interface SetManagementConfigRequest {
  mgmtConfig: MgmtConfig[];
}

export interface SetMgmtConfigResponse {
}

/**
 * AgentConfigRequest encapsulates an RSA public key, and a configFp. ConfigFp
 * should be the fingerprint of current config that agent knows of.
 * NOTE:
 *    Caller must provide either FabricId or DeviceId.
 */
export interface AgentConfigRequest {
  /** RSA public key. */
  publicKey: Uint8Array;
  /** The fingerprint of current config. */
  configFp: string;
  /** Optional identifier of fabric. */
  fabricId: string;
  /** The identifier of device. */
  deviceId: string;
  /** Workflow engine version. */
  workflowVersion: string;
}

/**
 * AgentConfigResponse encapsulates an encrypted AES-256 key, and encrypted config.
 * Encrypted config maybe either FabricConfig or DeviceConfig. On-premises agent must
 * upload an RSA public key during config query, and ConfigD uses the public key
 * to encrypt a AES-256 key, and encrypts FabricConfig/DeviceConfig using AES-256.
 *
 * Agent:
 *    - Uploads RSA public key.
 * ConfigD:
 *    - Generates a symmetric encryption key.
 *    - Encrypts config (FabricConfig, for example) using AES-256.
 *    - Encrypts symmetric key (AES-256 key) using RSA public key.
 */
export interface AgentConfigResponse {
  /** Indicates that there are no changes in config. */
  noUpdate: boolean;
  /** Agent must send a new RSA public key. */
  needKey: boolean;
  /** Symmetric encryption key (AES-256 key). */
  aesKey: Uint8Array;
  /** Encrypted FabricConfig object. */
  config: Uint8Array;
}

/**
 * SetWorkflowFeedbacksRequest encapsulates a set of workflow feedback
 * messages uploaded by Azimuth.
 */
export interface SetWorkflowFeedbacksRequest {
  /** A set of workflow feedbacks. */
  feedbacks: WorkflowFeedback[];
  /** The size of compressed data. */
  size: number;
  /** Serialized and compressed SetWorkflowFeedbacksRequest object. */
  data: Uint8Array;
}

/** SetWorkflowFeedbacksResponse encapsulates response to feedback post. */
export interface SetWorkflowFeedbacksResponse {
  /** Continue with the execution. */
  continue: boolean;
}

/** SetAgentPlatformInfoRequest encapsulates platform info. */
export interface SetAgentPlatformInfoRequest {
  /** The device identifie. */
  deviceId: string;
  /** Service's version. */
  serviceVersion: string;
  /** Workflow engine version. */
  workflowVersion: string;
  /** SONiC platform version info. */
  platformInfo: string;
  /** SONiC agent version, */
  imageVersion: string;
  /** SONiC agent image SHA256. */
  imageSha256: string;
  /** H/W platform architecture. */
  arch: string;
}

export interface SetAgentPlatformInfoResponse {
}

/** GetImagePackageInfoRequest encapsulates request to query agent image. */
export interface GetImagePackageInfoRequest {
  /** The device identifier. */
  deviceId: string;
  /** H/W architecture (amd64 or arm64). */
  arch: string;
  /** Include image package data in the result. */
  needData: boolean;
  /** Offset of image package data. */
  offset: number;
  /** Package name (AGENTS, SWSS etc.) */
  name: string;
}

export interface GetImagePackageInfoResponse {
  /** Well-known image name (E.g. AGENTS). */
  name: string;
  /** Full image download URL (maybe a signed S3 URL). */
  url: string;
  /** The SHA-256 value of the image package. */
  sha256: string;
  /** Image H/W architecture. */
  arch: string;
  /** Image version string. */
  version: string;
  /** Image timestamp. */
  fileTime:
    | Date
    | undefined;
  /** Image check interval. */
  checkInterval: string;
  /** Download directly from Web. */
  directWeb: boolean;
  /** Package binary data. */
  imageData: Uint8Array;
  /** Final chunk. */
  final: boolean;
}

function createBaseSetDeviceInventoryRequest(): SetDeviceInventoryRequest {
  return { inventories: [] };
}

export const SetDeviceInventoryRequest = {
  encode(message: SetDeviceInventoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.inventories) {
      ChassisInventory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDeviceInventoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeviceInventoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inventories.push(ChassisInventory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDeviceInventoryRequest {
    return {
      inventories: globalThis.Array.isArray(object?.inventories)
        ? object.inventories.map((e: any) => ChassisInventory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetDeviceInventoryRequest): unknown {
    const obj: any = {};
    if (message.inventories?.length) {
      obj.inventories = message.inventories.map((e) => ChassisInventory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeviceInventoryRequest>, I>>(base?: I): SetDeviceInventoryRequest {
    return SetDeviceInventoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeviceInventoryRequest>, I>>(object: I): SetDeviceInventoryRequest {
    const message = createBaseSetDeviceInventoryRequest();
    message.inventories = object.inventories?.map((e) => ChassisInventory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetDeviceInventoryResponse(): SetDeviceInventoryResponse {
  return {};
}

export const SetDeviceInventoryResponse = {
  encode(_: SetDeviceInventoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDeviceInventoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeviceInventoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetDeviceInventoryResponse {
    return {};
  },

  toJSON(_: SetDeviceInventoryResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeviceInventoryResponse>, I>>(base?: I): SetDeviceInventoryResponse {
    return SetDeviceInventoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeviceInventoryResponse>, I>>(_: I): SetDeviceInventoryResponse {
    const message = createBaseSetDeviceInventoryResponse();
    return message;
  },
};

function createBaseSetManagementConfigRequest(): SetManagementConfigRequest {
  return { mgmtConfig: [] };
}

export const SetManagementConfigRequest = {
  encode(message: SetManagementConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mgmtConfig) {
      MgmtConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetManagementConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetManagementConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mgmtConfig.push(MgmtConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetManagementConfigRequest {
    return {
      mgmtConfig: globalThis.Array.isArray(object?.mgmtConfig)
        ? object.mgmtConfig.map((e: any) => MgmtConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetManagementConfigRequest): unknown {
    const obj: any = {};
    if (message.mgmtConfig?.length) {
      obj.mgmtConfig = message.mgmtConfig.map((e) => MgmtConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetManagementConfigRequest>, I>>(base?: I): SetManagementConfigRequest {
    return SetManagementConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetManagementConfigRequest>, I>>(object: I): SetManagementConfigRequest {
    const message = createBaseSetManagementConfigRequest();
    message.mgmtConfig = object.mgmtConfig?.map((e) => MgmtConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetMgmtConfigResponse(): SetMgmtConfigResponse {
  return {};
}

export const SetMgmtConfigResponse = {
  encode(_: SetMgmtConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetMgmtConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetMgmtConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetMgmtConfigResponse {
    return {};
  },

  toJSON(_: SetMgmtConfigResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetMgmtConfigResponse>, I>>(base?: I): SetMgmtConfigResponse {
    return SetMgmtConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetMgmtConfigResponse>, I>>(_: I): SetMgmtConfigResponse {
    const message = createBaseSetMgmtConfigResponse();
    return message;
  },
};

function createBaseAgentConfigRequest(): AgentConfigRequest {
  return { publicKey: new Uint8Array(0), configFp: "", fabricId: "", deviceId: "", workflowVersion: "" };
}

export const AgentConfigRequest = {
  encode(message: AgentConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publicKey.length !== 0) {
      writer.uint32(10).bytes(message.publicKey);
    }
    if (message.configFp !== "") {
      writer.uint32(18).string(message.configFp);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    if (message.workflowVersion !== "") {
      writer.uint32(42).string(message.workflowVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publicKey = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.workflowVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentConfigRequest {
    return {
      publicKey: isSet(object.publicKey) ? bytesFromBase64(object.publicKey) : new Uint8Array(0),
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      workflowVersion: isSet(object.workflowVersion) ? globalThis.String(object.workflowVersion) : "",
    };
  },

  toJSON(message: AgentConfigRequest): unknown {
    const obj: any = {};
    if (message.publicKey.length !== 0) {
      obj.publicKey = base64FromBytes(message.publicKey);
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.workflowVersion !== "") {
      obj.workflowVersion = message.workflowVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentConfigRequest>, I>>(base?: I): AgentConfigRequest {
    return AgentConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentConfigRequest>, I>>(object: I): AgentConfigRequest {
    const message = createBaseAgentConfigRequest();
    message.publicKey = object.publicKey ?? new Uint8Array(0);
    message.configFp = object.configFp ?? "";
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.workflowVersion = object.workflowVersion ?? "";
    return message;
  },
};

function createBaseAgentConfigResponse(): AgentConfigResponse {
  return { noUpdate: false, needKey: false, aesKey: new Uint8Array(0), config: new Uint8Array(0) };
}

export const AgentConfigResponse = {
  encode(message: AgentConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.noUpdate !== false) {
      writer.uint32(8).bool(message.noUpdate);
    }
    if (message.needKey !== false) {
      writer.uint32(16).bool(message.needKey);
    }
    if (message.aesKey.length !== 0) {
      writer.uint32(42).bytes(message.aesKey);
    }
    if (message.config.length !== 0) {
      writer.uint32(50).bytes(message.config);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.noUpdate = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.needKey = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.aesKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentConfigResponse {
    return {
      noUpdate: isSet(object.noUpdate) ? globalThis.Boolean(object.noUpdate) : false,
      needKey: isSet(object.needKey) ? globalThis.Boolean(object.needKey) : false,
      aesKey: isSet(object.aesKey) ? bytesFromBase64(object.aesKey) : new Uint8Array(0),
      config: isSet(object.config) ? bytesFromBase64(object.config) : new Uint8Array(0),
    };
  },

  toJSON(message: AgentConfigResponse): unknown {
    const obj: any = {};
    if (message.noUpdate !== false) {
      obj.noUpdate = message.noUpdate;
    }
    if (message.needKey !== false) {
      obj.needKey = message.needKey;
    }
    if (message.aesKey.length !== 0) {
      obj.aesKey = base64FromBytes(message.aesKey);
    }
    if (message.config.length !== 0) {
      obj.config = base64FromBytes(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentConfigResponse>, I>>(base?: I): AgentConfigResponse {
    return AgentConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentConfigResponse>, I>>(object: I): AgentConfigResponse {
    const message = createBaseAgentConfigResponse();
    message.noUpdate = object.noUpdate ?? false;
    message.needKey = object.needKey ?? false;
    message.aesKey = object.aesKey ?? new Uint8Array(0);
    message.config = object.config ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSetWorkflowFeedbacksRequest(): SetWorkflowFeedbacksRequest {
  return { feedbacks: [], size: 0, data: new Uint8Array(0) };
}

export const SetWorkflowFeedbacksRequest = {
  encode(message: SetWorkflowFeedbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.feedbacks) {
      WorkflowFeedback.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.size !== 0) {
      writer.uint32(16).uint32(message.size);
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetWorkflowFeedbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetWorkflowFeedbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feedbacks.push(WorkflowFeedback.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.size = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetWorkflowFeedbacksRequest {
    return {
      feedbacks: globalThis.Array.isArray(object?.feedbacks)
        ? object.feedbacks.map((e: any) => WorkflowFeedback.fromJSON(e))
        : [],
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
    };
  },

  toJSON(message: SetWorkflowFeedbacksRequest): unknown {
    const obj: any = {};
    if (message.feedbacks?.length) {
      obj.feedbacks = message.feedbacks.map((e) => WorkflowFeedback.toJSON(e));
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    if (message.data.length !== 0) {
      obj.data = base64FromBytes(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetWorkflowFeedbacksRequest>, I>>(base?: I): SetWorkflowFeedbacksRequest {
    return SetWorkflowFeedbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetWorkflowFeedbacksRequest>, I>>(object: I): SetWorkflowFeedbacksRequest {
    const message = createBaseSetWorkflowFeedbacksRequest();
    message.feedbacks = object.feedbacks?.map((e) => WorkflowFeedback.fromPartial(e)) || [];
    message.size = object.size ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSetWorkflowFeedbacksResponse(): SetWorkflowFeedbacksResponse {
  return { continue: false };
}

export const SetWorkflowFeedbacksResponse = {
  encode(message: SetWorkflowFeedbacksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.continue !== false) {
      writer.uint32(8).bool(message.continue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetWorkflowFeedbacksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetWorkflowFeedbacksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.continue = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetWorkflowFeedbacksResponse {
    return { continue: isSet(object.continue) ? globalThis.Boolean(object.continue) : false };
  },

  toJSON(message: SetWorkflowFeedbacksResponse): unknown {
    const obj: any = {};
    if (message.continue !== false) {
      obj.continue = message.continue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetWorkflowFeedbacksResponse>, I>>(base?: I): SetWorkflowFeedbacksResponse {
    return SetWorkflowFeedbacksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetWorkflowFeedbacksResponse>, I>>(object: I): SetWorkflowFeedbacksResponse {
    const message = createBaseSetWorkflowFeedbacksResponse();
    message.continue = object.continue ?? false;
    return message;
  },
};

function createBaseSetAgentPlatformInfoRequest(): SetAgentPlatformInfoRequest {
  return {
    deviceId: "",
    serviceVersion: "",
    workflowVersion: "",
    platformInfo: "",
    imageVersion: "",
    imageSha256: "",
    arch: "",
  };
}

export const SetAgentPlatformInfoRequest = {
  encode(message: SetAgentPlatformInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.serviceVersion !== "") {
      writer.uint32(18).string(message.serviceVersion);
    }
    if (message.workflowVersion !== "") {
      writer.uint32(26).string(message.workflowVersion);
    }
    if (message.platformInfo !== "") {
      writer.uint32(34).string(message.platformInfo);
    }
    if (message.imageVersion !== "") {
      writer.uint32(42).string(message.imageVersion);
    }
    if (message.imageSha256 !== "") {
      writer.uint32(50).string(message.imageSha256);
    }
    if (message.arch !== "") {
      writer.uint32(58).string(message.arch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAgentPlatformInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAgentPlatformInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceVersion = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.workflowVersion = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.platformInfo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.imageSha256 = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.arch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetAgentPlatformInfoRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      serviceVersion: isSet(object.serviceVersion) ? globalThis.String(object.serviceVersion) : "",
      workflowVersion: isSet(object.workflowVersion) ? globalThis.String(object.workflowVersion) : "",
      platformInfo: isSet(object.platformInfo) ? globalThis.String(object.platformInfo) : "",
      imageVersion: isSet(object.imageVersion) ? globalThis.String(object.imageVersion) : "",
      imageSha256: isSet(object.imageSha256) ? globalThis.String(object.imageSha256) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
    };
  },

  toJSON(message: SetAgentPlatformInfoRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.serviceVersion !== "") {
      obj.serviceVersion = message.serviceVersion;
    }
    if (message.workflowVersion !== "") {
      obj.workflowVersion = message.workflowVersion;
    }
    if (message.platformInfo !== "") {
      obj.platformInfo = message.platformInfo;
    }
    if (message.imageVersion !== "") {
      obj.imageVersion = message.imageVersion;
    }
    if (message.imageSha256 !== "") {
      obj.imageSha256 = message.imageSha256;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAgentPlatformInfoRequest>, I>>(base?: I): SetAgentPlatformInfoRequest {
    return SetAgentPlatformInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAgentPlatformInfoRequest>, I>>(object: I): SetAgentPlatformInfoRequest {
    const message = createBaseSetAgentPlatformInfoRequest();
    message.deviceId = object.deviceId ?? "";
    message.serviceVersion = object.serviceVersion ?? "";
    message.workflowVersion = object.workflowVersion ?? "";
    message.platformInfo = object.platformInfo ?? "";
    message.imageVersion = object.imageVersion ?? "";
    message.imageSha256 = object.imageSha256 ?? "";
    message.arch = object.arch ?? "";
    return message;
  },
};

function createBaseSetAgentPlatformInfoResponse(): SetAgentPlatformInfoResponse {
  return {};
}

export const SetAgentPlatformInfoResponse = {
  encode(_: SetAgentPlatformInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAgentPlatformInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAgentPlatformInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetAgentPlatformInfoResponse {
    return {};
  },

  toJSON(_: SetAgentPlatformInfoResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAgentPlatformInfoResponse>, I>>(base?: I): SetAgentPlatformInfoResponse {
    return SetAgentPlatformInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAgentPlatformInfoResponse>, I>>(_: I): SetAgentPlatformInfoResponse {
    const message = createBaseSetAgentPlatformInfoResponse();
    return message;
  },
};

function createBaseGetImagePackageInfoRequest(): GetImagePackageInfoRequest {
  return { deviceId: "", arch: "", needData: false, offset: 0, name: "" };
}

export const GetImagePackageInfoRequest = {
  encode(message: GetImagePackageInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.arch !== "") {
      writer.uint32(18).string(message.arch);
    }
    if (message.needData !== false) {
      writer.uint32(24).bool(message.needData);
    }
    if (message.offset !== 0) {
      writer.uint32(32).uint32(message.offset);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImagePackageInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImagePackageInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.needData = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offset = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImagePackageInfoRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      needData: isSet(object.needData) ? globalThis.Boolean(object.needData) : false,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: GetImagePackageInfoRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.needData !== false) {
      obj.needData = message.needData;
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImagePackageInfoRequest>, I>>(base?: I): GetImagePackageInfoRequest {
    return GetImagePackageInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImagePackageInfoRequest>, I>>(object: I): GetImagePackageInfoRequest {
    const message = createBaseGetImagePackageInfoRequest();
    message.deviceId = object.deviceId ?? "";
    message.arch = object.arch ?? "";
    message.needData = object.needData ?? false;
    message.offset = object.offset ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetImagePackageInfoResponse(): GetImagePackageInfoResponse {
  return {
    name: "",
    url: "",
    sha256: "",
    arch: "",
    version: "",
    fileTime: undefined,
    checkInterval: "",
    directWeb: false,
    imageData: new Uint8Array(0),
    final: false,
  };
}

export const GetImagePackageInfoResponse = {
  encode(message: GetImagePackageInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    if (message.sha256 !== "") {
      writer.uint32(26).string(message.sha256);
    }
    if (message.arch !== "") {
      writer.uint32(34).string(message.arch);
    }
    if (message.version !== "") {
      writer.uint32(42).string(message.version);
    }
    if (message.fileTime !== undefined) {
      Timestamp.encode(toTimestamp(message.fileTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.checkInterval !== "") {
      writer.uint32(58).string(message.checkInterval);
    }
    if (message.directWeb !== false) {
      writer.uint32(64).bool(message.directWeb);
    }
    if (message.imageData.length !== 0) {
      writer.uint32(74).bytes(message.imageData);
    }
    if (message.final !== false) {
      writer.uint32(80).bool(message.final);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImagePackageInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImagePackageInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sha256 = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.version = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fileTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.checkInterval = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.directWeb = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.imageData = reader.bytes();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.final = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImagePackageInfoResponse {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      sha256: isSet(object.sha256) ? globalThis.String(object.sha256) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      fileTime: isSet(object.fileTime) ? fromJsonTimestamp(object.fileTime) : undefined,
      checkInterval: isSet(object.checkInterval) ? globalThis.String(object.checkInterval) : "",
      directWeb: isSet(object.directWeb) ? globalThis.Boolean(object.directWeb) : false,
      imageData: isSet(object.imageData) ? bytesFromBase64(object.imageData) : new Uint8Array(0),
      final: isSet(object.final) ? globalThis.Boolean(object.final) : false,
    };
  },

  toJSON(message: GetImagePackageInfoResponse): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.sha256 !== "") {
      obj.sha256 = message.sha256;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.fileTime !== undefined) {
      obj.fileTime = message.fileTime.toISOString();
    }
    if (message.checkInterval !== "") {
      obj.checkInterval = message.checkInterval;
    }
    if (message.directWeb !== false) {
      obj.directWeb = message.directWeb;
    }
    if (message.imageData.length !== 0) {
      obj.imageData = base64FromBytes(message.imageData);
    }
    if (message.final !== false) {
      obj.final = message.final;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImagePackageInfoResponse>, I>>(base?: I): GetImagePackageInfoResponse {
    return GetImagePackageInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImagePackageInfoResponse>, I>>(object: I): GetImagePackageInfoResponse {
    const message = createBaseGetImagePackageInfoResponse();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    message.sha256 = object.sha256 ?? "";
    message.arch = object.arch ?? "";
    message.version = object.version ?? "";
    message.fileTime = object.fileTime ?? undefined;
    message.checkInterval = object.checkInterval ?? "";
    message.directWeb = object.directWeb ?? false;
    message.imageData = object.imageData ?? new Uint8Array(0);
    message.final = object.final ?? false;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
