import { Container } from "@magnetic/container";
import { Flex } from "@magnetic/flex";
import { Icon, Nav, NavIconsKind } from "@magnetic/nav";
import { useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";
import { OrgsSwitcherDrawer, OrgSwitcher } from "./orgSwitcher";
import { UserRole } from "../gen/schema/models/auth";
import { LoginContext } from "./loginContext";

const navLabels = [
  // "Overview",
  "Fabrics",
  "Switches",
  "Inventory",
  "Connectivity",
  "Insights",
  "Devices",
  "Pluggables",
  "Fans",
  "Switch MAC addresses",
  "Cloud connectivity",
  "Recent changes",
  "Reports",
  "Administration",
  "Power supply units",
  "Organization management",
  "API tokens",
  "ChatBot settings",
  "Audit logs"
] as const;

export const navUrls = [
  // "/overview",
  "/fabrics",
  "/switches",
  "/inventory",
  "/connectivity",
  "/insights",
  "/devices",
  "/pluggables",
  "/fans",
  "/switch-mac-addresses",
  "/cloud-connectivity",
  "/recent-changes",
  "/reports",
  "/administration",
  "/power-supply-units",
  "/user-management",
  "/api-tokens",
  "/chatbot-settings",
  "/audit-logs"
] as const;

type LabelType = (typeof navLabels)[number] | "Developer" | "User management";
type URLType = (typeof navUrls)[number] | "/dev/echo";

type SubMenuItem = {
  label: LabelType;
  to: URLType;
};

type NavConfigItem = {
  label?: LabelType;
  kind?: NavIconsKind;
  subMenu?: SubMenuItem[];
  to?: URLType;
  role?: UserRole;
  dividerKey?: string;
};

type NavSubMenuConfigItem = {
  label: LabelType;
  kind?: NavIconsKind;
  subMenu: SubMenuItem[];
  to: URLType;
  dividerKey?: string;
};

type NavConfigType = (NavConfigItem | NavSubMenuConfigItem)[];

const navConfig: NavConfigType = [
  /*{
    label: "Overview",
    to: "/overview"
  },*/
  {
    label: "Fabrics",
    to: "/fabrics"
  },
  // {
  //   label: "Inventory",
  //   subMenu: [
  //     {
  //       label: "Devices",
  //       to: "/devices"
  //     },
  //     {
  //       label: "Pluggables",
  //       to: "/pluggables"
  //     },
  //     {
  //       label: "Fans",
  //       to: "/fans"
  //     },
  //     {
  //       label: "Power supply units",
  //       to: "/power-supply-units"
  //     }
  //   ]
  // },
  // {
  //   label: "Connectivity",
  //   kind: "connect",
  //   subMenu: [
  //     {
  //       label: "Switch MAC addresses",
  //       to: "/switch-mac-addresses"
  //     },
  //     {
  //       label: "Cloud connectivity",
  //       to: "/cloud-connectivity"
  //     }
  //   ]
  // },
  // {
  //   label: "Insights",
  //   kind: "analytics",
  //   subMenu: [
  //     {
  //       label: "Recent changes",
  //       to: "/recent-changes"
  //     },
  //     {
  //       label: "Reports",
  //       to: "/reports"
  //     }
  //   ]
  // },
  {
    dividerKey: "div1"
  },
  {
    label: "Administration",
    kind: "admin",
    role: UserRole.ADMIN,
    subMenu: [
      {
        label: "User management",
        to: "/user-management"
      }
      // {
      //   label: "API tokens",
      //   to: "/api-tokens"
      // },
      // {
      //   label: "ChatBot settings",
      //   to: "/chatbot-settings"
      // },
      // {
      //   label: "Audit logs",
      //   to: "/audit-logs"
      // }
    ]
  }
];

export const Navbar = () => {
  const { pathname } = useLocation();
  const [selected, setSelected] = useState<URLType>(pathname as URLType);
  const { session } = useContext(LoginContext);
  const userRole = session?.user?.role;

  const updatedNavConfig = useMemo(() => {
    const isDevMode = window.cnc.settings.get("dev");
    const cfg = [...navConfig];
    if (isDevMode) {
      cfg.push({
        label: "Developer",
        kind: "settings",
        to: "/dev/echo"
      } as NavConfigItem);
    }
    /**
     * TODO: Add proper Role permissions
     * TORT-1661 [UI]: Block non-admin users from accessing User management page
     *
     * This is another Alpha hack to hide
     * the administration menu item from
     * users that do not have Admin role.
     *
     * !!! This should be considered total throw away code!!!
     */
    return cfg.filter(({ role }: NavConfigItem) => {
      if (role) {
        return role === userRole;
      }
      return true;
    });
  }, [userRole]);

  const nav = updatedNavConfig.map((itemConf) => {
    if (itemConf.dividerKey) {
      return <Nav.Divider key={itemConf.dividerKey} />;
    }

    const { kind, label, subMenu, to } = itemConf as NavSubMenuConfigItem;

    const conf = {
      icon: <Icon kind={kind ?? (label.toLowerCase() as NavIconsKind)} />,
      label: label
    };
    if (subMenu) {
      return (
        <Nav.Item
          {...conf}
          key={label}
          drawerContentId={label.toLowerCase()}
          isSelected={subMenu.some(({ to: url }) => url === selected)}
        />
      );
    }
    return (
      <Link to={to} key={label}>
        <Nav.Item
          {...conf}
          onClick={() => setSelected(to)}
          isSelected={selected === to}
        />
      </Link>
    );
  });

  const getSubNavItems = (subMenu?: SubMenuItem[]) =>
    subMenu?.map(({ label, to }) => (
      <Link to={to} key={label}>
        <Nav.Drawer.Item
          onClick={() => setSelected(to)}
          selected={selected === to}
        >
          {label}
        </Nav.Drawer.Item>
      </Link>
    ));

  const subNav = updatedNavConfig
    .filter(({ subMenu }) => subMenu)
    .map((item) => {
      const { label, subMenu } = item as NavSubMenuConfigItem;
      return (
        <Nav.Drawer.Content
          heading={label}
          id={label?.toLowerCase()}
          key={label}
        >
          {getSubNavItems(subMenu)}
        </Nav.Drawer.Content>
      );
    });

  return (
    <Container>
      <Flex>
        <Nav
          className="sticky-nav"
          initialIsCollapsed={true}
          isIconOnly={true}
          key="nav"
          navZIndex={1}
        >
          <Nav.Collapse auto={false} />
          <Nav.Menu>
            <OrgSwitcher />
            {nav}
          </Nav.Menu>
          <Nav.Drawer key="nav-drawer">
            <OrgsSwitcherDrawer key="orgs" />
            {subNav}
          </Nav.Drawer>
        </Nav>
      </Flex>
    </Container>
  );
};
