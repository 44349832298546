import * as React from "react";
import "./pageHeader.scss";
import { BreadbrumbNavigation } from "../common/pageHeader";
import VFlex from "./vFlex";
import HFlex from "./hFlex";
import { Text } from "./text";
import { PageTab, TabRoute } from "./pageTabs";
import { Heading } from "@magnetic/heading";
import { CandidateActions } from "../pages/candidates/candidateActions";

export interface PageHeaderProps {
  readonly title: React.ReactNode;
  readonly titleSummary?: React.ReactNode;
  readonly subTitle?: React.ReactNode;
  readonly breadcrumbs?: [string, string][];
  readonly actions?: React.ReactNode;
  readonly name: string;
  readonly tabs?: TabRoute[];
  readonly summaryHeader?: React.ReactNode; //if it has summary, tab moves further to right
}

export const PageHeader = (props: PageHeaderProps) => {
  const {
    title,
    actions,
    tabs,
    subTitle,
    summaryHeader,
    titleSummary,
    breadcrumbs
  } = props;

  const pageActions = actions ? actions : <CandidateActions />;
  const headerClassNames = actions
    ? ["cnc-page-header"]
    : ["cnc-page-header", "candidate-action-header"];

  return (
    <>
      <VFlex className={headerClassNames.join(" ")}>
        <BreadbrumbNavigation breadcrumbs={breadcrumbs} />
        <HFlex className="header">
          <div className="title-section" data-qa={props.name}>
            <div className="title-with-status">
              <Heading size="h1">{title}</Heading>
              {titleSummary && (
                <Text color="light" size="p4" style={{ paddingLeft: 4 }}>
                  {titleSummary}
                </Text>
              )}
            </div>
          </div>
          {pageActions}
        </HFlex>
      </VFlex>
      <VFlex className="cnc-page-header">
        {subTitle && (
          <div className="sub-title-section">
            <Text color="light" size="p3" weight="regular">
              {subTitle}
            </Text>
          </div>
        )}
        <HFlex>
          {summaryHeader && (
            <VFlex style={{ flex: "0 0 23%", alignSelf: "center" }} gap="md">
              {summaryHeader}
            </VFlex>
          )}
          {tabs && (
            <>
              <VFlex className="page-tabs">
                <PageTab tabs={tabs} />
              </VFlex>
            </>
          )}
        </HFlex>
      </VFlex>
    </>
  );
};
