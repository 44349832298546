import { Tabs } from "@magnetic/tabs";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

export interface TabRoute {
  header: React.ReactNode;
  url: string;
  name: string;
  onNavigate: (name: string) => void;
  disabled?: boolean;
}

interface PageTabProps {
  readonly tabs: TabRoute[];
  readonly kind?: "primary" | "secondary";
}

export const PageTab = (props: PageTabProps) => {
  const { tabs, kind = "primary" } = props;

  /**
   * It seems like we want the component
   * to update the selected tab styling if
   * the location changes outside the component.
   * This might happen if someone manually changes
   * the url. I had to defererence the pathname from
   * the location object or react-hooks/exhaustive-deps
   * rule complained.
   */
  const { pathname } = location;
  useEffect(() => {
    tabs.some(({ url, onNavigate, name }) => {
      if (location.pathname === url) {
        /**
         * Moved into useEffect because calling this
         * while rendering calls a setState on the
         * parent.
         */
        onNavigate(name);
        return true;
      }
      return false;
    });
  }, [tabs, pathname]);

  const tabLinks = useMemo((): React.ReactElement[] => {
    return tabs.map((tab): React.ReactElement => {
      const { name, url, header, disabled, onNavigate } = tab;
      const handleNavigation = (): void => {
        onNavigate(name);
      };

      return (
        <Tabs.Link
          key={url}
          as={Link}
          to={url}
          className="mds-rebuild-tabs-link"
          selected={pathname === url}
          disabled={disabled}
          onClick={handleNavigation}
        >
          {header as string}
        </Tabs.Link>
      );
    });
  }, [tabs, pathname]);

  return <Tabs kind={kind}>{tabLinks}</Tabs>;
};
