import { useEffect, useMemo, useState } from "react";
import HFlex from "../components/hFlex";
import { API_URL } from "../proxy";
import { EchoRequest } from "../gen/schema/common/echo";
import { Api } from "../hooks/useApi";
import { uuid } from "./globals";

// a global navigation button that is displayed if there is a connection issue and links to a url to retrieve the cert
export const MissingCertHackButton = (): React.ReactNode => {
  // this drives the polling. Changing it will initiate another echo as the request changes
  const [pingAt, setPingAt] = useState<Date>(new Date());
  // track the initial request, because we want to hide the button until we have gotten an error or a successful response
  const [isInitialRender, setIsInitialRender] = useState(true);

  // create a memoed request, dependent on the ping date
  const req = useMemo(
    () =>
      EchoRequest.create({
        message: `Reachability Ping - TraceId::` + uuid(),
        requestTs: pingAt
      }),
    [pingAt]
  );

  const { resp, error } = Api.Echo.useFred(req); // unary call, which depends on the request

  // if we have gotten a response/error of any kind, set initial render to false
  useEffect(() => {
    if (resp || error) {
      setIsInitialRender(false);
    }
  }, [resp, error]);

  // if there is no response, we want to keep trying to connect. This is done by updating the pingAt state
  useEffect(() => {
    let pollLoop: string | number | NodeJS.Timeout | undefined;
    if (!resp) {
      // create a polling loop if there is no response, resolve when we have had a response
      pollLoop = setInterval(() => {
        setPingAt(new Date());
      }, 2000);
    }
    return () => clearInterval(pollLoop);
  }, [resp]);

  // once we have had a successful API call, we don't need to show anything anymore
  // while we are doing the initial request we also don't want to show the button, this is to prevent flicker!
  if (resp || isInitialRender) {
    return <></>;
  }

  return (
    <>
      <HFlex>
        <a
          className="mds-button mds-button-kind-primary mds-button-size-md"
          target="_blank"
          href={API_URL}
          rel="noreferrer"
        >
          Click to fetch certificate
        </a>
      </HFlex>
    </>
  );
};
