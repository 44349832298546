import { createContext } from "react";
import { GetUserSessionResponse } from "../gen/schema/brig/bapi";
import { fixContext } from "../core/provider/dataProvider";

/**
 * All the params and callbacks
 * needed to manage login/logout
 * sessions
 */
interface LoginSchema {
  session?: GetUserSessionResponse | undefined;
  logout: (e?: React.MouseEvent<unknown>) => void;
  changePassword?: (cg: () => void) => void;
  getUserSession: () => Promise<boolean>;
  switchTenant: (id?: string) => void;
}

export const LoginContext = createContext<LoginSchema>({
  session: undefined,
  logout: fixContext,
  getUserSession: () => Promise.resolve(true),
  switchTenant: fixContext
});
