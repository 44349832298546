export abstract class BaseDataSourceApi<TDataSource> {
  protected source: TDataSource | undefined;
  /* Used to force the subclass to set the source before 1st use. */
  abstract initDataSource(): TDataSource;

  getDataSource(): TDataSource {
    /**
     * If the souce has not been
     * set, we need to initialize
     * it before 1st use.
     */
    if (!this.source) {
      this.source = this.initDataSource();
    }
    return this.source as TDataSource;
  }
}
