// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/tenancy.proto

/* eslint-disable */

export const protobufPackage = "models";

/** CredentialsType defines an enumeration for various authentication credentials. */
export enum CredentialsType {
  /** CREDENTIALS_UNSPECIFIED - Unknown credential type */
  CREDENTIALS_UNSPECIFIED = 0,
  /** PASSWORD - Username/Password based credential */
  PASSWORD = 1,
  /** PUBLIC_KEY - SSH private/public key authentication */
  PUBLIC_KEY = 2,
  UNRECOGNIZED = -1,
}

export function credentialsTypeFromJSON(object: any): CredentialsType {
  switch (object) {
    case 0:
    case "CREDENTIALS_UNSPECIFIED":
      return CredentialsType.CREDENTIALS_UNSPECIFIED;
    case 1:
    case "PASSWORD":
      return CredentialsType.PASSWORD;
    case 2:
    case "PUBLIC_KEY":
      return CredentialsType.PUBLIC_KEY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CredentialsType.UNRECOGNIZED;
  }
}

export function credentialsTypeToJSON(object: CredentialsType): string {
  switch (object) {
    case CredentialsType.CREDENTIALS_UNSPECIFIED:
      return "CREDENTIALS_UNSPECIFIED";
    case CredentialsType.PASSWORD:
      return "PASSWORD";
    case CredentialsType.PUBLIC_KEY:
      return "PUBLIC_KEY";
    case CredentialsType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OrgType defines an enumeration for various tenancies. */
export enum OrgType {
  ORG_TYPE_UNSPECIFIED = 0,
  SERVICE_PROVIDER = 1,
  ENTERPRISE = 2,
  SMALL_MEDIUM_BUSINESS = 3,
  CLOUD_CONSUMER = 4,
  UNRECOGNIZED = -1,
}

export function orgTypeFromJSON(object: any): OrgType {
  switch (object) {
    case 0:
    case "ORG_TYPE_UNSPECIFIED":
      return OrgType.ORG_TYPE_UNSPECIFIED;
    case 1:
    case "SERVICE_PROVIDER":
      return OrgType.SERVICE_PROVIDER;
    case 2:
    case "ENTERPRISE":
      return OrgType.ENTERPRISE;
    case 3:
    case "SMALL_MEDIUM_BUSINESS":
      return OrgType.SMALL_MEDIUM_BUSINESS;
    case 4:
    case "CLOUD_CONSUMER":
      return OrgType.CLOUD_CONSUMER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgType.UNRECOGNIZED;
  }
}

export function orgTypeToJSON(object: OrgType): string {
  switch (object) {
    case OrgType.ORG_TYPE_UNSPECIFIED:
      return "ORG_TYPE_UNSPECIFIED";
    case OrgType.SERVICE_PROVIDER:
      return "SERVICE_PROVIDER";
    case OrgType.ENTERPRISE:
      return "ENTERPRISE";
    case OrgType.SMALL_MEDIUM_BUSINESS:
      return "SMALL_MEDIUM_BUSINESS";
    case OrgType.CLOUD_CONSUMER:
      return "CLOUD_CONSUMER";
    case OrgType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
