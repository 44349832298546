import { SyncFollowStreamManager } from "./syncFollowStreamManager";

export class SextantStreamManager extends SyncFollowStreamManager {
  init(fabricId: string | undefined) {
    if (fabricId) {
      // the fabric id is required for the Sextant service to route sockets
      super.init(`wss://${location.host}/ws?svc=sextant&fabricId=${fabricId}`);
    }
  }
}
