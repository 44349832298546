import { ApiError } from "../../core/apiError";
import { logger } from "../../core/globals";
import {
  DeleteUsersRequest,
  GetUserSessionRequest,
  GetUserSessionResponse,
  GetUsersRequest,
  NewOrgRequest,
  NewOrgResponse,
  RefreshUserSessionRequest,
  SetUserPasswordRequest,
  SetUserPasswordResponse,
  SetUsersRequest,
  StartUserSessionResponse,
  UsersResponse
} from "../../gen/schema/brig/bapi";
import {
  BearerToken,
  BearerTokensResponse,
  CreateBearerTokenRequest,
  DeleteBearerTokenRequest,
  GetBearerTokensRequest
} from "../../gen/schema/brig/btokens";
import { BrigClientImpl, GrpcWebImpl } from "../../gen/schema/brig/bservice";
import {
  FabricCandidate,
  GetNodesFilters,
  GetNodesPortsRequest,
  GetNodesPortsResponse,
  GetNodesRequest,
  GetNodesResponse,
  GetFabricCandidatesRequest,
  GetFabricCandidatesResponse,
  GetFabricConfigRequest,
  GetFabricConfigResponse,
  GetFabricInventoriesRequest,
  GetFabricInventoriesResponse,
  GetFabricsFilters,
  GetFabricsRequest,
  GetFabricsResponse,
  GetVnisRequest,
  GetVrfsRequest,
  ProvisionRequest,
  ProvisionResponse,
  ReviewFabricCandidateRequest,
  ReviewFabricCandidateResponse,
  TenantsObjectsResponse,
  GetVlansRequest
} from "../../gen/schema/configd/api";
import {
  AddManagementPortsRequest,
  GetManagementPortsRequest,
  ManagementPortsResponse,
  UpdateManagementPortRequest
} from "../../gen/schema/configd/node";
import {
  Node,
  Fabric,
  ManagementPort,
  Vlan,
  Vni,
  Vrf
} from "../../gen/schema/models/models";
import { User } from "../../gen/schema/models/user";
import { Empty } from "../../gen/schema/common/empty";
import { ConfigD } from "./configD";
import { FabricTopology } from "../../gen/schema/models/fabric";

export const KEY_NAME = "running-mode";

export enum CandidateMode {
  Edit = "",
  Running = "running"
}

const rpc = new GrpcWebImpl("", {
  debug: false // change later
});

const brig = new BrigClientImpl(rpc);

const request = (
  url: string,
  method: string,
  body?: string,
  options?: RequestInit | undefined
): Promise<Response> => {
  const opts: RequestInit | undefined = {
    method: method,
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body,
    ...options
  };
  return fetch(url, opts);
};

export const Requests = {
  delete: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "DELETE", body, options);
  },
  get: (url: string, options?: RequestInit | undefined): Promise<Response> => {
    return request(url, "GET", undefined, options);
  },
  post: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "POST", body, options);
  },
  put: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    /**
     * Overriding the mode with "same-origin" in the fetch
     * config since no-cors does not support PUT!
     *
     * `{ ...options, mode: "same-origin" }`
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
     * - no-cors
     *
     * Prevents the method from being anything other
     * than HEAD, GET or POST, and the headers from
     * being anything other than CORS-safelisted request
     * headers. If any ServiceWorkers intercept these requests,
     * they may not add or override any headers except for
     * those that are CORS-safelisted request headers.
     * In addition, JavaScript may not access any properties
     * of the resulting Response.
     * This ensures that ServiceWorkers do not affect the semantics
     * of the Web and prevents security and privacy issues
     * arising from leaking data across domains.
     */
    return request(url, "PUT", body, { ...options, mode: "same-origin" });
  }
};

/**
 * Standard Vanilla get<Type>(id) returning a
 * single item in most cases.
 */
export const Apis = {
  ConfigD: {
    /**
     *
     * @param fabricIds string[]
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in
     *                                    filter will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   fabricIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   chassisIds?: string[];
     */
    getFabrics: (
      mode: CandidateMode,
      fabricIds: string[],
      filters: Partial<GetFabricsFilters> = {}
    ): Promise<Fabric[]> => {
      const fabricsRequest = GetFabricsRequest.create({
        filters: GetFabricsFilters.create({
          candidate: mode,
          fabricIds: fabricIds,
          ...filters
        })
      });
      return ConfigD.getFabrics(fabricsRequest).then(
        (resp: GetFabricsResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    /**
     *
     * @param fabricIds string
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in filter
     *                                    will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch: boolean;
     *   loadChildren: boolean;
     *   fabricIds: string[];
     *   names: string[];
     *   tags: string[];
     *   chassisIds: string[];
     */
    getFabric: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetFabricsFilters> = { loadChildren: true }
    ): Promise<Fabric | undefined> => {
      return Apis.ConfigD.getFabrics(mode, [fabricId], filters).then(
        (fabrics) => {
          return fabrics?.[0];
        }
      );
    },
    /**
     *
     * @param fabricIds string
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in filter
     *                                    will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch: boolean;
     *   loadChildren: boolean;
     *   fabricIds: string[];
     *   names: string[];
     *   tags: string[];
     *   chassisIds: string[];
     */
    getFabricTransactions: (
      fabricId: string,
      filters: Partial<GetFabricCandidatesRequest> = {
        needInactive: false,
        needReviews: true,
        needEvents: true,
        name: ""
      }
    ): Promise<FabricCandidate[] | undefined> => {
      const req = GetFabricCandidatesRequest.create({
        fabricId: fabricId,
        ...filters
      });
      return ConfigD.getFabricTransactions(req).then(
        (resp: GetFabricCandidatesResponse) => {
          return resp.candidates;
        }
      );
    },
    /**
     *
     * @param fabricIds string
     */
    getFabricConfig: (
      mode: CandidateMode,
      fabricId: string
    ): Promise<Fabric | undefined> => {
      const req = GetFabricConfigRequest.create({
        candidate: mode,
        fabricId: fabricId
      });
      return ConfigD.getFabricConfig(req).then(
        (resp: GetFabricConfigResponse) => {
          return resp?.config?.fabric;
        }
      );
    },
    /**
     * @param switchIds string[] - empty array is also acceptable
     * @param filters - GetChassisFilters - Partial set of filters with switchId would override the switchIds param
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   chassisIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   roles?: ChassisRole[];
     *   fabricIds?: string[];
     *   serialNumbers?: string[];
     *   modelNames?: string[];
     *   switchIds?: string[];
     *   fabricNames?: string[];
     */
    getSwitches: (
      mode: CandidateMode,
      fabricId: string,
      switchIds: string[] = [],
      filters: Partial<GetNodesFilters> = {}
    ): Promise<Node[] | undefined> => {
      const req = GetNodesRequest.create({
        filters: GetNodesFilters.create({
          candidate: mode,
          fabricId,
          nodeIds: switchIds,
          ...filters
        })
      });
      return ConfigD.getSwitch(req).then((resp: GetNodesResponse) => {
        return resp.nodes;
      });
    },
    /**
     * @param switchIds string - empty is also acceptable
     * @param filters - Partial<GetChassisFilters> - filters with switchId would override the switchIds param
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   chassisIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   roles?: ChassisRole[];
     *   fabricIds?: string[];
     *   serialNumbers?: string[];
     *   modelNames?: string[];
     *   switchIds?: string[];
     *   fabricNames?: string[];
     */
    getSwitch: (
      mode: CandidateMode,
      fabricId: string,
      switchId: string,
      filters: Partial<GetNodesFilters> = { loadChildren: true }
    ): Promise<Node | undefined> => {
      return Apis.ConfigD.getSwitches(mode, fabricId, [switchId], filters).then(
        (chassis) => {
          return chassis?.[0];
        }
      );
    },
    getFabricInventory: (
      fabricId?: string,
      topology?: FabricTopology,
      nodeIds?: string[]
    ): Promise<Fabric[] | undefined> => {
      const req = GetFabricInventoriesRequest.create({
        fabricId,
        topology,
        nodeIds
      });
      return ConfigD.getFabricInventory(req).then(
        (resp: GetFabricInventoriesResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    getAllFabricInventory: (): Promise<Fabric[] | undefined> => {
      const req = GetFabricInventoriesRequest.create();
      return ConfigD.getFabricInventory(req).then(
        (resp: GetFabricInventoriesResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    addManagementPort: (
      req: Partial<AddManagementPortsRequest>
    ): Promise<ManagementPortsResponse> => {
      return ConfigD.addManagementPort(req);
    },
    updateManagementPort: (
      req: Partial<UpdateManagementPortRequest>
    ): Promise<ManagementPort> => {
      return ConfigD.updateManagementPort(req);
    },
    getVLANs: (
      mode: CandidateMode,
      fabricId: string,
      vlanIds: number[] = []
    ): Promise<Vlan[]> => {
      const req = GetVlansRequest.create({
        candidate: mode,
        fabricId: fabricId,
        vlanIds
      });
      return ConfigD.getVlans(req).then((resp: TenantsObjectsResponse) => {
        return resp?.tenants?.[0]?.vlans ?? [];
      });
    },
    getSwitchPorts: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetNodesPortsRequest>
    ): Promise<Node | undefined> => {
      const req = GetNodesPortsRequest.create({
        candidate: mode,
        fabricId,
        ...filters
      });
      return ConfigD.getSwitchPorts(req).then((resp: GetNodesPortsResponse) => {
        return resp.nodes[0];
      });
    },
    getVnis: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetVnisRequest> = {}
    ): Promise<Vni[]> => {
      const req = GetVnisRequest.create({
        fabricId: fabricId,
        candidate: mode,
        ...filters
      });
      return ConfigD.getVnis(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].vnis;
        }
        return [];
      });
    },
    getVRFs: (
      mode: CandidateMode,
      fabricId?: string,
      filters: Partial<GetVrfsRequest> = {}
    ): Promise<Vrf[]> => {
      const req = GetVrfsRequest.create({
        candidate: mode,
        fabricId: fabricId,
        ...filters
      });
      return ConfigD.getVRFs(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].vrfs;
        }
        return [];
      });
    },
    reviewFabricTransaction: (
      fabricId: string,
      comments: string
    ): Promise<boolean> => {
      const req = ReviewFabricCandidateRequest.create({
        fabricId,
        comments
      });
      return ConfigD.reviewFabricTransaction(req).then(
        (resp: ReviewFabricCandidateResponse) => {
          return resp !== undefined;
        }
      );
    },
    getManagementPort: (
      fabricId: string,
      nodeId: string
    ): Promise<ManagementPort[]> => {
      const req = GetManagementPortsRequest.create({
        fabricId,
        nodeId
      });
      return ConfigD.getManagementPort(req).then(
        (resp: ManagementPortsResponse) => {
          return resp.ports;
        }
      );
    },
    provision: (
      req: ProvisionRequest,
      callback: ((response: ProvisionResponse) => void) | undefined,
      errorCallback: (err: ApiError) => void
    ): Promise<ProvisionResponse | ApiError> => {
      return ConfigD.provision(req)
        .then((resp) => {
          if (callback) {
            callback(resp);
          }
          return resp;
        })
        .catch((err) => {
          const error = new ApiError(err);
          logger.error(err);
          if (errorCallback) {
            logger.error(error.getDetails());
            errorCallback(error);
          }
          return error;
        });
    },
    commitFabricCandidate: (
      fabricId?: string,
      name?: string,
      comments?: string
    ): Promise<boolean> => {
      return ConfigD.commitFabricCandidate({ fabricId, name, comments })
        .then(() => {
          return true;
        })
        .catch((e) => {
          logger.error(e);
          return false;
        });
    },
    revertFabricCandidate: (
      fabricId?: string,
      name?: string,
      comments?: string
    ): Promise<boolean> => {
      return ConfigD.revertFabricCandidate({ fabricId, name, comments })
        .then(() => {
          return true;
        })
        .catch((e) => {
          logger.error(e);
          return false;
        });
    }
  },
  Brig: {
    /**
     */
    setUserPassword: (
      email: string,
      newPassword: string
    ): Promise<SetUserPasswordResponse> => {
      return brig.SetUserPassword(
        SetUserPasswordRequest.create({
          email,
          newPassword
        })
      );
    },
    getUserSession: (): Promise<GetUserSessionResponse> => {
      return brig.GetUserSession(GetUserSessionRequest.create());
    },
    getUsers: (): Promise<UsersResponse> => {
      return brig.GetUsers(GetUsersRequest.create());
    },
    setUsers: (users: User[]): Promise<UsersResponse> => {
      return brig.SetUsers(
        SetUsersRequest.fromPartial({
          users
        })
      );
    },
    newOrg: (req: NewOrgRequest): Promise<NewOrgResponse> => {
      return brig.NewOrg(req);
    },
    deleteUsers: (users: string[]): Promise<UsersResponse> => {
      return brig.DeleteUsers(
        DeleteUsersRequest.fromPartial({
          ids: users
        })
      );
    },
    refreshUserSession: (): Promise<StartUserSessionResponse> => {
      return brig.RefreshUserSession(RefreshUserSessionRequest.create());
    },
    getBearerTokens: (): Promise<BearerTokensResponse> => {
      return brig.GetBearerTokens(GetBearerTokensRequest.create());
    },
    createBearerToken: (
      req: CreateBearerTokenRequest
    ): Promise<BearerToken> => {
      return brig.CreateBearerToken(req);
    },
    deleteBearerToken: (tokenId: string): Promise<Empty> => {
      return brig.DeleteBearerToken(
        DeleteBearerTokenRequest.fromPartial({
          tokenId: tokenId
        })
      );
    }
  },
  /**
   * Some non-grpc api calls Paul added
   * for Auth/org stuff
   */
  Auth: {
    pwreset: (payload: string[]): Promise<Response> => {
      const body: string = payload.join("&");
      return Requests.put("/ui/v1/auth/pwreset", body);
    },
    local: (payload: string[]): Promise<Response> => {
      const body: string = payload.join("&");
      return Requests.post("/ui/v1/auth/local", body);
    },
    login: (payload: string[]): Promise<Response> => {
      const body: string = payload.join("&");
      return Requests.post("/ui/v1/auth/login/okta", body);
    },
    /**
     * @deprecated
     */
    logout: (): Promise<boolean> => {
      return Requests.post("/ui/v1/auth/logout")
        .then(
          () => {
            window.location.reload();
            return true;
          },
          (err: ApiError) => {
            logger.error(err);
            return false;
          }
        )
        .catch(() => false);
    },
    /**
     * @param id - tenant id as a string
     */
    switchTenant: (id: string): Promise<boolean> => {
      return Requests.put(`/ui/v1/auth/sessions/${id}`).then(
        () => {
          window.location.reload();
          return true;
        },
        (err: ApiError) => {
          logger.error(err);
          return false;
        }
      );
    },
    /**
     * @param expiry This is a Devmode only param - used for testing
     */
    refresh: (expiry?: number): Promise<Response> => {
      let args: string = "";
      if (expiry) {
        args = `expiry=now+${expiry}s`;
      }
      return Requests.post("/ui/v1/auth/refresh", args)
        .then(
          (response: Response) => response,
          (err: Response) => err
        )
        .catch((err: Response) => err);
    }
  }
};
