import React from "react";

import { Flex, FlexProps } from "@magnetic/flex";

export default function HFlex(props: FlexProps) {
  return (
    <Flex gap={props.gap || "md"} direction="horizontal" {...props}>
      {props.children}
    </Flex>
  );
}
