import { PurchaseMethod } from "../gen/schema/models/auth";

export type PurchaseOption = {
  label: string;
  value: string;
};

const purchaseMethods: PurchaseOption[] = [
  {
    label: "Directly",
    value: PurchaseMethod.DIRECTLY.toString()
  },
  {
    label: "Via partner",
    value: PurchaseMethod.PARTNER.toString()
  },
  {
    label: "Via distributor",
    value: PurchaseMethod.DISTRIBUTOR.toString()
  },
  {
    label: "Not sure",
    value: PurchaseMethod.NOT_SURE.toString()
  }
];

export default purchaseMethods;
