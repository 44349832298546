// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: syncfollow/syncfollow.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { EchoRequest, EchoResponse } from "../common/echo";
import { SchemaType, schemaTypeFromJSON, schemaTypeToJSON } from "../schema_path/schema_path";
import { DataSeries } from "./syncfollow_threadlist";

export const protobufPackage = "syncfollow";

export enum ClientType {
  CLIENT_UNSPECIFIED = 0,
  LIRA = 1,
  FRED = 2,
  RUDDER = 3,
  AZIMUTH = 4,
  SEXTANT = 5,
  UNRECOGNIZED = -1,
}

export function clientTypeFromJSON(object: any): ClientType {
  switch (object) {
    case 0:
    case "CLIENT_UNSPECIFIED":
      return ClientType.CLIENT_UNSPECIFIED;
    case 1:
    case "LIRA":
      return ClientType.LIRA;
    case 2:
    case "FRED":
      return ClientType.FRED;
    case 3:
    case "RUDDER":
      return ClientType.RUDDER;
    case 4:
    case "AZIMUTH":
      return ClientType.AZIMUTH;
    case 5:
    case "SEXTANT":
      return ClientType.SEXTANT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClientType.UNRECOGNIZED;
  }
}

export function clientTypeToJSON(object: ClientType): string {
  switch (object) {
    case ClientType.CLIENT_UNSPECIFIED:
      return "CLIENT_UNSPECIFIED";
    case ClientType.LIRA:
      return "LIRA";
    case ClientType.FRED:
      return "FRED";
    case ClientType.RUDDER:
      return "RUDDER";
    case ClientType.AZIMUTH:
      return "AZIMUTH";
    case ClientType.SEXTANT:
      return "SEXTANT";
    case ClientType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ErrorCode {
  ERR_UNSPECIFIED = 0,
  ERR_DESERIALIZATION_ERROR = 1,
  ERR_UNSUPPORTED_TYPE = 2,
  ERR_UNKNOWN_ID = 3,
  ERR_MISSING_ID = 4,
  ERR_SERVER_REJECTED = 5,
  ERR_FORBIDDEN = 6,
  ERR_UNAUTHORIZED = 7,
  ERR_MAX_SYNCS_REACHED = 8,
  UNRECOGNIZED = -1,
}

export function errorCodeFromJSON(object: any): ErrorCode {
  switch (object) {
    case 0:
    case "ERR_UNSPECIFIED":
      return ErrorCode.ERR_UNSPECIFIED;
    case 1:
    case "ERR_DESERIALIZATION_ERROR":
      return ErrorCode.ERR_DESERIALIZATION_ERROR;
    case 2:
    case "ERR_UNSUPPORTED_TYPE":
      return ErrorCode.ERR_UNSUPPORTED_TYPE;
    case 3:
    case "ERR_UNKNOWN_ID":
      return ErrorCode.ERR_UNKNOWN_ID;
    case 4:
    case "ERR_MISSING_ID":
      return ErrorCode.ERR_MISSING_ID;
    case 5:
    case "ERR_SERVER_REJECTED":
      return ErrorCode.ERR_SERVER_REJECTED;
    case 6:
    case "ERR_FORBIDDEN":
      return ErrorCode.ERR_FORBIDDEN;
    case 7:
    case "ERR_UNAUTHORIZED":
      return ErrorCode.ERR_UNAUTHORIZED;
    case 8:
    case "ERR_MAX_SYNCS_REACHED":
      return ErrorCode.ERR_MAX_SYNCS_REACHED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ErrorCode.UNRECOGNIZED;
  }
}

export function errorCodeToJSON(object: ErrorCode): string {
  switch (object) {
    case ErrorCode.ERR_UNSPECIFIED:
      return "ERR_UNSPECIFIED";
    case ErrorCode.ERR_DESERIALIZATION_ERROR:
      return "ERR_DESERIALIZATION_ERROR";
    case ErrorCode.ERR_UNSUPPORTED_TYPE:
      return "ERR_UNSUPPORTED_TYPE";
    case ErrorCode.ERR_UNKNOWN_ID:
      return "ERR_UNKNOWN_ID";
    case ErrorCode.ERR_MISSING_ID:
      return "ERR_MISSING_ID";
    case ErrorCode.ERR_SERVER_REJECTED:
      return "ERR_SERVER_REJECTED";
    case ErrorCode.ERR_FORBIDDEN:
      return "ERR_FORBIDDEN";
    case ErrorCode.ERR_UNAUTHORIZED:
      return "ERR_UNAUTHORIZED";
    case ErrorCode.ERR_MAX_SYNCS_REACHED:
      return "ERR_MAX_SYNCS_REACHED";
    case ErrorCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QueryType {
  Invalid = 0,
  Sync = 1,
  Follow = 2,
  SyncAndFollow = 3,
  Stop = 4,
  UNRECOGNIZED = -1,
}

export function queryTypeFromJSON(object: any): QueryType {
  switch (object) {
    case 0:
    case "Invalid":
      return QueryType.Invalid;
    case 1:
    case "Sync":
      return QueryType.Sync;
    case 2:
    case "Follow":
      return QueryType.Follow;
    case 3:
    case "SyncAndFollow":
      return QueryType.SyncAndFollow;
    case 4:
    case "Stop":
      return QueryType.Stop;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QueryType.UNRECOGNIZED;
  }
}

export function queryTypeToJSON(object: QueryType): string {
  switch (object) {
    case QueryType.Invalid:
      return "Invalid";
    case QueryType.Sync:
      return "Sync";
    case QueryType.Follow:
      return "Follow";
    case QueryType.SyncAndFollow:
      return "SyncAndFollow";
    case QueryType.Stop:
      return "Stop";
    case QueryType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MarkerType {
  None = 0,
  /** StartOfSync - Indicates the start of a sync section */
  StartOfSync = 1,
  /** EndOfSync - Indicates the end of the sync section */
  EndOfSync = 2,
  /** StartOfFollow - Indicates the start of a follow section */
  StartOfFollow = 3,
  /** UpToDate - Indicates the follow has caught up with the tail of the threadlist */
  UpToDate = 4,
  /** ResumeFailed - Indicates that the resume failed, the client should re-request a full sync */
  ResumeFailed = 5,
  UNRECOGNIZED = -1,
}

export function markerTypeFromJSON(object: any): MarkerType {
  switch (object) {
    case 0:
    case "None":
      return MarkerType.None;
    case 1:
    case "StartOfSync":
      return MarkerType.StartOfSync;
    case 2:
    case "EndOfSync":
      return MarkerType.EndOfSync;
    case 3:
    case "StartOfFollow":
      return MarkerType.StartOfFollow;
    case 4:
    case "UpToDate":
      return MarkerType.UpToDate;
    case 5:
    case "ResumeFailed":
      return MarkerType.ResumeFailed;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarkerType.UNRECOGNIZED;
  }
}

export function markerTypeToJSON(object: MarkerType): string {
  switch (object) {
    case MarkerType.None:
      return "None";
    case MarkerType.StartOfSync:
      return "StartOfSync";
    case MarkerType.EndOfSync:
      return "EndOfSync";
    case MarkerType.StartOfFollow:
      return "StartOfFollow";
    case MarkerType.UpToDate:
      return "UpToDate";
    case MarkerType.ResumeFailed:
      return "ResumeFailed";
    case MarkerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Message {
  /**
   * This could use oneof but the code gen does not support it
   * Only set one of these in each message
   */
  request: Request | undefined;
  data: Data | undefined;
  echoRequest: EchoRequest | undefined;
  echoResponse: EchoResponse | undefined;
  error: Error | undefined;
  hello: Hello | undefined;
  options: Options | undefined;
  simpleRequest: SimpleRequest | undefined;
}

export interface Hello {
  clientType: ClientType;
  clientId: string;
}

export interface BoostRequest {
  objectName: string;
  syncId: string;
  boost: boolean;
}

export interface Options {
  boost: BoostRequest[];
}

export interface Error {
  syncId: string;
  errorMessage: string;
  httpStatusCode: number;
  errorCode: ErrorCode;
  /** If fatal_error is set, the client should disconnect and wait at least backoff_time_ms before retrying */
  fatalError: boolean;
  backoffTimeMs: number;
}

export interface MonitoredObjects {
  /** A list of arbitrary object IDs to monitor. */
  objectId: string[];
  /** A list of switch IDs to monitor.  If any switch IDs are not known, the request will fail. */
  switchId: string[];
  /** A list of fabric IDs to monitor. */
  fabricId: string[];
  /** A list of types to monitor.  Every object ID will monitor all types. */
  types: SchemaType[];
  /** If true, object IDs are assumed to be fabric IDs, which will be translated server-side to all switch IDs in each fabric */
  expandFabricIds: boolean;
}

export interface Request {
  /** Optional - can be set if the client wishes to multiplex multiple streaming result sets. Subsequent requests for the same sync_id will replace any previous sync for that ID */
  syncId: string;
  /**
   * The set of switch IDs to monitor
   *
   * @deprecated
   */
  switchId: string[];
  /**
   * The set of fabric IDs to monitor
   *
   * @deprecated
   */
  fabricId: string[];
  /**
   * If set to true, instead of monitoring fabric_id, monitor all switch IDs in those fabrics
   *
   * @deprecated
   */
  expandFabricId: boolean;
  /** @deprecated */
  types: SchemaType[];
  objects: MonitoredObjects[];
  query: QueryType;
  /**
   * Following deprecation this is now always true i.e. all the data for all types is synced before starting any follows
   *
   * @deprecated
   */
  syncAllBeforeFollow: boolean;
  /** Indicates the offset to resume from for each type. If any single resume_offset cannot be applied then the server will respond with a Data message with a marker of ResumeFailed, client is responsible for re-requesting a full sync */
  resumeOffsets: StreamOffset[];
  /** Indicates that the client wants to boost the sampling rate of the stream */
  boost: boolean;
  /** Indicates that the client wants the path_string field populated for telemetry items. */
  includePathString: boolean;
}

/** SimpleRequest is a simplified version of Request that allows only a SyncAndFollow query for a single object_id and type */
export interface SimpleRequest {
  /** Optional - can be set if the client wishes to multiplex multiple streaming result sets. Subsequent requests for the same sync_id will replace any previous sync for that ID */
  syncId: string;
  /** An arbitrary object ID, together with type idenfifies a single threadlist to monitor. */
  objectId: string;
  /** A single type, together with object_id idenfifies a single threadlist to monitor. */
  type: SchemaType;
}

export interface StreamOffset {
  objectId: string;
  type: SchemaType;
  instance: number;
  offset: number;
}

export interface Data {
  /** Refers back to the sync_id that was set in the request */
  syncId: string;
  /** The data points */
  series:
    | DataSeries
    | undefined;
  /** Start/end of sync marker */
  marker: MarkerType;
}

function createBaseMessage(): Message {
  return {
    request: undefined,
    data: undefined,
    echoRequest: undefined,
    echoResponse: undefined,
    error: undefined,
    hello: undefined,
    options: undefined,
    simpleRequest: undefined,
  };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.data !== undefined) {
      Data.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.echoRequest !== undefined) {
      EchoRequest.encode(message.echoRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.echoResponse !== undefined) {
      EchoResponse.encode(message.echoResponse, writer.uint32(34).fork()).ldelim();
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(42).fork()).ldelim();
    }
    if (message.hello !== undefined) {
      Hello.encode(message.hello, writer.uint32(50).fork()).ldelim();
    }
    if (message.options !== undefined) {
      Options.encode(message.options, writer.uint32(58).fork()).ldelim();
    }
    if (message.simpleRequest !== undefined) {
      SimpleRequest.encode(message.simpleRequest, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = Data.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.echoRequest = EchoRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.echoResponse = EchoResponse.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hello = Hello.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.options = Options.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.simpleRequest = SimpleRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? Data.fromJSON(object.data) : undefined,
      echoRequest: isSet(object.echoRequest) ? EchoRequest.fromJSON(object.echoRequest) : undefined,
      echoResponse: isSet(object.echoResponse) ? EchoResponse.fromJSON(object.echoResponse) : undefined,
      error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
      hello: isSet(object.hello) ? Hello.fromJSON(object.hello) : undefined,
      options: isSet(object.options) ? Options.fromJSON(object.options) : undefined,
      simpleRequest: isSet(object.simpleRequest) ? SimpleRequest.fromJSON(object.simpleRequest) : undefined,
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.data !== undefined) {
      obj.data = Data.toJSON(message.data);
    }
    if (message.echoRequest !== undefined) {
      obj.echoRequest = EchoRequest.toJSON(message.echoRequest);
    }
    if (message.echoResponse !== undefined) {
      obj.echoResponse = EchoResponse.toJSON(message.echoResponse);
    }
    if (message.error !== undefined) {
      obj.error = Error.toJSON(message.error);
    }
    if (message.hello !== undefined) {
      obj.hello = Hello.toJSON(message.hello);
    }
    if (message.options !== undefined) {
      obj.options = Options.toJSON(message.options);
    }
    if (message.simpleRequest !== undefined) {
      obj.simpleRequest = SimpleRequest.toJSON(message.simpleRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.data = (object.data !== undefined && object.data !== null) ? Data.fromPartial(object.data) : undefined;
    message.echoRequest = (object.echoRequest !== undefined && object.echoRequest !== null)
      ? EchoRequest.fromPartial(object.echoRequest)
      : undefined;
    message.echoResponse = (object.echoResponse !== undefined && object.echoResponse !== null)
      ? EchoResponse.fromPartial(object.echoResponse)
      : undefined;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.hello = (object.hello !== undefined && object.hello !== null) ? Hello.fromPartial(object.hello) : undefined;
    message.options = (object.options !== undefined && object.options !== null)
      ? Options.fromPartial(object.options)
      : undefined;
    message.simpleRequest = (object.simpleRequest !== undefined && object.simpleRequest !== null)
      ? SimpleRequest.fromPartial(object.simpleRequest)
      : undefined;
    return message;
  },
};

function createBaseHello(): Hello {
  return { clientType: 0, clientId: "" };
}

export const Hello = {
  encode(message: Hello, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientType !== 0) {
      writer.uint32(8).int32(message.clientType);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Hello {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHello();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.clientType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Hello {
    return {
      clientType: isSet(object.clientType) ? clientTypeFromJSON(object.clientType) : 0,
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: Hello): unknown {
    const obj: any = {};
    if (message.clientType !== 0) {
      obj.clientType = clientTypeToJSON(message.clientType);
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Hello>, I>>(base?: I): Hello {
    return Hello.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Hello>, I>>(object: I): Hello {
    const message = createBaseHello();
    message.clientType = object.clientType ?? 0;
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseBoostRequest(): BoostRequest {
  return { objectName: "", syncId: "", boost: false };
}

export const BoostRequest = {
  encode(message: BoostRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectName !== "") {
      writer.uint32(10).string(message.objectName);
    }
    if (message.syncId !== "") {
      writer.uint32(18).string(message.syncId);
    }
    if (message.boost !== false) {
      writer.uint32(24).bool(message.boost);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BoostRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoostRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.boost = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BoostRequest {
    return {
      objectName: isSet(object.objectName) ? globalThis.String(object.objectName) : "",
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      boost: isSet(object.boost) ? globalThis.Boolean(object.boost) : false,
    };
  },

  toJSON(message: BoostRequest): unknown {
    const obj: any = {};
    if (message.objectName !== "") {
      obj.objectName = message.objectName;
    }
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.boost !== false) {
      obj.boost = message.boost;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BoostRequest>, I>>(base?: I): BoostRequest {
    return BoostRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoostRequest>, I>>(object: I): BoostRequest {
    const message = createBaseBoostRequest();
    message.objectName = object.objectName ?? "";
    message.syncId = object.syncId ?? "";
    message.boost = object.boost ?? false;
    return message;
  },
};

function createBaseOptions(): Options {
  return { boost: [] };
}

export const Options = {
  encode(message: Options, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.boost) {
      BoostRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Options {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.boost.push(BoostRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Options {
    return {
      boost: globalThis.Array.isArray(object?.boost) ? object.boost.map((e: any) => BoostRequest.fromJSON(e)) : [],
    };
  },

  toJSON(message: Options): unknown {
    const obj: any = {};
    if (message.boost?.length) {
      obj.boost = message.boost.map((e) => BoostRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Options>, I>>(base?: I): Options {
    return Options.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Options>, I>>(object: I): Options {
    const message = createBaseOptions();
    message.boost = object.boost?.map((e) => BoostRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseError(): Error {
  return { syncId: "", errorMessage: "", httpStatusCode: 0, errorCode: 0, fatalError: false, backoffTimeMs: 0 };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.errorMessage !== "") {
      writer.uint32(18).string(message.errorMessage);
    }
    if (message.httpStatusCode !== 0) {
      writer.uint32(24).uint32(message.httpStatusCode);
    }
    if (message.errorCode !== 0) {
      writer.uint32(32).int32(message.errorCode);
    }
    if (message.fatalError !== false) {
      writer.uint32(40).bool(message.fatalError);
    }
    if (message.backoffTimeMs !== 0) {
      writer.uint32(48).uint32(message.backoffTimeMs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.httpStatusCode = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.errorCode = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fatalError = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.backoffTimeMs = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Error {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      httpStatusCode: isSet(object.httpStatusCode) ? globalThis.Number(object.httpStatusCode) : 0,
      errorCode: isSet(object.errorCode) ? errorCodeFromJSON(object.errorCode) : 0,
      fatalError: isSet(object.fatalError) ? globalThis.Boolean(object.fatalError) : false,
      backoffTimeMs: isSet(object.backoffTimeMs) ? globalThis.Number(object.backoffTimeMs) : 0,
    };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.httpStatusCode !== 0) {
      obj.httpStatusCode = Math.round(message.httpStatusCode);
    }
    if (message.errorCode !== 0) {
      obj.errorCode = errorCodeToJSON(message.errorCode);
    }
    if (message.fatalError !== false) {
      obj.fatalError = message.fatalError;
    }
    if (message.backoffTimeMs !== 0) {
      obj.backoffTimeMs = Math.round(message.backoffTimeMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Error>, I>>(base?: I): Error {
    return Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.syncId = object.syncId ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.httpStatusCode = object.httpStatusCode ?? 0;
    message.errorCode = object.errorCode ?? 0;
    message.fatalError = object.fatalError ?? false;
    message.backoffTimeMs = object.backoffTimeMs ?? 0;
    return message;
  },
};

function createBaseMonitoredObjects(): MonitoredObjects {
  return { objectId: [], switchId: [], fabricId: [], types: [], expandFabricIds: false };
}

export const MonitoredObjects = {
  encode(message: MonitoredObjects, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectId) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.switchId) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.fabricId) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.expandFabricIds !== false) {
      writer.uint32(40).bool(message.expandFabricIds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MonitoredObjects {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMonitoredObjects();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.expandFabricIds = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MonitoredObjects {
    return {
      objectId: globalThis.Array.isArray(object?.objectId) ? object.objectId.map((e: any) => globalThis.String(e)) : [],
      switchId: globalThis.Array.isArray(object?.switchId) ? object.switchId.map((e: any) => globalThis.String(e)) : [],
      fabricId: globalThis.Array.isArray(object?.fabricId) ? object.fabricId.map((e: any) => globalThis.String(e)) : [],
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => schemaTypeFromJSON(e)) : [],
      expandFabricIds: isSet(object.expandFabricIds) ? globalThis.Boolean(object.expandFabricIds) : false,
    };
  },

  toJSON(message: MonitoredObjects): unknown {
    const obj: any = {};
    if (message.objectId?.length) {
      obj.objectId = message.objectId;
    }
    if (message.switchId?.length) {
      obj.switchId = message.switchId;
    }
    if (message.fabricId?.length) {
      obj.fabricId = message.fabricId;
    }
    if (message.types?.length) {
      obj.types = message.types.map((e) => schemaTypeToJSON(e));
    }
    if (message.expandFabricIds !== false) {
      obj.expandFabricIds = message.expandFabricIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MonitoredObjects>, I>>(base?: I): MonitoredObjects {
    return MonitoredObjects.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MonitoredObjects>, I>>(object: I): MonitoredObjects {
    const message = createBaseMonitoredObjects();
    message.objectId = object.objectId?.map((e) => e) || [];
    message.switchId = object.switchId?.map((e) => e) || [];
    message.fabricId = object.fabricId?.map((e) => e) || [];
    message.types = object.types?.map((e) => e) || [];
    message.expandFabricIds = object.expandFabricIds ?? false;
    return message;
  },
};

function createBaseRequest(): Request {
  return {
    syncId: "",
    switchId: [],
    fabricId: [],
    expandFabricId: false,
    types: [],
    objects: [],
    query: 0,
    syncAllBeforeFollow: false,
    resumeOffsets: [],
    boost: false,
    includePathString: false,
  };
}

export const Request = {
  encode(message: Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    for (const v of message.switchId) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.fabricId) {
      writer.uint32(26).string(v!);
    }
    if (message.expandFabricId !== false) {
      writer.uint32(32).bool(message.expandFabricId);
    }
    writer.uint32(42).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.objects) {
      MonitoredObjects.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.query !== 0) {
      writer.uint32(48).int32(message.query);
    }
    if (message.syncAllBeforeFollow !== false) {
      writer.uint32(56).bool(message.syncAllBeforeFollow);
    }
    for (const v of message.resumeOffsets) {
      StreamOffset.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.boost !== false) {
      writer.uint32(72).bool(message.boost);
    }
    if (message.includePathString !== false) {
      writer.uint32(80).bool(message.includePathString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.expandFabricId = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.objects.push(MonitoredObjects.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.query = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.syncAllBeforeFollow = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.resumeOffsets.push(StreamOffset.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.boost = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.includePathString = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Request {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      switchId: globalThis.Array.isArray(object?.switchId) ? object.switchId.map((e: any) => globalThis.String(e)) : [],
      fabricId: globalThis.Array.isArray(object?.fabricId) ? object.fabricId.map((e: any) => globalThis.String(e)) : [],
      expandFabricId: isSet(object.expandFabricId) ? globalThis.Boolean(object.expandFabricId) : false,
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => schemaTypeFromJSON(e)) : [],
      objects: globalThis.Array.isArray(object?.objects)
        ? object.objects.map((e: any) => MonitoredObjects.fromJSON(e))
        : [],
      query: isSet(object.query) ? queryTypeFromJSON(object.query) : 0,
      syncAllBeforeFollow: isSet(object.syncAllBeforeFollow) ? globalThis.Boolean(object.syncAllBeforeFollow) : false,
      resumeOffsets: globalThis.Array.isArray(object?.resumeOffsets)
        ? object.resumeOffsets.map((e: any) => StreamOffset.fromJSON(e))
        : [],
      boost: isSet(object.boost) ? globalThis.Boolean(object.boost) : false,
      includePathString: isSet(object.includePathString) ? globalThis.Boolean(object.includePathString) : false,
    };
  },

  toJSON(message: Request): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.switchId?.length) {
      obj.switchId = message.switchId;
    }
    if (message.fabricId?.length) {
      obj.fabricId = message.fabricId;
    }
    if (message.expandFabricId !== false) {
      obj.expandFabricId = message.expandFabricId;
    }
    if (message.types?.length) {
      obj.types = message.types.map((e) => schemaTypeToJSON(e));
    }
    if (message.objects?.length) {
      obj.objects = message.objects.map((e) => MonitoredObjects.toJSON(e));
    }
    if (message.query !== 0) {
      obj.query = queryTypeToJSON(message.query);
    }
    if (message.syncAllBeforeFollow !== false) {
      obj.syncAllBeforeFollow = message.syncAllBeforeFollow;
    }
    if (message.resumeOffsets?.length) {
      obj.resumeOffsets = message.resumeOffsets.map((e) => StreamOffset.toJSON(e));
    }
    if (message.boost !== false) {
      obj.boost = message.boost;
    }
    if (message.includePathString !== false) {
      obj.includePathString = message.includePathString;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Request>, I>>(base?: I): Request {
    return Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Request>, I>>(object: I): Request {
    const message = createBaseRequest();
    message.syncId = object.syncId ?? "";
    message.switchId = object.switchId?.map((e) => e) || [];
    message.fabricId = object.fabricId?.map((e) => e) || [];
    message.expandFabricId = object.expandFabricId ?? false;
    message.types = object.types?.map((e) => e) || [];
    message.objects = object.objects?.map((e) => MonitoredObjects.fromPartial(e)) || [];
    message.query = object.query ?? 0;
    message.syncAllBeforeFollow = object.syncAllBeforeFollow ?? false;
    message.resumeOffsets = object.resumeOffsets?.map((e) => StreamOffset.fromPartial(e)) || [];
    message.boost = object.boost ?? false;
    message.includePathString = object.includePathString ?? false;
    return message;
  },
};

function createBaseSimpleRequest(): SimpleRequest {
  return { syncId: "", objectId: "", type: 0 };
}

export const SimpleRequest = {
  encode(message: SimpleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.objectId !== "") {
      writer.uint32(18).string(message.objectId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SimpleRequest {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: SimpleRequest): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SimpleRequest>, I>>(base?: I): SimpleRequest {
    return SimpleRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SimpleRequest>, I>>(object: I): SimpleRequest {
    const message = createBaseSimpleRequest();
    message.syncId = object.syncId ?? "";
    message.objectId = object.objectId ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseStreamOffset(): StreamOffset {
  return { objectId: "", type: 0, instance: 0, offset: 0 };
}

export const StreamOffset = {
  encode(message: StreamOffset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectId !== "") {
      writer.uint32(10).string(message.objectId);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.instance !== 0) {
      writer.uint32(24).uint32(message.instance);
    }
    if (message.offset !== 0) {
      writer.uint32(32).uint64(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StreamOffset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamOffset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.instance = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offset = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamOffset {
    return {
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
      instance: isSet(object.instance) ? globalThis.Number(object.instance) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
    };
  },

  toJSON(message: StreamOffset): unknown {
    const obj: any = {};
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    if (message.instance !== 0) {
      obj.instance = Math.round(message.instance);
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamOffset>, I>>(base?: I): StreamOffset {
    return StreamOffset.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamOffset>, I>>(object: I): StreamOffset {
    const message = createBaseStreamOffset();
    message.objectId = object.objectId ?? "";
    message.type = object.type ?? 0;
    message.instance = object.instance ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseData(): Data {
  return { syncId: "", series: undefined, marker: 0 };
}

export const Data = {
  encode(message: Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.series !== undefined) {
      DataSeries.encode(message.series, writer.uint32(18).fork()).ldelim();
    }
    if (message.marker !== 0) {
      writer.uint32(24).int32(message.marker);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.series = DataSeries.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.marker = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Data {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      series: isSet(object.series) ? DataSeries.fromJSON(object.series) : undefined,
      marker: isSet(object.marker) ? markerTypeFromJSON(object.marker) : 0,
    };
  },

  toJSON(message: Data): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.series !== undefined) {
      obj.series = DataSeries.toJSON(message.series);
    }
    if (message.marker !== 0) {
      obj.marker = markerTypeToJSON(message.marker);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Data>, I>>(base?: I): Data {
    return Data.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Data>, I>>(object: I): Data {
    const message = createBaseData();
    message.syncId = object.syncId ?? "";
    message.series = (object.series !== undefined && object.series !== null)
      ? DataSeries.fromPartial(object.series)
      : undefined;
    message.marker = object.marker ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
