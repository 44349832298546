import React, { FunctionComponent } from "react";
import { GlobalFooter } from "./globalFooter";
import "./pageLayout.scss";
export type PageLayoutProps = {
  readonly banner?: React.ReactNode;
  readonly content?: React.ReactNode;
  readonly sideNav?: React.ReactNode;
};

const DefaultSideNav = () => <div>Side Nav</div>;
const DefaultBanner = () => <div>Banner</div>;

export const PageLayout: FunctionComponent<PageLayoutProps> = ({
  banner = <DefaultBanner />,
  sideNav = <DefaultSideNav />,
  content
}) => (
  <div className="cnc-shell">
    <div className="cnc-banner">{banner}</div>
    <div className="cnc-page-layout">
      <div className="cnc-side-nav">{sideNav}</div>
      <div className="cnc-content">{content}</div>
    </div>
    <GlobalFooter />
  </div>
);
