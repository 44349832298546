import { Text } from "@magnetic/text";
import "./globalFooter.scss";

export const GlobalFooter = () => {
  return (
    <footer>
      <cite>© 2024 Cisco Systems, Inc. All rights reserved.</cite>
      <div>
        <Text size="p3">
          <a href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html">
            Terms
          </a>
        </Text>
        <Text size="p3">
          <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html">
            Privacy
          </a>
        </Text>
        <Text size="p3">
          <a href="https://www.cisco.com/c/en/us/about/help.html">Help</a>
        </Text>
        <Text size="p3">
          <a href="https://www.cisco.com/site/us/en/about/contact-cisco/index.html?contactpageID=footer-link">
            Contact
          </a>
        </Text>
        <Text size="p3">
          <a href="https://ciscocx.qualtrics.com/jfe/form/SV_bwrmeoKrBHYxOyW?Ref=tortuga">
            Feedback
          </a>
        </Text>
      </div>
    </footer>
  );
};
