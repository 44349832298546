import { grpc } from "@improbable-eng/grpc-web";
import LocalSettings from "./localSettings";
import { logger } from "./globals";
import { BilgeStreamPool, SextantStreamPool } from "./streaming/streamPool";
import { Stream } from "./streaming/core/stream";
import { Message } from "../gen/schema/syncfollow/syncfollow";
import { StreamingCachePrintType } from "./dataflow/streamingCache";

export default class AppState {
  public settings: LocalSettings;
  public sextantPool: SextantStreamPool;
  public bilgePool: BilgeStreamPool;

  constructor() {
    this.settings = new LocalSettings();
    this.sextantPool = new SextantStreamPool();
    this.bilgePool = new BilgeStreamPool();

    this.init();
  }

  public debugSync: () => void = () => {};

  init = () => {
    if (this.settings.get("websockets")) {
      const transport = grpc.WebsocketTransport();
      grpc.setDefaultTransport(transport);
    }
  };

  enableWebsocketTransport() {
    const transport = grpc.WebsocketTransport();
    grpc.setDefaultTransport(transport);
    logger.warn("using websockets, this is non-sticky");
  }

  enableAuthBypass(enable: boolean) {
    this.settings.set("authBypass", enable);
    logger.warn("using authBypass,", enable);
  }

  pageRefresh() {
    window.location.reload();
  }

  getStreams(): Stream<Message, Message>[] {
    const b = this.bilgePool.getStreams();
    const s = this.sextantPool.getStreams();
    return [...b, ...s];
  }

  setSync(cb: () => void) {
    this.debugSync = cb;
  }

  showContent(): StreamingCachePrintType[] {
    const b = this.bilgePool.showContent();
    const s = this.sextantPool.showContent();
    return [...b, ...s];
  }
}
