import { Spinner } from "@magnetic/spinner";
import VFlex from "./vFlex";
import "./pageLoader.scss";

export const PageLoader = (): React.ReactNode => {
  return (
    <div className="cnc-page-loader">
      <VFlex>
        <Spinner size="lg" />
      </VFlex>
    </div>
  );
};
interface SectionLoaderProps {
  readonly text?: React.ReactNode;
}
export const SectionLoader = ({
  text
}: SectionLoaderProps): React.ReactNode => {
  return (
    <div className="cnc-section-loader">
      <VFlex className="section-spinner">
        <Spinner size="sm" />
        <>{text}</>
      </VFlex>
    </div>
  );
};
