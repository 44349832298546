import { useContext, useEffect, useState } from "react";
import { Message } from "../../gen/schema/syncfollow/syncfollow";
import { ApiError } from "../apiError";
import { logger } from "../globals";
import { StreamReducer, StreamTransformer } from "../streaming/core/stream";
import { MD5 } from "../../utils/md5";
import { GrpcWebError } from "../../gen/schema/sextant/service";
import { grpc } from "grpc-web-client";
import { DataProviderContext } from "../provider/dataProvider";
import { ErrorHandler, UpdateHandler } from "../streaming/core/streamClient";
import { Sextant } from "../../utils/api";
import {
  MessageManager,
  SeriesCallback
} from "../streaming/core/messageManager";
import { PathId } from "../../gen/schema/schema_path/path_id";

export const useInternalUnary = <TRequest, TResponse>(
  callFn: (req: TRequest) => Promise<TResponse>,
  request: TRequest // this is processed by value via JSON.stringify
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [resp, setResp] = useState<TResponse | undefined>();
  const [error, setError] = useState<ApiError | undefined>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    //make api call
    callFn(request)
      .then((response: TResponse) => {
        // log response
        if (window.cnc.settings.get("apiLoggingInConsole")) {
          logger.info({ type: "grpc-unary", request, response });
        }

        setLoading(false);
        setResp(response);
      })
      .catch((err: GrpcWebError) => {
        const e = new ApiError(err);
        setError(e);
        setLoading(false);
        logger.error({ type: "grpc-unary-error", request, error: e });
      });
    return;
    // Only update the connection if the VALUE of the request changes
  }, [callFn, request]);

  return { loading, resp, error };
};

export const syncFollowStreamingCache = <TRequest extends Message, TResponse>(
  id: string,
  request: TRequest,
  responseTransform: StreamTransformer<TResponse>, // this argument shall not change
  responseReducer: StreamReducer<TResponse>, // this argument shall not change
  onSuccess: UpdateHandler,
  onError: ErrorHandler
) => {
  const streamManager = window.cnc.sextantPool.useStream(id);
  if (streamManager) {
    /**
     * This is an id based on the request converted
     * to a MD5 hash. if there is a duplicate request
     * they will hash out to the same and, we can
     * reuse that stream.
     */
    const groupId = MD5.hashJson(request);
    /**
     * This will either get an existing stream or
     * create a new one.
     */
    let stream = streamManager.getStream(groupId);
    if (!stream) {
      stream = streamManager.addStream(
        request,
        responseTransform,
        responseReducer
      );
    }
    if (stream) {
      /**
       * stream.follow() returns the cleanup
       * function used by useEffect.
       */
      return stream.subscribe(onSuccess, onError);
    }
  }
  /**
   * Because Typescript... there
   * will always be a stream but
   * typescript says it is possible
   * so need to handle the compiler
   * here.
   */
  return MessageManager.unsubscribe;
};

export const useSextantSyncFollow = <
  TRequest extends Message,
  TResponse extends Partial<{ id: PathId }>
>(
  request: TRequest,
  responseAccessor: SeriesCallback<TResponse> // this argument shall not change
  //reducer: (prev: TResponse[] | undefined, curr: TResponse[]) => TResponse[] // this argument shall not change
) => {
  const [resp, setResp] = useState<TResponse[][] | undefined>();
  const [error, setError] = useState<ApiError | undefined>();
  const [transformer] = useState(() =>
    MessageManager.transformer(responseAccessor)
  );

  const { fabric } = useContext(DataProviderContext);

  useEffect(() => {
    return Sextant.getDataSource().subscribe(
      fabric?.id,
      request,
      transformer,
      MessageManager.reducer,
      (data: TResponse[]) => {
        setResp([data]);
      },
      setError
    );
  }, [fabric?.id, request, transformer]);

  return {
    resp,
    error,
    respKey: undefined
  };
};

export const useInternalRawWebsocket = <TRequest, TResponse>(
  url: string,
  request: TRequest
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [resp, setResp] = useState<TResponse | undefined>();
  const [error, setError] = useState<ApiError | undefined>();

  const stringifiedRequest = "" + JSON.stringify(request);

  useEffect(() => {
    setOpen(false);
    setResp(undefined);
    setError(undefined);

    let ws: WebSocket;

    try {
      ws = new WebSocket(url);

      ws.onopen = (event) => {
        logger.info(event.timeStamp + ": Connection open");
        setOpen(true);
        ws.send(request as string);
      };
      ws.onmessage = (m) => {
        const data = JSON.parse(m.data);
        logger.info(data);
        setResp(data as TResponse);
      };
      ws.onerror = (e: Event) => {
        if (e instanceof ErrorEvent) {
          const err = new ApiError(
            new GrpcWebError(e.message, grpc.Code.Unknown, {} as grpc.Metadata)
          );
          logger.error(err);
          setOpen(false);
          setError(err);
        }
      };
    } catch (e: unknown) {
      if (e instanceof ErrorEvent) {
        const err = new ApiError(
          new GrpcWebError(e.message, grpc.Code.Unknown, {} as grpc.Metadata)
        );
        logger.error(err);
        setOpen(false);
        setError(err);
      }
    }

    // cleanup fn
    return () => {
      if (ws.OPEN) {
        ws.close();
      }
    };
    // compare the value of the request, not the reference, as there will always be a new reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, stringifiedRequest]);

  return { resp, error, open };
};
