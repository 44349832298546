// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/policy.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { AdjacencyProtocol, adjacencyProtocolFromJSON, adjacencyProtocolToJSON } from "./fabric";
import { Annotation } from "./global";
import { Metadata } from "./types";

export const protobufPackage = "models";

/**
 * NetworkPortPolicy encapsulates tenant network port policies that can be
 * applied to a set of network ports.
 */
export interface NetworkPortPolicy {
  /** The policy identifier. */
  id: string;
  /** A user-defined object name. */
  name: string;
  /** A user-defined object description. */
  description: string;
  /** A set of user-defined object labels. */
  labels: string[];
  /** A set of object annotations. */
  annotations: Annotation[];
  /** Is the object enabled?. */
  enabled: boolean;
  /** Object metadata. */
  metadata:
    | Metadata
    | undefined;
  /** Port speeds and MTU. */
  mtu: number;
  /** Maximum configurable speed (E.g. 10G). */
  maxSpeed: number;
  adjacencyProtocols: AdjacencyProtocol[];
  learningSettings: string[];
  securitySettings: string[];
}

/**
 * OrgProperty encapsulates name/value pair for configuring an organization
 * level property.
 */
export interface OrgProperty {
  /** The name of the property. */
  name: string;
  /** Value of the property. */
  value: string;
  /** Optional service name. */
  service: string;
  /** Is the property enabled? */
  enabled: boolean;
}

function createBaseNetworkPortPolicy(): NetworkPortPolicy {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    mtu: 0,
    maxSpeed: 0,
    adjacencyProtocols: [],
    learningSettings: [],
    securitySettings: [],
  };
}

export const NetworkPortPolicy = {
  encode(message: NetworkPortPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.mtu !== 0) {
      writer.uint32(80).uint32(message.mtu);
    }
    if (message.maxSpeed !== 0) {
      writer.uint32(88).uint32(message.maxSpeed);
    }
    writer.uint32(162).fork();
    for (const v of message.adjacencyProtocols) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.learningSettings) {
      writer.uint32(170).string(v!);
    }
    for (const v of message.securitySettings) {
      writer.uint32(178).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkPortPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkPortPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maxSpeed = reader.uint32();
          continue;
        case 20:
          if (tag === 160) {
            message.adjacencyProtocols.push(reader.int32() as any);

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.adjacencyProtocols.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.learningSettings.push(reader.string());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.securitySettings.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkPortPolicy {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      maxSpeed: isSet(object.maxSpeed) ? globalThis.Number(object.maxSpeed) : 0,
      adjacencyProtocols: globalThis.Array.isArray(object?.adjacencyProtocols)
        ? object.adjacencyProtocols.map((e: any) => adjacencyProtocolFromJSON(e))
        : [],
      learningSettings: globalThis.Array.isArray(object?.learningSettings)
        ? object.learningSettings.map((e: any) => globalThis.String(e))
        : [],
      securitySettings: globalThis.Array.isArray(object?.securitySettings)
        ? object.securitySettings.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: NetworkPortPolicy): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.maxSpeed !== 0) {
      obj.maxSpeed = Math.round(message.maxSpeed);
    }
    if (message.adjacencyProtocols?.length) {
      obj.adjacencyProtocols = message.adjacencyProtocols.map((e) => adjacencyProtocolToJSON(e));
    }
    if (message.learningSettings?.length) {
      obj.learningSettings = message.learningSettings;
    }
    if (message.securitySettings?.length) {
      obj.securitySettings = message.securitySettings;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkPortPolicy>, I>>(base?: I): NetworkPortPolicy {
    return NetworkPortPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkPortPolicy>, I>>(object: I): NetworkPortPolicy {
    const message = createBaseNetworkPortPolicy();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.mtu = object.mtu ?? 0;
    message.maxSpeed = object.maxSpeed ?? 0;
    message.adjacencyProtocols = object.adjacencyProtocols?.map((e) => e) || [];
    message.learningSettings = object.learningSettings?.map((e) => e) || [];
    message.securitySettings = object.securitySettings?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrgProperty(): OrgProperty {
  return { name: "", value: "", service: "", enabled: false };
}

export const OrgProperty = {
  encode(message: OrgProperty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.service !== "") {
      writer.uint32(26).string(message.service);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgProperty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgProperty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.service = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgProperty {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: OrgProperty): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgProperty>, I>>(base?: I): OrgProperty {
    return OrgProperty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgProperty>, I>>(object: I): OrgProperty {
    const message = createBaseOrgProperty();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.service = object.service ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
