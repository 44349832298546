import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import App from "./core/app";

// commented out stuff until react 18 can be used...

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
