import { ActivityTimeline } from "@magnetic/activity-timeline";
import { Button } from "@magnetic/button";
import { Drawer } from "@magnetic/drawer";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { withShowToggle } from "../../components/hoc/withShowToggle";
import {
  DataProviderContext,
  DATE_FORMAT
} from "../../core/provider/dataProvider";
import { ActivityEvent, FabricCandidate } from "../../gen/schema/configd/api";
import {
  objectTypeToJSON,
  operationToJSON
} from "../../gen/schema/models/global";
import VFlex from "../../components/vFlex";
import HFlex from "../../components/hFlex";
import { compareDesc, format } from "date-fns";
import "./previewChanges.scss";
import { useClickOutside } from "@magnetic/hooks";
import { EmptyContainer } from "../../components/emptyContainer";
import { Apis } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../core/apiError";
import { ErrorNotification } from "../../components/errorNotification";
import { getFabricRoute } from "../../utils/routesUtils";

export const PreviewChanges = () => {
  const { fabric, events } = useContext(DataProviderContext);

  const [candidates, setCandidates] = useState<FabricCandidate[]>([]);
  const [error, setError] = useState<ApiError | undefined>();

  useEffect(() => {
    /**
     * Loads all the pending Fabric Transactions.
     * there should just be one with a bunch of
     * events
     */
    if (fabric) {
      Apis.ConfigD.getFabricTransactions(fabric.id).then(
        (t: FabricCandidate[] = []) => {
          setCandidates(t);
        },
        (e: ApiError) => setError(e)
      );
    }
  }, [fabric, events.length]);

  const items = useMemo(() => {
    if (candidates.length) {
      const elements = candidates?.reduce(
        (out: React.ReactElement[], candidate: FabricCandidate) => {
          const activityEventsMap = new Map<string, ActivityEvent[]>();

          candidate.events
            /**
             * Need to sort the events by date and user.
             * That way we can group changes the same
             * way they are displayed in the mockup.
             */
            .sort((a: ActivityEvent, b: ActivityEvent) => {
              return compareDesc(a.timestamp ?? 0, b.timestamp ?? 0);
            })
            /**
             * Need to now group the events by date and user.
             * using foreach to populate a map. The key for
             * The map will be a string "username|timestamp".
             */
            .forEach((e: ActivityEvent) => {
              const key: string = `${e.username}|${format(
                e.timestamp ?? 0,
                DATE_FORMAT
              )}`;
              const tmp = activityEventsMap.get(key) ?? [];
              activityEventsMap.set(key, [...tmp, e]);
            });

          /**
           * iterate over the map to generate the
           * ActivityTimeline Items and the details
           * of each provisioning event
           */
          activityEventsMap.forEach((v: ActivityEvent[], k: string) => {
            const [user, ts] = k.split("|");
            const activityEvents = v.map((e: ActivityEvent) => {
              const operation = operationToJSON(e.operation).toLowerCase();
              const type = objectTypeToJSON(e.objectType)
                .replace("_", " ")
                .toLowerCase();

              return (
                <span
                  className="transaction-event"
                  key={`${candidate.txnId}-${type}-${operation}-${e.objectName}`}
                >{`${operation} ${type} "${e.objectName}"`}</span>
              );
            });

            /**
             * ActivityTimeline is not very flexible,
             * I basically need to put all the data in
             * to the header element.
             */
            const content = (
              <VFlex gap={8}>
                <HFlex
                  justify="space-between"
                  align="center"
                  className="transaction-title"
                >
                  <span className="created-by">{user}</span>
                  <time className="mds-activity-timeline-item-content-time">
                    {ts}
                  </time>
                </HFlex>
                <VFlex className="transaction-event-list" gap={8}>
                  {activityEvents}
                </VFlex>
              </VFlex>
            );

            out.push(
              <ActivityTimeline.Item
                key={`${user}${ts}`}
                heading={content}
                status="complete"
                time={-1}
              />
            );
          });
          return out;
        },
        []
      );
      if (elements?.length) {
        return elements;
      }
    }
    return <EmptyContainer text="No changes pending" />;
  }, [candidates]);

  return (
    <div className="preview-changes">
      <ErrorNotification error={error} />
      <ActivityTimeline>{items}</ActivityTimeline>
    </div>
  );
};

interface PreviewChangesDrawerProps {
  onClose: () => void;
}
export const PreviewChangesDrawer = withShowToggle(
  ({ onClose }: PreviewChangesDrawerProps) => {
    const { fabric } = useContext(DataProviderContext);
    const redirect = useNavigate();

    const reviewConfiguration = useCallback(() => {
      if (fabric) {
        redirect(getFabricRoute(fabric.id, "candidate"));
      }
    }, [redirect, fabric]);

    const node = useClickOutside<HTMLDivElement>((e) => {
      if (
        !(
          e.target instanceof HTMLAnchorElement ||
          e.target instanceof HTMLButtonElement
        )
      ) {
        onClose();
      }
    });

    if (fabric) {
      return (
        <Drawer
          isOpen={true}
          onClose={onClose}
          size="md"
          className="preview-changes-drawer"
        >
          <div ref={node} className="on-click-outside-container">
            <Drawer.Heading>Preview Changes</Drawer.Heading>
            <Drawer.Content>
              <PreviewChanges />
            </Drawer.Content>
            <Drawer.Footer>
              <Button
                name="review-config"
                kind="primary"
                onClick={reviewConfiguration}
              >
                Review configuration
              </Button>
            </Drawer.Footer>
          </div>
        </Drawer>
      );
    }
    return null;
  }
);

interface PreviewChangesDrawerSchema {
  previewChangesDrawer: React.ReactElement | null;
  openPreviewChangesDrawer: () => void;
}
export const usePreviewChangesDrawer = (): PreviewChangesDrawerSchema => {
  const [previewChangesDrawer, setPreviewChangesDrawer] =
    useState<React.ReactElement | null>(null);

  const openPreviewChangesDrawer = useCallback(() => {
    const onClose = () => {
      setPreviewChangesDrawer(null);
    };
    setPreviewChangesDrawer(<PreviewChangesDrawer onClose={onClose} />);
  }, []);

  return { previewChangesDrawer, openPreviewChangesDrawer };
};
