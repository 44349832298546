// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bredfish.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  BmcProductType,
  bmcProductTypeFromJSON,
  bmcProductTypeToJSON,
  ManagedEndpointType,
  managedEndpointTypeFromJSON,
  managedEndpointTypeToJSON,
  SmartNicDpuProductType,
  smartNicDpuProductTypeFromJSON,
  smartNicDpuProductTypeToJSON,
} from "../models/server";

export const protobufPackage = "brig";

export interface GetManagedEndpointSignedLoginBodyRequest {
  /** The identifier of switch. */
  deviceId: string;
  /** The interface on which the ManagedEndpoint is connected. */
  localInterface: string;
  /** The identifier of the BMC or DPU */
  remoteChassisId: string;
  meType: ManagedEndpointType;
  /** Valid if me_type is BMC */
  bmcType: BmcProductType;
  /** Valid id me_type is DPU */
  dpuType: SmartNicDpuProductType;
  nonce: string;
}

export interface GetManagedEndpointSignedLoginBodyResponse {
  loginBody: Uint8Array;
  /** Base64 encoded signature of login_body */
  signature: string;
}

function createBaseGetManagedEndpointSignedLoginBodyRequest(): GetManagedEndpointSignedLoginBodyRequest {
  return { deviceId: "", localInterface: "", remoteChassisId: "", meType: 0, bmcType: 0, dpuType: 0, nonce: "" };
}

export const GetManagedEndpointSignedLoginBodyRequest = {
  encode(message: GetManagedEndpointSignedLoginBodyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.localInterface !== "") {
      writer.uint32(18).string(message.localInterface);
    }
    if (message.remoteChassisId !== "") {
      writer.uint32(26).string(message.remoteChassisId);
    }
    if (message.meType !== 0) {
      writer.uint32(32).int32(message.meType);
    }
    if (message.bmcType !== 0) {
      writer.uint32(40).int32(message.bmcType);
    }
    if (message.dpuType !== 0) {
      writer.uint32(48).int32(message.dpuType);
    }
    if (message.nonce !== "") {
      writer.uint32(58).string(message.nonce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetManagedEndpointSignedLoginBodyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetManagedEndpointSignedLoginBodyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.localInterface = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.remoteChassisId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.meType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.bmcType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dpuType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.nonce = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetManagedEndpointSignedLoginBodyRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      localInterface: isSet(object.localInterface) ? globalThis.String(object.localInterface) : "",
      remoteChassisId: isSet(object.remoteChassisId) ? globalThis.String(object.remoteChassisId) : "",
      meType: isSet(object.meType) ? managedEndpointTypeFromJSON(object.meType) : 0,
      bmcType: isSet(object.bmcType) ? bmcProductTypeFromJSON(object.bmcType) : 0,
      dpuType: isSet(object.dpuType) ? smartNicDpuProductTypeFromJSON(object.dpuType) : 0,
      nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "",
    };
  },

  toJSON(message: GetManagedEndpointSignedLoginBodyRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.localInterface !== "") {
      obj.localInterface = message.localInterface;
    }
    if (message.remoteChassisId !== "") {
      obj.remoteChassisId = message.remoteChassisId;
    }
    if (message.meType !== 0) {
      obj.meType = managedEndpointTypeToJSON(message.meType);
    }
    if (message.bmcType !== 0) {
      obj.bmcType = bmcProductTypeToJSON(message.bmcType);
    }
    if (message.dpuType !== 0) {
      obj.dpuType = smartNicDpuProductTypeToJSON(message.dpuType);
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetManagedEndpointSignedLoginBodyRequest>, I>>(
    base?: I,
  ): GetManagedEndpointSignedLoginBodyRequest {
    return GetManagedEndpointSignedLoginBodyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetManagedEndpointSignedLoginBodyRequest>, I>>(
    object: I,
  ): GetManagedEndpointSignedLoginBodyRequest {
    const message = createBaseGetManagedEndpointSignedLoginBodyRequest();
    message.deviceId = object.deviceId ?? "";
    message.localInterface = object.localInterface ?? "";
    message.remoteChassisId = object.remoteChassisId ?? "";
    message.meType = object.meType ?? 0;
    message.bmcType = object.bmcType ?? 0;
    message.dpuType = object.dpuType ?? 0;
    message.nonce = object.nonce ?? "";
    return message;
  },
};

function createBaseGetManagedEndpointSignedLoginBodyResponse(): GetManagedEndpointSignedLoginBodyResponse {
  return { loginBody: new Uint8Array(0), signature: "" };
}

export const GetManagedEndpointSignedLoginBodyResponse = {
  encode(message: GetManagedEndpointSignedLoginBodyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginBody.length !== 0) {
      writer.uint32(10).bytes(message.loginBody);
    }
    if (message.signature !== "") {
      writer.uint32(18).string(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetManagedEndpointSignedLoginBodyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetManagedEndpointSignedLoginBodyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginBody = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetManagedEndpointSignedLoginBodyResponse {
    return {
      loginBody: isSet(object.loginBody) ? bytesFromBase64(object.loginBody) : new Uint8Array(0),
      signature: isSet(object.signature) ? globalThis.String(object.signature) : "",
    };
  },

  toJSON(message: GetManagedEndpointSignedLoginBodyResponse): unknown {
    const obj: any = {};
    if (message.loginBody.length !== 0) {
      obj.loginBody = base64FromBytes(message.loginBody);
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetManagedEndpointSignedLoginBodyResponse>, I>>(
    base?: I,
  ): GetManagedEndpointSignedLoginBodyResponse {
    return GetManagedEndpointSignedLoginBodyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetManagedEndpointSignedLoginBodyResponse>, I>>(
    object: I,
  ): GetManagedEndpointSignedLoginBodyResponse {
    const message = createBaseGetManagedEndpointSignedLoginBodyResponse();
    message.loginBody = object.loginBody ?? new Uint8Array(0);
    message.signature = object.signature ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
