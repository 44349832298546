import { MessageManager } from "../../core/streaming/core/messageManager";
import { ErrorHandler } from "../../core/streaming/core/streamClient";
import {
  SextantDataSource,
  SuccessCallback
} from "../../core/streaming/pooledDataSource";
import {
  Breakout,
  FabricTransaction,
  Fan,
  IpRouteTable,
  L2Fdb,
  L3Table,
  PortConfig,
  PortCounters,
  PortNeighbor,
  ProxyConnectionState,
  ProxyConnectionStats,
  Psu,
  QsfpDomSensor,
  QsfpDomThreshold,
  QsfpInfo,
  RemedyAction,
  TempSensor,
  VlanVniMap
} from "../../gen/schema/schema/schema";
import { SchemaType } from "../../gen/schema/schema_path/schema_path";
import { PortAssert } from "../../pages/assertions/portAssertUtils";
import { DeviceAssert } from "../../pages/assertions/switchAssertUtils";
import { BaseDataSourceApi } from "./baseDataSourceApi";

export enum AssertScope {
  FABRIC,
  DEVICE
}

/* valid Sextant device id xx-xx-xx-xx-xx-xx */
const DEVICE_ID_FORMAT = /([a-f0-9]{2}-){5}([a-f0-9]{2})/i;
/* valid Sextant device id de-ad-xx-xx-xx-xx */
export const IS_DEAD = /de-ad-([a-f0-9]{2}-?){4}/i;

const validDeviceId = (deviceId: string): boolean => {
  if (!DEVICE_ID_FORMAT.test(deviceId)) {
    console.trace("Device id has wrong format");
    return false;
  }
  if (IS_DEAD.test(deviceId)) {
    console.log(`Device id ${deviceId} is a temp id`);
    return false;
  }
  return true;
};

class SextantApi extends BaseDataSourceApi<SextantDataSource> {
  initDataSource(): SextantDataSource {
    return new SextantDataSource(window.cnc.sextantPool);
  }

  subscribeFabricVlanVniMap(
    fabricId: string,
    onSuccess: SuccessCallback<VlanVniMap[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.VlanVniMapType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.vlanVniMap),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeFabricIPMacAddress(
    fabricId: string,
    onSuccess: SuccessCallback<L2Fdb[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.L2FdbType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.l2Fdb),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeFabricLLDP(
    fabricId: string,
    onSuccess: SuccessCallback<PortNeighbor[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.PortNeighborType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.portNeighbor),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeFabricArpMac(
    fabricId: string,
    onSuccess: SuccessCallback<L3Table[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.L3TableType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.l3Table),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeSwitchPortConfig(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortConfig[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortConfigType]
        }),
        MessageManager.transformer((s) => s.portConfig),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricPortConfig(
    fabricId: string,
    onSuccess: SuccessCallback<PortConfig[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.PortConfigType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.portConfig),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeSwitchFan(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Fan[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.FanType]
        }),
        MessageManager.transformer((s) => s.fan),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPsu(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Psu[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PsuType]
        }),
        MessageManager.transformer((s) => s.psu),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchTemp(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<TempSensor[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.TempSensorType]
        }),
        MessageManager.transformer((s) => s.tempSensor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeRemedyAction(
    fabricId: string,
    onSuccess: SuccessCallback<RemedyAction[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.RemedyActionType]
      }),
      MessageManager.transformer((s) => s.remedyAction),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeProxyConnectionState(
    fabricId: string,
    onSuccess: SuccessCallback<ProxyConnectionState[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.ProxyConnectionStateType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.proxyConnectionState),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeProxyConnectionStats(
    fabricId: string,
    onSuccess: SuccessCallback<ProxyConnectionStats[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.ProxyConnectionStatsType],
        expandFabricIds: true
      }),
      MessageManager.transformer((s) => s.proxyConnectionStats),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeIpRouteTableType(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<IpRouteTable[]>,
    onError: ErrorHandler
  ) {
    deviceIds.forEach(validDeviceId);
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        switchId: deviceIds,
        types: [SchemaType.IpRouteTableType]
      }),
      MessageManager.transformer((s) => s.ipRouteTable),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeSwitchPortNeighbor(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortNeighbor[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortNeighborType]
        }),
        MessageManager.transformer((s) => s.portNeighbor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortState(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Breakout[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.BreakoutType]
        }),
        MessageManager.transformer((s) => s.breakout),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchQsfpDomThresholdInfo(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpDomThreshold[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpDomThresholdType]
        }),
        MessageManager.transformer((s) => s.qsfpDomThreshold),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortCounters(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortCounters[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortCountersType]
        }),
        MessageManager.transformer((s) => s.portCounters),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchQsfpInfo(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpInfo[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpInfoType]
        }),
        MessageManager.transformer((s) => s.qsfpInfo),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchOptics(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpDomSensor[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpDomSensorType]
        }),
        MessageManager.transformer((s) => s.qsfpDomSensor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricTransactions(
    fabricId: string,
    onSuccess: SuccessCallback<FabricTransaction[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.FabricTransactionType]
      }),
      MessageManager.transformer((s) => s.fabricTransaction),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeDeviceAsserts(
    fabricId: string,
    deviceId: string,
    scope: AssertScope,
    onSuccess: SuccessCallback<DeviceAssert[]>,
    onError: ErrorHandler
  ): typeof MessageManager.unsubscribe {
    if (validDeviceId(deviceId)) {
      const req = MessageManager.create({
        fabricId: scope === AssertScope.FABRIC ? [fabricId] : [],
        switchId: scope === AssertScope.DEVICE ? [deviceId] : [],
        types: [SchemaType.AllDeviceAssertTypes],
        expandFabricIds: true
      });

      const transformer = MessageManager.transformer((series) => {
        if (series) {
          if (series.assertDeviceConnectedToCloud.length) {
            return series.assertDeviceConnectedToCloud;
          }
          if (series.assertDeviceConnectedToFabric.length) {
            return series.assertDeviceConnectedToFabric;
          }
          if (series.assertVlanHasTraffic.length) {
            return series.assertVlanHasTraffic;
          }
          if (series.assertStaticDefaultRouteExists.length) {
            return series.assertStaticDefaultRouteExists;
          }
          if (series.assertManagementPortConfigSame.length) {
            return series.assertManagementPortConfigSame;
          }
          if (series.assertDeviceResourceUsageBelowThreshold.length) {
            return series.assertDeviceResourceUsageBelowThreshold;
          }
          if (series.assertBgpPeerEstablished.length) {
            return series.assertBgpPeerEstablished;
          }
        }
        return [];
      });

      return this.getDataSource().subscribe(
        fabricId,
        req,
        transformer,
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeAllDeviceAsserts(
    fabricId: string,
    deviceIds: string[],
    scope: AssertScope = AssertScope.FABRIC,
    onSuccess: SuccessCallback<DeviceAssert[] | PortAssert[]>,
    onError: ErrorHandler
  ): typeof MessageManager.unsubscribe {
    const cleanup = deviceIds.map((deviceId: string) => {
      if (validDeviceId(deviceId)) {
        return this.subscribeDeviceAsserts(
          fabricId,
          deviceId,
          scope,
          onSuccess,
          onError
        );
      }
      onSuccess([]);
      return MessageManager.unsubscribe;
    });
    return () => {
      cleanup.map((cb: () => void) => cb());
    };
  }

  subscribePortAsserts(
    fabricId: string,
    deviceId: string,
    scope: AssertScope,
    onSuccess: SuccessCallback<PortAssert[]>,
    onError: ErrorHandler
  ) {
    if (validDeviceId(deviceId)) {
      const req = MessageManager.create({
        fabricId: scope === AssertScope.FABRIC ? [fabricId] : [],
        switchId: scope === AssertScope.DEVICE ? [deviceId] : [],
        types: [SchemaType.AllPortAssertTypes],
        expandFabricIds: true
      });

      const transformer = MessageManager.transformer((series) => {
        if (series) {
          if (series.assertPortLinkDown.length) {
            return series.assertPortLinkDown;
          }
          if (series.assertPortLinkUp.length) {
            return series.assertPortLinkUp;
          }
          if (series.assertPortConnectionSpeedMatch.length) {
            return series.assertPortConnectionSpeedMatch;
          }
          if (series.assertPortExpectedNeighbor.length) {
            return series.assertPortExpectedNeighbor;
          }
          if (series.assertPortConnectionSpeedMatch.length) {
            return series.assertPortConnectionSpeedMatch;
          }
          if (series.assertPortNotConnectedToFabric.length) {
            return series.assertPortNotConnectedToFabric;
          }
          if (series.assertPortSpeedConsistent.length) {
            return series.assertPortSpeedConsistent;
          }
        }
        return [];
      });

      return this.getDataSource().subscribe(
        fabricId,
        req,
        transformer,
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeAllPortAsserts(
    fabricId: string,
    deviceIds: string[],
    scope: AssertScope = AssertScope.FABRIC,
    onSuccess: SuccessCallback<PortAssert[]>,
    onError: ErrorHandler
  ): typeof MessageManager.unsubscribe {
    const cleanup = deviceIds.map((deviceId: string) => {
      return this.subscribePortAsserts(
        fabricId,
        deviceId,
        scope,
        onSuccess,
        onError
      );
    });
    return () => {
      cleanup.map((cb: () => void) => cb());
    };
  }
}

const Sextant = new SextantApi();
export { Sextant };
