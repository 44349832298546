import { Notification } from "@magnetic/notification";
import React, { useContext } from "react";
import { ApiError } from "../core/apiError";
import {
  CandidateMode,
  DataProviderContext
} from "../core/provider/dataProvider";
import { ErrorCode } from "../gen/schema/syncfollow/syncfollow";

export const ErrorNotification = ({ error }: { readonly error?: ApiError }) => {
  if (error) {
    return (
      <Notification title="Error" status="negative">
        {error.getMessage()}
      </Notification>
    );
  }
  return null;
};

/**
 * Error for managing Sextant specific errors
 * @constructor
 */
export const SextantErrorNotification = ({
  error
}: {
  readonly error?: ApiError;
}) => {
  const { mode } = useContext(DataProviderContext);
  if (
    mode === CandidateMode.Edit &&
    error?.getCode() === ErrorCode.ERR_FORBIDDEN
  ) {
    return null;
  }
  return <ErrorNotification error={error} />;
};
