import { OrgType } from "../gen/schema/models/tenancy";

export type OrgTypeOption = {
  label: string;
  value: string;
};

const orgTypes: OrgTypeOption[] = [
  {
    label: "Cloud consumer",
    value: OrgType.CLOUD_CONSUMER.toString()
  },
  {
    label: "Enterprise",
    value: OrgType.ENTERPRISE.toString()
  },
  {
    label: "Service provider",
    value: OrgType.SERVICE_PROVIDER.toString()
  },
  {
    label: "Small to medium business",
    value: OrgType.SMALL_MEDIUM_BUSINESS.toString()
  }
];

export default orgTypes;
