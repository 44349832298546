import React, { forwardRef } from "react";
/**
 * Shows the Component based on if
 * the prop show evaluates to true
 * or is not passed
 */
interface ShowToggleProps {
  readonly show?: boolean | (() => boolean);
}

/**
 * @private
 * First checking for existence of show should
 * handle the case if no show prop is
 * passed. We default to showing the
 * component
 *
 * Next of show is a function call it
 * and coerce the result into a boolean,
 * just in case. this will allow for
 * complex logic that might be needed
 * when hiding/showing Components
 *
 * Otherwise, we expect it to be a boolean
 * and just return it.
 */
const showComponent = (show: boolean | (() => boolean) | undefined) => {
  if (show === undefined) {
    return true;
  }
  if (show instanceof Function) {
    return !!show();
  }
  return show;
};

const withShowToggle = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return forwardRef((props: ShowToggleProps & P, ref) => {
    const { show, ...rest } = props;
    if (showComponent(show)) {
      return <WrappedComponent {...(rest as P)} ref={ref} />;
    }
    return null;
  });
};
export { withShowToggle };
