import { BaseDataType } from "./dataType";
import { UserRole } from "../gen/schema/models/auth";
import React from "react";
import "./userRoleType.scss";
import { Constants } from "../utils/constants";

const STRINGS = {
  [UserRole.ADMIN]: "Administrator",
  [UserRole.READ_ONLY]: "Read Only",
  [UserRole.READ_WRITE]: "Read Write",
  [UserRole.USER_ROLE_UNSPECIFIED]: "Unspecified",
  [UserRole.UNRECOGNIZED]: "Unrecognized"
};

export class UserRoleType extends BaseDataType<UserRole> {
  UserRoleType = true;
  readonly tag = "user-role";
  compare(a: UserRoleType): number {
    return this.valueOf() - a.valueOf();
  }
  valueOf(): UserRole | -1 {
    return this.value ?? UserRole.USER_ROLE_UNSPECIFIED;
  }
  toString(): string {
    return STRINGS[this.valueOf()] ?? Constants.EMPTY_VALUE;
  }
  render(): React.ReactElement {
    return <span className={this.tag}>{this.toString()}</span>;
  }
}
