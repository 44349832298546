import { withShowToggle } from "../../components/hoc/withShowToggle";
import { Sextant } from "../../utils/api";
import { useEffect, useState } from "react";
import { ApiError } from "../../core/apiError";
import { logger } from "../../core/globals";
import { FabricTransaction } from "../../gen/schema/schema/schema";
import { MessageManager } from "../../core/streaming/core/messageManager";

interface FabricEventMonitorProps {
  readonly fabricId?: string;
  readonly onUpdate: () => void;
}

/**
 * This is a very simple component that will subscribe
 * to the FabricTransaction thread list. It should be
 * updated when anything on the fabric changes.
 */
export const FabricEventMonitor = withShowToggle(
  (props: FabricEventMonitorProps) => {
    const [transaction, setFabricTransaction] = useState<FabricTransaction[]>(
      []
    );
    const [error, setError] = useState<ApiError | undefined>();
    const { fabricId, onUpdate } = props;
    useEffect(() => {
      if (fabricId) {
        return Sextant.subscribeFabricTransactions(
          fabricId,
          setFabricTransaction,
          setError
        );
      }
      return MessageManager.unsubscribe;
    }, [fabricId]);

    useEffect(() => {
      if (error) {
        logger.error(error?.getMessage());
      }
    }, [error]);

    useEffect(() => {
      onUpdate();
    }, [transaction, onUpdate]);
    // No UI for this comp.
    return null;
  }
);
