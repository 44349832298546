import { Button } from "@magnetic/button";
import { Container } from "@magnetic/container";
import { Flex } from "@magnetic/flex";
import { Header } from "@magnetic/header";
import { Heading } from "@magnetic/heading";
import { Skeleton } from "@magnetic/skeleton";
import { useContext } from "react";
import { CiscoLogo } from "../common/ciscoLogo";
import HFlex from "../components/hFlex";
import { Link } from "../components/link";
import { useUrlState } from "../hooks/useUrlState";
import { Constants } from "../utils/constants";
import "./globalHeader.scss";
import { LoginContext } from "./loginContext";
import { MissingCertHackButton } from "./missingCertHackButton";
import { StreamingCacheMonitor } from "./streamingCacheMonitor";
import { DataProviderContext } from "./provider/dataProvider";

const PendingFabricChanges = () => {
  const { events, fabric } = useContext(DataProviderContext);
  if (fabric?.id) {
    return (
      <Header.Dropdown
        icon="deploy"
        label="Pending Changes"
        panel={false}
        alert={events?.length || 0}
      >
        <></>
      </Header.Dropdown>
    );
  }
  return null;
};

export default function GlobalHeader() {
  const [searchText, setSearchText] = useUrlState("g");
  const { session, logout } = useContext(LoginContext);

  const handleSearchChange = (): ((
    e: React.FormEvent<HTMLInputElement>
  ) => void) => {
    return (e: React.FormEvent<HTMLInputElement>): void => {
      setSearchText(e.currentTarget.value);
    };
  };

  const isDevMode = window.cnc.settings.get("dev");
  return (
    <Header
      className="global-header"
      logo={<CiscoLogo />}
      productName={
        isDevMode
          ? `${Constants.APP_NAME} - Developer Mode`
          : Constants.APP_NAME
      }
      profileAndTenant={{
        icon: "user",
        profile: {
          heading: session?.user?.email || "Oscar Piastri",
          subHeading: session?.tenantName || "McLaren"
        },
        content: (
          <Container style={{ width: 362 }} className="user-profile">
            <Flex
              className="user-profile-actions"
              direction="vertical"
              gap="md"
            >
              <Link
                name="apiBearerTokens"
                className="api-bearer-tokens"
                targetRoute="/api-bearer-tokens"
              >
                API bearer tokens
              </Link>
              {/* <Button
                name="changePassword"
                className="change-password"
                kind="tertiary"
                onClick={() => changePassword(() => {})}
              >
                Change Password
              </Button> */}
              <Button
                name="logout"
                className="logout"
                kind="tertiary"
                onClick={logout}
              >
                Log Out
              </Button>
            </Flex>
          </Container>
        )
      }}
      showXPN={false}
    >
      {isDevMode && (
        <Header.Search
          label="example search"
          panel={true}
          open={!!searchText}
          onChange={handleSearchChange()}
        >
          <Container style={{ width: 500 }}>
            <Heading size="h1">Results for {`"${searchText}"`}</Heading>
            <HFlex>
              <Heading>Switches Top 5</Heading>
              <Link
                name="allSwitches"
                targetRoute={`/globalSearch/switches/${searchText}`}
              >
                All Switches
              </Link>
            </HFlex>

            <Flex direction="vertical" gap="md">
              <Skeleton height="lg" />
              <Skeleton height="md" />
              <Skeleton height="sm" />
              <Skeleton height={72} />
            </Flex>
            <HFlex>
              <Heading>Ports Top 5</Heading>
              <Link
                name="allPorts"
                targetRoute={`/globalSearch/ports/${searchText}`}
              >
                All Ports
              </Link>
            </HFlex>
            <Flex direction="vertical" gap="md">
              <Skeleton height="lg" />
              <Skeleton height="md" />
              <Skeleton height="sm" />
              <Skeleton height={72} />
            </Flex>
          </Container>
        </Header.Search>
      )}
      {isDevMode && <MissingCertHackButton />}

      {isDevMode && <StreamingCacheMonitor />}

      {/* <Header.Button icon="info" label="info" onClick={handleClick} /> */}
      {/*
          <Header.Dropdown icon="question" label="question" panel={true}>
            <Container style={{ width: 500 }}>
              <Flex direction="vertical" gap="md">
                <Skeleton height="lg" />
                <Skeleton height="md" />
                <Skeleton height="sm" />
                <Skeleton height={72} />
              </Flex>
            </Container>
          </Header.Dropdown>
          <Header.Dropdown icon="bell" label="question" alert={81} panel={true}>
            <Container style={{ width: 500 }}>
              <Flex direction="vertical" gap="md">
                <Skeleton height="lg" />
                <Skeleton height="md" />
                <Skeleton height="sm" />
                <Skeleton height={72} />
              </Flex>
            </Container>
          </Header.Dropdown> */}
      {/* <Header.Button
        alert={100}
        icon="bell"
        label="example dropdown"
        onClick={console.log}
      /> */}
      <PendingFabricChanges />
    </Header>
  );
}
